import axios from 'axios'

const state = {
  customer: null,
  loginError: null
}

// getters
const getters = {
  customer: state => state.customer,
  loginError: state => state.loginError,
}

// actions
const actions = {
  login(context, payload) {
    return axios.post(context.rootGetters.API.baseUrl + 'customers/auth',
      {
        email: payload.email,
        password: payload.password
      }
    ).then(response => {
      console.log('axios login resolved')
      console.log(response.data)
      let payload = {
        customer: response.data.customer,
        token: response.data.token
      }
      if (!response.data.success) {
        console.log('login error')
        context.commit('SET_LOGIN_ERROR', response.data.message)
      } else {
        console.log('login success')
        context.commit('LOGIN', payload.customer)
        context.commit('SET_TOKEN', payload.token, { root: true })
        localStorage.setItem('fiasco-token', payload.token)
      }
      return response
    }).catch(error => {
      console.log(error)
      console.log('login error')
      context.commit('SET_LOGIN_ERROR', error)
    })
  },
  getById(context, payload) {
    return axios.get(context.rootGetters.API.baseURL + 'customers/getById/' + payload, context.rootGetters.axiosConfig)
      .then((response) => {
        context.commit('LOGIN', response.data)
        return response
      })
      .catch((error) => console.log(error))
  },
  update(context, payload) {
    console.log('update customer')
    return axios.patch(context.rootGetters.API.baseURL + 'customers/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  updatePassword(context, payload) {
    console.log('updatePassword')
    return axios.patch(context.rootGetters.API.baseURL + 'customers/pwd/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  uploadCameraPhoto(context, payload) {
    console.log('uploadCameraPhoto')
    return axios.post(
      context.rootGetters.API.baseURL + 'customers/upload/camera',
      payload,
      context.rootGetters.axiosConfig
    ).then(response => {
      return response
    }).catch(error => {
      return error
    })
  },
  register(context, payload) {
    console.log('register customer')
    return axios.put(context.rootGetters.API.baseUrl + 'customers/register', payload)
      .then((response) => {
        return {
          success: true,
          data: response
        }
      })
      .catch((error) => {
        return {
          success: false,
          error: error
        }
      })
  },
  resendRegister(context, payload) {
    console.log('resendRegister customer')
    return axios.post(context.rootGetters.API.baseUrl + 'customers/register-resend', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return {
          success: true,
          data: response
        }
      })
      .catch((error) => {
        return {
          success: false,
          error: error
        }
      })
  },
  verify(context, payload) {
    console.log('verify customer')
    return axios.post(context.rootGetters.API.baseUrl + 'customers/verify', payload)
      .then((response) => {
        return {
          success: true,
          data: response
        }
      })
      .catch((error) => {
        return {
          success: false,
          error: error
        }
      })
  }
}

// mutations
const mutations = {
  LOGIN(state, payload) {
    console.log('LOGIN')
    state.customer = payload
    localStorage.setItem('fiasco-customer', JSON.stringify(payload))
  },
  SET_EMAIL_VERIFIED(state) {
    state.customer.emailVerified = true
    localStorage.setItem('fiasco-customer', JSON.stringify(state.customer))
  },
  TEMP_LOGIN(state) {
    console.log('TEMP LOGIN')
    state.customer = {
      email: '',
      password: ''
    }
    // localStorage.setItem('fiasco-customer', JSON.stringify(payload))
  },
  SET_CUSTOMER_DATA(state, payload) {
    if (payload.firstName && !state.customer.firstName) {
      state.customer.firstName = payload.firstName
    }
    if (payload.lastName && !state.customer.lastName) {
      state.customer.lastName = payload.lastName
    }
    if (payload.city && !state.customer.city) {
      state.customer.city = payload.city
    }
    if (payload.address && !state.customer.address) {
      state.customer.address = payload.address
    }
    if (payload.zip && !state.customer.zip) {
      state.customer.zip = payload.zip
    }
  },
  LOGOUT(state) {
    console.log('LOGOUT')
    state.customer = null
    localStorage.removeItem('fiasco-token')
    localStorage.removeItem('fiasco-customer')
  },
  SET_LOGIN_ERROR(state, data) {
    state.loginError = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
