<template>
  <div class="create-request-damage create-request-content">
    <div class="create-request-damage-car-select">
      <h2>Fahrzeugtyp wählen</h2>
      <button @click="selectDummyCar('LIMOUSINE')">
        <img src="@/assets/gfx/icon_car_limousine.svg" />
        Limousine
      </button>
      <button @click="selectDummyCar('COUPE')">
        <img src="@/assets/gfx/icon_car_coupe.svg" />
        Coupe
      </button>
      <button @click="selectDummyCar('SUV')">
        <img src="@/assets/gfx/icons_autos_01.svg" />
        Kombi / SUV
      </button>
    </div>
    <div class="create-request-btn-prev" @click="goBack()">
      <img src="@/assets/gfx/arrow-prev.png" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CreateRequestCar",
  data() {
    return {
      editRequest: null,
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "CreateRequestCar" });
    },
    selectDummyCar(carType) {
      this.$store.commit("SET_DAMAGE_DUMMY_CAR", carType);
      this.$router.push({ name: "CreateRequestDamage" });
    },
    getEditData() {
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest));
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      damageTypes: "requests/damageTypes",
      damageLocations: "requests/damageLocations",
      damageLocationName: "requests/damageLocationName",
      damageTypeName: "requests/damageTypeName",
      selectedRequest: "requests/selectedRequest",
      damageDummyCar: "damageDummyCar",
    }),
  },
  watch: {
    carSelected() {
      console.log("WATCH carSelected");
      console.log(this.carSelected);
      /*
      if (!this.carSelected) {
        if (this.scene) {
          this.scene.dispose();
        }
      }
      */
    },
  },
  mounted() {
    this.getEditData();
  },
};
</script>
