<template>
  <div class="edit-car-modal modal">
    <div class="modal-clicker" @click="close()"></div>
    <div class="edit-car" v-if="editCar">

      <div class="profile-header">
        <div class="profile-header-chevron-back" @click="close()"></div>
        <h2 class="center">Fahrzeug<br>bearbeiten</h2>
      </div>

      <!--img class="profile-avatar" :src="API.imageUrl + 'cars/' + editCar.image">
      <label for="camera-select" class="input-camera profile-btn-avatar" >
        <img class="input-camera-icon btn-icon" src="@/assets/gfx/icon_camera.png">
      </label>
      <input id="camera-select" class="input-camera profile-btn-avatar" type="file" capture="environment" accept="image/*" @change="onFileSelect" />
      <label for="file-select" class="input-camera profile-btn-avatar" >
        <img class="input-camera-icon btn-icon" src="@/assets/gfx/icon_gallery.png">
      </label>
      <input id="file-select" class="input-camera profile-btn-avatar" type="file" accept="image/*" @change="onFileSelect" /-->

      <div class="profile-form-line">
        <label>Hersteller</label>
        <input type="text" v-model="editCar.manufacturer" />
      </div>
      <div class="profile-form-line">
        <label>Modell</label>
        <input type="text" v-model="editCar.model" />
      </div>
      <div class="profile-form-line">
        <label>Erstzulassung (JJJJ-MM-TT)</label>
        <input type="text" v-model="editCar.firstRegistration" />
      </div>
      <div class="profile-form-line">
        <label>Kilometerstand</label>
        <input type="number" v-model="editCar.kilometers" />
      </div>
      <div class="profile-form-line">
        <label>FIN</label>
        <input type="text" v-model="editCar.vin" />
        <p class="small">
          Achtung: Ändere die FIN nur, wenn du weißt, dass sie falsch ist, oder wenn sie noch fehlt.
        </p>
      </div>
      <div class="profile-form-line">
        <button 
          @click="updateCar()">
          Speichern
        </button>
      </div>
      <div class="profile-form-line">
        <button class="secondary"
          @click="close()">
          Abbrechen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Profile',
  data () {
    return {
      menuVisible: false,
      editMode: false,
      editCar: null,
      avatarUpload: null
    }
  },
  methods: {
    close () {
      this.$store.commit('cars/SET_EDIT_CAR_VISIBLE', false)
      this.$store.commit('cars/SET_SELECTED_CAR', null)
    },
    updateCar () {
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('cars/update', this.editCar).then(() => {
        this.$store.dispatch('cars/getByCustomer', this.customer._id).then(() => {
          this.$store.commit('cars/SET_SELECTED_CAR', this.customerCars.find(o => o._id === this.selectedCar._id))
          this.getEditData()
          this.$store.commit('SET_BUSY', false)
        })
      })
    },
    getEditData () {
      this.editCar = JSON.parse(JSON.stringify(this.customerCars.find(o => o._id === this.selectedCar._id)))
    },
    onFileSelect (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.$store.dispatch('fileToSizedBlob', {file: files[0]}).then(blob => {
        this.avatarUpload = blob
        this.uploadAvatar()
      })
    },
    uploadAvatar () {
      console.log('uploading carvatar')
      let payload = {
        // id: this.editCustomer._id,
        photo: this.avatarUpload
      }
      this.$store.dispatch('cars/uploadCameraPhoto', payload).then((res) => {
        this.editCar.image = res.data
        this.updateCar()
      })
    },
  },
  computed: {
    ...mapGetters({
      API: 'API',
      busy: 'busy',
      fileToSizedBlob: 'fileToSizedBlob',
      editCarVisible: 'cars/editCarVisible',
      customer: 'customers/customer',
      customerCars: 'cars/customerCars',
      selectedCar: 'cars/selectedCar',
    }),
  },
  mounted () {
    this.getEditData()
  }
}

</script>
