<template>
  <div class="create-request-damage">
    <div class="create-request-damage-visualizer">
      <canvas ref="renderCanvas" />
    </div>
    <div class="create-request-damage-title">
      <h2>Wo ist das Fahrzeug beschädigt?</h2>
      <p class="center">Beschädigte Teile markieren:</p>
    </div>

    <div class="modal invisible" v-if="selectedLocation">
      <div class="modal-clicker" @click="selectedLocation = null" />
      <div class="create-request-damage-selector">
        <p>{{ selectedLocation.displayName }}</p>
        <div class="create-request-damage-selector-types">
          <div
            class="create-request-damage-selector-type"
            v-for="(item, index) in selectedLocation.possibleTypes"
            :key="index + 'v'"
            :class="
              'damage-type-' + damageTypes.find((o) => o.type === item).type
            "
            @click="onDamageSelect(damageTypes.find((o) => o.type === item))"
          >
            {{ damageTypes.find((o) => o.type === item).displayName }}
          </div>
          <div
            class="create-request-damage-selector-type damage-type-none"
            @click="onDamageSelect('none')"
          >
            Kein Schaden
          </div>
        </div>
      </div>
    </div>

    <div class="create-request-damage-parts">
      <div
        class="create-request-damage-part"
        v-for="(item, index) in damagedParts"
        :key="index"
        :class="'damage-type-' + item.type"
      >
        {{ damageLocationName(item.location) }}<br />
        {{ damageTypeName(item.type) }}
      </div>
    </div>

    <div class="create-request-btn-prev" @click="goBack()">
      <img src="@/assets/gfx/arrow-prev.png" />
    </div>

    <div
      class="create-request-btn-next"
      :disabled="damagedParts.length == 0"
      @click="goNext()"
    >
      <img src="@/assets/gfx/arrow-next.png" />
    </div>
  </div>
</template>

<script>
import * as BABYLON from "babylonjs";
import "babylonjs-loaders";
import { mapGetters } from "vuex";

export default {
  name: "CreateRequestCar",
  data() {
    return {
      photoIndex: 0,
      canvas: null,
      engine: null,
      scene: null,
      light: null,
      cameraLight: null,
      ground: null,
      groundMaterial: null,
      touchMaterial: null,
      lineMaterial: null,
      sphere: null,
      camera: null,
      damageMaterials: [],
      damageMaterialScratch: null,
      damageMaterialPaint: null,
      damageMaterialDent: null,
      damageMaterialBump: null,
      damageMaterialBreak: null,
      damagedParts: [],
      editRequest: null,
      selectedLocation: null,
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "CreateRequestDamageIntro" });
    },
    goNext() {
      this.$router.push({ name: "CreateRequestInterview" });
    },
    createScene() {
      console.log("createScene");
      this.scene = new BABYLON.Scene(this.engine);
      // this.scene.clearColor = new BABYLON.Color3(0.90, 0.93, 0.99)
      this.scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);

      console.log(this.scene);

      // this.scene.createDefaultEnvironment()
      var hdrTexture = new BABYLON.CubeTexture.CreateFromPrefilteredData(
        "./textures/SKIESV2_19SN.env",
        this.scene
      );
      this.scene.environmentTexture = hdrTexture;

      this.light = new BABYLON.HemisphericLight(
        "HemiLight",
        new BABYLON.Vector3(0, 1, 0),
        this.scene
      );
      this.light.intensity = 0.5;
      this.light.position = new BABYLON.Vector3(0, 10, 0);

      // this.shadowGenerator = new BABYLON.ShadowGenerator(1024, this.sunLight)

      this.camera = new BABYLON.ArcRotateCamera(
        "Camera",
        Math.PI * 2,
        Math.PI * 0.35,
        20,
        new BABYLON.Vector3(5, 0, 0),
        this.scene
      );

      // this.camera.alpha = this.roomFeatures.room.alpha * Math.PI * 2
      // this.camera.beta = this.roomFeatures.room.beta * Math.PI * (-1)
      // this.camera.invertRotation = true

      this.camera.attachControl(this.canvas, true);
      this.camera.fov = 1.33;
      this.camera.lowerBetaLimit = Math.PI * -0.02;
      this.camera.upperBetaLimit = Math.PI * 0.4;
      this.camera.lowerRadiusLimit = 10;
      this.camera.upperRadiusLimit = 30;
      this.camera.useAutoRotationBehavior = true;
      console.log(this.camera);

      this.cameraLight = new BABYLON.PointLight(
        "cameraLight",
        this.camera.position,
        this.scene
      );
      this.cameraLight.parent = this.camera;
      this.cameraLight.intensity = 0.1;

      this.neutralMaterial = new BABYLON.StandardMaterial(
        "neutralMaterial",
        this.scene
      );
      // this.neutralMaterial.diffuseColor = new BABYLON.Color3(0.0, 0.38, 0.784)
      this.neutralMaterial.specularColor = new BABYLON.Color3(0.5, 0.5, 0.5);
      this.neutralMaterial.diffuseColor = new BABYLON.Color3(0.98, 0.98, 0.98);
      //
      this.neutralMaterial.alpha = 0.0;
      /*
      this.neutralMaterial.diffuseFresnelParameters = new BABYLON.FresnelParameters()
      this.neutralMaterial.diffuseFresnelParameters.leftColor = new BABYLON.Color3(0.98, 0.98, 0.98)
      this.neutralMaterial.diffuseFresnelParameters.rightColor = new BABYLON.Color3(0.87, 0.87, 0.87)
      */
      this.tireMaterial = new BABYLON.StandardMaterial(
        "tireMaterial",
        this.scene
      );
      this.tireMaterial.diffuseColor = new BABYLON.Color3(0.18, 0.18, 0.18);
      this.tireMaterial.specularColor = new BABYLON.Color3(0.25, 0.25, 0.25);

      this.lineMaterial = new BABYLON.StandardMaterial(
        "lineMaterial",
        this.scene
      );
      this.lineMaterial.diffuseColor = new BABYLON.Color3(0.08, 0.28, 0.9);

      this.touchMaterial = new BABYLON.StandardMaterial(
        "touchMaterial",
        this.scene
      );
      this.touchMaterial.diffuseColor = new BABYLON.Color3(0.98, 0.98, 0.98);
      this.touchMaterial.alpha = 0.8;

      // this.sphere = BABYLON.MeshBuilder.CreateSphere("Sphere", {diameter: 1}, this.scene)
      // this.camera.setTarget(this.sphere)

      this.damageMaterialScratch = new BABYLON.StandardMaterial(
        "damageMaterialScratch",
        this.scene
      );
      this.damageMaterialScratch.diffuseColor = new BABYLON.Color3(
        0.2,
        0.8,
        0.2
      );
      this.damageMaterialPaint = new BABYLON.StandardMaterial(
        "damageMaterialPaint",
        this.scene
      );
      this.damageMaterialPaint.diffuseColor = new BABYLON.Color3(0.9, 0.2, 0.2);
      this.damageMaterialDent = new BABYLON.StandardMaterial(
        "damageMaterialDent",
        this.scene
      );
      this.damageMaterialDent.diffuseColor = new BABYLON.Color3(0.85, 0.7, 0.2);
      this.damageMaterialBump = new BABYLON.StandardMaterial(
        "damageMaterialBump",
        this.scene
      );
      this.damageMaterialBump.diffuseColor = new BABYLON.Color3(0.2, 0.2, 0.8);
      this.damageMaterialBreak = new BABYLON.StandardMaterial(
        "damageMaterialBreak",
        this.scene
      );
      this.damageMaterialBreak.diffuseColor = new BABYLON.Color3(
        0.6,
        0.1,
        0.95
      );
      this.damageMaterials.push(this.damageMaterialScratch);
      this.damageMaterials.push(this.damageMaterialPaint);
      this.damageMaterials.push(this.damageMaterialDent);
      // this.damageMaterials.push(damageMaterialBump)
      this.damageMaterials.push(this.damageMaterialBreak);

      // this.showAxis(5)
    },
    showAxis(size) {
      var axisX = BABYLON.Mesh.CreateLines(
        "axisX",
        [new BABYLON.Vector3.Zero(), new BABYLON.Vector3(size, 0, 0)],
        this.scene
      );
      axisX.color = new BABYLON.Color3(1, 0, 0);
      var axisY = BABYLON.Mesh.CreateLines(
        "axisY",
        [new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, size, 0)],
        this.scene
      );
      axisY.color = new BABYLON.Color3(0, 1, 0);
      var axisZ = BABYLON.Mesh.CreateLines(
        "axisZ",
        [new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, 0, size)],
        this.scene
      );
      axisZ.color = new BABYLON.Color3(0, 0, 1);
    },
    loadCar(carType) {
      this.$store.commit("SET_BUSY", true);
      let vueThis = this;
      let fileName = "";
      if (carType === "LIMOUSINE") fileName = "limo_03.gltf";
      if (carType === "COUPE") fileName = "coupe_07.gltf";
      if (carType === "SUV") fileName = "auto_09.gltf";
      BABYLON.SceneLoader.ImportMesh(
        "",
        "3d/",
        fileName,
        this.scene,
        function (meshes) {
          // BABYLON.SceneLoader.ImportMesh('', '3d/', 'auto_09.gltf', this.scene, function (meshes) {
          vueThis.$store.commit("SET_BUSY", false);
          meshes.forEach((mesh) => {
            console.log(mesh.name);
            if (mesh.name.includes("-overlay")) {
              mesh.material = vueThis.neutralMaterial;
            }
            /*
          if (!mesh.name.includes('-display') && !mesh.name.includes('linien')) {
            if (mesh.name.includes('Reifen')) {
              mesh.material = vueThis.tireMaterial
            }
          }
          if (mesh.name.includes('linien')) {
            mesh.material = vueThis.lineMaterial
          }
          if (mesh.name.includes('-touch')) {
            mesh.material = vueThis.touchMaterial
          }
          if (mesh.name.includes('-touch')) {
            mesh.material = vueThis.touchMaterial
          }
          */
            if (
              vueThis.damageLocations.find(
                (o) => o.location + "-touch" === mesh.name
              )
            ) {
              console.log("found damageLocation: " + mesh.name);
              mesh.actionManager = new BABYLON.ActionManager(vueThis.scene);
              mesh.actionManager.registerAction(
                new BABYLON.ExecuteCodeAction(
                  BABYLON.ActionManager.OnPickUpTrigger,
                  function (e) {
                    console.log(e.meshUnderPointer.name);
                    if (e.meshUnderPointer.name === "Auto") return;
                    let meshBaseName = e.meshUnderPointer.name.replace(
                      "-touch",
                      ""
                    );
                    window.setTimeout(() => {
                      vueThis.selectedLocation = vueThis.damageLocations.find(
                        (o) => o.location === meshBaseName
                      );
                    }, 100);
                  }
                )
              );
            }
          });
          vueThis.markDamages();
        }
      );
    },
    onDamageSelect(damageType) {
      console.log(damageType.type);
      let locationName = this.selectedLocation.location;
      let overlayMeshName = locationName + "-overlay";
      let overlayMesh = this.scene.getMeshByName(overlayMeshName);

      let targetMaterial; // = this.nextDamageTypeIndex(currentMaterialIndex, locationName)
      if (damageType.type === "PAINT") {
        targetMaterial = this.damageMaterialScratch;
      }
      if (damageType.type === "DENT") {
        targetMaterial = this.damageMaterialPaint;
      }
      if (damageType.type === "PAINT_DENT") {
        targetMaterial = this.damageMaterialDent;
      }
      if (damageType.type === "CRACK") {
        targetMaterial = this.damageMaterialBreak;
      }

      if (targetMaterial) {
        overlayMesh.material = targetMaterial;

        if (!this.damagedParts.some((o) => o.location === locationName)) {
          this.damagedParts.push({
            location: locationName,
            type: damageType.type,
          });
        } else {
          console.log(
            this.damagedParts.some((o) => o.location === locationName)
          );
          this.damagedParts.find((o) => o.location === locationName).type =
            damageType.type;
        }
      } else {
        overlayMesh.material = this.neutralMaterial;
        this.damagedParts = this.damagedParts.filter(
          (o) => o.location !== locationName
        );
      }

      this.editRequest.damages = JSON.parse(JSON.stringify(this.damagedParts));
      this.$store.commit("requests/SET_SELECTED_REQUEST", this.editRequest);
      this.getEditData();
      this.selectedLocation = null;
    },
    getEditData() {
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest));
      this.damagedParts = this.editRequest.damages
        ? this.editRequest.damages
        : [];
    },
    markDamages() {
      this.damagedParts.forEach((part) => {
        this.selectedLocation = this.damageLocations.find(
          (o) => o.location === part.location
        );
        this.onDamageSelect(part);
      });
    },
    nextDamageTypeIndex(typeIndex, location) {
      let damageLocation = this.damageLocations.find(
        (o) => o.location === location
      );
      for (
        let index = typeIndex + 1;
        index < this.damageTypes.length;
        index++
      ) {
        if (
          damageLocation.possibleTypes.includes(this.damageTypes[index].type)
        ) {
          return index;
        }
      }
      return -1;
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      damageTypes: "requests/damageTypes",
      damageLocations: "requests/damageLocations",
      damageLocationName: "requests/damageLocationName",
      damageTypeName: "requests/damageTypeName",
      selectedRequest: "requests/selectedRequest",
      damageDummyCar: "damageDummyCar",
    }),
  },
  mounted() {
    this.getEditData();
    this.canvas = this.$refs.renderCanvas;
    this.engine = new BABYLON.Engine(this.canvas, true);
    this.createScene();
    this.loadCar(this.damageDummyCar);
    window.addEventListener("resize", () => {
      this.engine.resize();
    });
    window.addEventListener("orientationchange", () => {
      setTimeout(() => {
        console.log(window.innerHeight);
        this.engine.resize();
      }, 100);
    });
    this.engine.runRenderLoop(() => {
      this.scene.render();
    });
  },
  beforeUnmount() {
    this.scene.dispose();
  },
};
</script>
