<template>
  <div class="camera">
    <!--div class="camera-debug">
      <div class="camera-debug-line" v-for="item in devices" :key="item.deviceId"
        @click="selectCamera(item.deviceId)">
        {{item.label}}<br>
        {{item.deviceId}}
      </div>
    </div-->

    <div class="create-request-photos-camera">
      <div class="create-request-photos-camera-image">
        <!--img :src="'/placeholders/camera-placeholder.jpg'"-->
      </div>
      <vue-web-cam
        ref="webcam"
        :device-id="deviceId"
        @started="onStarted"
        @stopped="onStopped"
        @error="onError"
        @cameras="onCameras"
        :resolution="{ width: 1920, height: 1920 }"
      />
      <div
        class="create-request-photos-camera-btn"
        @click="takePhoto()"
        :class="{ inactive: cameraPhotos.length >= 12 }"
      ></div>
      <div
        class="create-request-photos-camera-switch-btn"
        @click="switchCamera()"
        :class="{ inactive: cameraPhotos.length >= 12 }"
      ></div>
      <div
        class="create-request-photos-camera-file-btn"
        :class="{ inactive: cameraPhotos.length >= 12 }"
      >
        <label for="file-select" class="input-camera">
          <img class="input-camera-icon" src="@/assets/gfx/icon_gallery.png" />
        </label>
        <input
          id="file-select"
          class="input-camera"
          type="file"
          accept="image/*"
          @change="onFileSelect"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Camera",
  data() {
    return {
      img: null,
      camera: null,
      cameraIndex: 0,
      deviceId: null,
      devices: [],
      cameraStarted: false,
    };
  },
  methods: {
    takePhoto() {
      let photo = this.$refs.webcam.capture();
      this.$emit("grab", photo);
    },
    onFileSelect(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.$store.commit("SET_BUSY", true);
      this.$store
        .dispatch("fileToSizedBlob", { file: files[0] })
        .then((blob) => {
          this.$store.commit("SET_BUSY", false);
          this.$emit("grab", blob);
          /*
        this.photos.push(blob)
        this.$store.commit('requests/SET_CAMERA_PHOTOS', this.photos)
        this.$store.commit('SET_BUSY', false)
        */
        });
    },
    onStarted(stream) {
      console.log("On Started Event", stream);
      this.cameraStarted = true;
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
      this.cameraStarted = false;
    },
    stopCamera() {
      this.$refs.webcam.stop();
    },
    startCamera() {
      console.log("On Start");
      this.$refs.webcam.start();
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras.filter((o) => !o.label.includes("infrared"));
      console.log("On Cameras Event", cameras);
      this.switchCamera();
    },
    selectCamera(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      console.log("On Camera Change Event", deviceId);
    },
    switchCamera() {
      if (this.cameraIndex < this.devices.length - 1) {
        this.cameraIndex++;
      } else {
        this.cameraIndex = 0;
      }
      this.selectCamera(this.devices[this.cameraIndex].deviceId);
    },
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      customerCars: "cars/customerCars",
      selectedRequest: "requests/selectedRequest",
      cameraPhotos: "requests/cameraPhotos",
    }),
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      console.log(tail);
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
        this.startCamera();
      }
    },
  },
  mounted() {
    // this.initCam()
  },
  beforeDestroy() {
    this.stopCamera();
  },
};
</script>
