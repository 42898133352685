import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'

import customers from './modules/customers'
import requests from './modules/requests'
import stations from './modules/stations'
import cars from './modules/cars'
import offers from './modules/offers'
Vue.use(Vuex)

import ImageBlobReduce from 'image-blob-reduce'

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {
    API: {

      // baseUrl: 'http://localhost:3004/',
      // baseURL: 'http://localhost:3004/',
      // imageUrl: 'http://localhost:3004/images/',

      baseUrl: 'https://api.fiasco.de/',
      baseURL: 'https://api.fiasco.de/',
      imageUrl: 'https://api.fiasco.de/images/',
    },
    CHANGE_TIMEOUT_MS: 500,
    MAX_UPLOAD_IMAGE_SIZE_PX: 1920,
    MAX_UPLOAD_MINI_SIZE_PX: 180,
    axiosConfig: {},
    busy: false,
    whitelabel: false,
    vinScanActive: false,
    registrationScanActive: false,
    qrScanActive: false,
    enterCarActive: false,
    lastUpdateCall: null,
    newDataAvailable: false,
    imprintVisible: false,
    privacyVisible: false,
    customerInfoVisible: false,
    onboardingVisible: false,
    damageDummyCar: false
  },
  getters: {
    API: state => state.API,
    axiosConfig: state => state.axiosConfig,
    busy: state => state.busy,
    whitelabel: state => state.whitelabel,
    CHANGE_TIMEOUT_MS: state => state.CHANGE_TIMEOUT_MS,
    MAX_UPLOAD_IMAGE_SIZE_PX: state => state.MAX_UPLOAD_IMAGE_SIZE_PX,
    MAX_UPLOAD_MINI_SIZE_PX: state => state.MAX_UPLOAD_MINI_SIZE_PX,
    vinScanActive: state => state.vinScanActive,
    registrationScanActive: state => state.registrationScanActive,
    qrScanActive: state => state.qrScanActive,
    enterCarActive: state => state.enterCarActive,
    lastUpdateCall: state => state.lastUpdateCall,
    newDataAvailable: state => state.newDataAvailable,
    imprintVisible: state => state.imprintVisible,
    privacyVisible: state => state.privacyVisible,
    customerInfoVisible: state => state.customerInfoVisible,
    onboardingVisible: state => state.onboardingVisible,
    damageDummyCar: state => state.damageDummyCar,
  },
  actions: {
    uploadS3CameraPhoto(context, payload) {
      return axios.post(context.rootGetters.API.baseUrl + 'uploads/camera-image-s3/', payload, context.rootGetters.axiosConfig)
        .then((res) => {
          return res.data
        })
        .catch((error) => console.log(error))
    },
    getS3Image(context, payload) {
      return axios.get(context.rootGetters.API.baseUrl + 'uploads/image-s3/' + encodeURIComponent(payload), context.rootGetters.axiosConfig)
        .then((res) => {
          return res.data
        })
        .catch((error) => console.log(error))
    },
    fileToSizedBlob(context, payload) {
      return ImageBlobReduce().toBlob(payload.file, { max: context.getters.MAX_UPLOAD_IMAGE_SIZE_PX }).then(
        blob => {
          return new Promise((resolve, reject) => {
            var reader = new FileReader()
            reader.onload = (e) => {
              resolve(e.target.result)
            }
            reader.onerror = reject
            reader.readAsDataURL(blob)
          })
        }
      )
    },
    fileToMiniBlob(context, payload) {
      return ImageBlobReduce().toBlob(payload.file, { max: context.getters.MAX_UPLOAD_MINI_SIZE_PX }).then(
        blob => {
          return new Promise((resolve, reject) => {
            var reader = new FileReader()
            reader.onload = (e) => {
              resolve(e.target.result)
            }
            reader.onerror = reject
            reader.readAsDataURL(blob)
          })
        }
      )
    },
    fileToBlob(context, payload) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader()
        reader.onload = (e) => {
          resolve(e.target.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(payload.file)
      })
    },
  },
  mutations: {
    SET_BUSY(state, data) {
      state.busy = data
    },
    SET_TOKEN(state, data) {
      state.axiosConfig = {
        headers: { 'Authorization': 'Bearer ' + data }
      }
      console.log(state.axiosConfig)
    },
    SET_WHITELABEL(state, data) {
      state.whitelabel = data
    },
    SET_VIN_SCAN_ACTIVE(state, data) {
      console.log('SET_VIN_SCAN_ACTIVE')
      state.vinScanActive = data
    },
    SET_REGISTRATION_SCAN_ACTIVE(state, data) {
      state.registrationScanActive = data
    },
    SET_QR_SCAN_ACTIVE(state, data) {
      state.qrScanActive = data
    },
    SET_ENTER_CAR_ACTIVE(state, data) {
      state.enterCarActive = data
    },
    RESET_LAST_UPDATE_CALL(state) {
      state.lastUpdateCall = new Date()
    },
    SET_NEW_DATA_AVAILABLE(state, data) {
      state.newDataAvailable = data
    },
    SET_IMPRINT_VISIBLE(state, data) {
      state.imprintVisible = data
    },
    SET_PRIVACY_VISIBLE(state, data) {
      state.privacyVisible = data
    },
    SET_CUSTOMER_INFO_VISIBLE(state, data) {
      state.customerInfoVisible = data
    },
    SET_ONBOARDING_VISIBLE(state, data) {
      state.onboardingVisible = data
    },
    SET_DAMAGE_DUMMY_CAR(state, data) {
      state.damageDummyCar = data
    },
  },
  modules: {
    customers,
    cars,
    offers,
    requests,
    stations
  },
  strict: debug
})
