import axios from "axios";

const state = {
  selectedRequest: null,
  requestPending: false,
  cameraPhotos: [],
  documentPhotos: [],
  voiceMessage: null,
  lastUpdate: null,
  requests: [],
  requestStates: [],
  damageLocations: [
    {
      location: "FENDER_F_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Kotflügel vorne rechts",
    },
    {
      location: "FENDER_F_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Kotflügel vorne links",
    },
    {
      location: "FENDER_B_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Kotflügel hinten rechts",
    },
    {
      location: "FENDER_B_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Kotflügel hinten links",
    },
    {
      location: "DOOR_F_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Tür vorne rechts",
    },
    {
      location: "DOOR_F_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Tür vorne links",
    },
    {
      location: "DOOR_B_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Tür hinten rechts",
    },
    {
      location: "DOOR_B_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Tür hinten links",
    },
    {
      location: "SIDE_B_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Seitenwand hinten rechts",
    },
    {
      location: "SIDE_B_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Seitenwand hinten links",
    },
    {
      location: "ENGINE_HOOD",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Motorhaube",
    },
    {
      location: "TAILGATE",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Heckklappe",
    },
    {
      location: "BUMPER_F",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Stoßstange vorne",
    },
    {
      location: "BUMPER_B",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Stoßstange hinten",
    },
    {
      location: "A_PILLAR_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "A-Säule rechts",
    },
    {
      location: "A_PILLAR_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "A-Säule links",
    },
    {
      location: "SILL_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Einstieg links",
    },
    {
      location: "SILL_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Einstieg rechts",
    },
    {
      location: "ROOF",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Dach",
    },
    {
      location: "Rim_F_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Felge vorne rechts",
    },
    {
      location: "RIM_F_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Felge vorne links",
    },
    {
      location: "RIM_B_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Felge hinten rechts",
    },
    {
      location: "RIM_B_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Felge hinten links",
    },
    {
      location: "FUEL_FILLER_CAP",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Tankklappe",
    },
    {
      location: "REAR_MIRROR_L",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Spiegel links",
    },
    {
      location: "REAR_MIRROR_R",
      type: "",
      possibleTypes: ["PAINT", "DENT", "PAINT_DENT", "CRACK"],
      displayName: "Spiegel rechts",
    },
    {
      location: "WINDSCHIELD",
      type: "",
      possibleTypes: ["CRACK"],
      displayName: "Windschutzscheibe",
    },
    {
      location: "REAR_WINDOW",
      type: "",
      possibleTypes: ["CRACK"],
      displayName: "Heckscheibe",
    },
    {
      location: "HEADLIGHT_R",
      type: "",
      possibleTypes: ["CRACK"],
      displayName: "Scheinwerfer rechts",
    },
    {
      location: "HEADLIGHT_L",
      type: "",
      possibleTypes: ["CRACK"],
      displayName: "Scheinwerfer links",
    },
    {
      location: "TAILLIGHT_R",
      type: "",
      possibleTypes: ["CRACK"],
      displayName: "Rücklicht rechts",
    },
    {
      location: "TAILLIGHT_L",
      type: "",
      possibleTypes: ["CRACK"],
      displayName: "Rücklicht links",
    },
  ],
  damageTypes: [
    {
      type: "PAINT",
      displayName: "Lackschaden",
    },
    {
      type: "DENT",
      displayName: "Delle",
    },
    {
      type: "PAINT_DENT",
      displayName: "Lackschaden mit Delle",
    },
    {
      type: "CRACK",
      displayName: "Bruch oder Riss",
    },
  ],
};

// getters
const getters = {
  requests: (state) => state.requests,
  requestPending: (state) => state.requestPending,
  lastUpdate: (state) => state.lastUpdate,
  activeRequests: (state) => state.activeRequests,
  openRequests: (state) =>
    state.requests.filter(
      (o) =>
        o.status !== "FIXED" &&
        (o.status !== "INVOICE_SENT") & (o.status !== "INVOICE_PAID")
    ),
  fixedAndReadyRequests: (state) => state.requests.filter((o) => o.status === "FIXED"),
  fixedRequests: (state) =>
    state.requests.filter(
      (o) => o.status === "INVOICE_SENT" || o.status === "INVOICE_PAID"
    ),
  selectedRequest: (state) => state.selectedRequest,
  requestStates: (state) => state.requestStates,
  damageLocations: (state) => state.damageLocations,
  cameraPhotos: (state) => state.cameraPhotos,
  documentPhotos: (state) => state.documentPhotos,
  uploadPhotos: (state) => {
    return state.cameraPhotos.concat(state.documentPhotos);
  },
  voiceMessage: (state) => state.voiceMessage,
  requestsByStatus: (state) => (status) => {
    return state.requests.filter((o) => o.status === status && o.type === "DIRECT");
  },
  requestStatusName: (state) => (status) =>
    state.requestStates.find((o) => o.status === status).displayName,
  requestStatusActionName: (state) => (status) =>
    state.requestStates.find((o) => o.status === status).actionName,
  damageTypes: (state) => state.damageTypes,
  damageTypeName: (state) => (damageType) =>
    damageType ? state.damageTypes.find((o) => o.type === damageType).displayName : "",
  damageLocationName: (state) => (damageLocation) =>
    damageLocation
      ? state.damageLocations.find((o) => o.location === damageLocation).displayName
      : "",
  hasCalculation: () => (request) => {
    return (
      request.status === "QUALIFIED" ||
      request.status === "FIASCO_CALCULATION_REQUESTED" ||
      request.status === "FIASCO_CALCULATION_FEEDBACK" ||
      request.status === "FIASCO_CALCULATING" ||
      request.status === "CALCULATION_DONE" ||
      request.status === "FIASCO_CALCULATION_SENT"
    );
  },
  nextSteps: () => (status) => {
    switch (status) {
      case "REQUESTED":
        return ["FEEDBACK", "QUALIFIED"];
      case "FEEDBACK":
        return ["REQUESTED", "QUALIFIED"];
      case "QUALIFIED":
        return [
          "FIASCO_CALCULATION_REQUESTED",
          "FIASCO_CALCULATION_FEEDBACK",
          "FIASCO_CALCULATION_QUALIFIED",
          "CALCULATION_DONE",
        ];
      case "FIASCO_CALCULATION_REQUESTED":
        return [
          "QUALIFIED",
          "FIASCO_CALCULATING",
          "FIASCO_CALCULATION_QUALIFIED",
          "FIASCO_CALCULATION_SENT",
        ];
      case "FIASCO_CALCULATION_FEEDBACK":
        return [
          "QUALIFIED",
          "FIASCO_CALCULATING",
          "FIASCO_CALCULATION_QUALIFIED",
          "FIASCO_CALCULATION_SENT",
        ];
      case "FIASCO_CALCULATING":
        return ["FIASCO_CALCULATION_FEEDBACK", "FIASCO_CALCULATION_SENT"];
      case "FIASCO_CALCULATION_QUALIFIED":
        return ["FIASCO_CALCULATION_FEEDBACK", "FIASCO_CALCULATING"];
      case "FIASCO_CALCULATION_SENT":
        return ["CALCULATION_DONE", "OFFERED"];
      case "CALCULATION_DONE":
        return ["OFFERED"];
      case "OFFERED":
        return ["ACCEPTED", "DECLINED"];
      case "ACCEPTED":
        return [
          "INSPECTION_REQUESTED",
          "INSPECTION_AGREED",
          "REPAIR_REQUESTED",
          "REPAIR_AGREED",
        ];
      case "INSPECTION_REQUESTED":
        return ["INSPECTION_REQUESTED", "INSPECTION_AGREED", "INSPECTED"];
      case "INSPECTION_AGREED":
        return ["INSPECTION_AGREED", "INSPECTED"];
      case "INSPECTED":
        return ["REPAIR_REQUESTED", "REPAIR_AGREED"];
      case "REPAIR_REQUESTED":
        return ["REPAIR_AGREED", "IN_REPAIR"];
      case "REPAIR_AGREED":
        return ["IN_REPAIR"];
      case "IN_REPAIR":
        return ["FIXED"];
      case "FIXED":
        return ["INVOICE_SENT", "INVOICE_PAID"];
      case "INVOICE_SENT":
        return ["INVOICE_PAID"];
    }
  },
  recentMessage: (state) => {
    let filteredMessages = state.selectedRequest.messages.filter(
      (o) => o.type === "PUBLIC"
    );
    return filteredMessages[filteredMessages.length - 1];
  },
};

// actions
const actions = {
  getByCustomer(context, payload) {
    return axios
      .get(
        context.rootGetters.API.baseUrl + "requests/customer/" + payload,
        context.rootGetters.axiosConfig
      )
      .then((response) => context.commit("SET_ALL", response.data))
      .catch((error) => console.log(error));
  },
  getById(context, id) {
    return axios
      .get(
        context.rootGetters.API.baseUrl + "requests/" + id,
        context.rootGetters.axiosConfig
      )
      .then((response) => context.commit("SET_SELECTED_REQUEST", response.data))
      .catch((error) => console.log(error));
  },
  create(context, payload) {
    console.log("update request");
    return axios
      .put(
        context.rootGetters.API.baseURL + "requests/",
        payload,
        context.rootGetters.axiosConfig
      )
      .then((response) => {
        return response;
      })
      .catch((error) => console.log(error));
  },
  update(context, payload) {
    console.log("update request");
    delete payload.messages;
    return axios
      .patch(
        context.rootGetters.API.baseURL + "requests/" + payload._id,
        payload,
        context.rootGetters.axiosConfig
      )
      .then((response) => {
        return response;
      })
      .catch((error) => console.log(error));
  },
  getRequestStates(context) {
    return axios
      .get(
        context.rootGetters.API.baseUrl + "requests/request-states",
        context.rootGetters.axiosConfig
      )
      .then((response) => context.commit("SET_REQUEST_STATES", response.data))
      .catch((error) => console.log(error));
  },
  sendMessage(context, payload) {
    console.log("sendMessage request");
    return axios
      .put(
        context.rootGetters.API.baseURL + "requests/" + payload.requestId + "/message",
        payload,
        context.rootGetters.axiosConfig
      )
      .then((response) => {
        return response;
      })
      .catch((error) => console.log(error));
  },
  deletePhoto(context, payload) {
    console.log("deletePhoto");
    return axios
      .patch(
        context.rootGetters.API.baseURL + "requests/photo/delete",
        payload,
        context.rootGetters.axiosConfig
      )
      .then((response) => {
        console.log(response);
        console.log("DELETED");
      })
      .catch((error) => {
        console.log(error);
      });
  },
  uploadCameraPhoto(context, payload) {
    console.log("uploadCameraPhoto");
    return axios
      .post(
        context.rootGetters.API.baseURL + "requests/upload/camera",
        payload,
        context.rootGetters.axiosConfig
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  uploadVoiceMessage(context, payload) {
    console.log("uploadVoiceMessage");
    return axios
      .post(
        context.rootGetters.API.baseURL + "requests/upload/audio",
        payload,
        context.rootGetters.axiosConfig
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  getLastUpdate(context, payload) {
    return axios
      .get(
        context.rootGetters.API.baseUrl + "requests/last-update/customer/" + payload,
        context.rootGetters.axiosConfig
      )
      .then((response) => context.commit("SET_LAST_UPDATE", response.data[0].updatedAt))
      .catch((error) => console.log(error));
  },
};

// mutations
const mutations = {
  SET_ALL(state, data) {
    state.requests = data;
  },
  SET_SELECTED_REQUEST(state, data) {
    console.log("SET_SELECTED_REQUEST");
    console.log(data);
    state.selectedRequest = data;
  },
  SET_REQUEST_STATES(state, data) {
    state.requestStates = data;
  },
  SET_CAMERA_PHOTOS(state, data) {
    console.log("SET_CAMERA_PHOTOS");
    state.cameraPhotos = data;
  },
  ADD_CAMERA_PHOTO(state, data) {
    console.log("ADD_CAMERA_PHOTO");
    state.cameraPhotos.push(data);
  },
  REMOVE_CAMERA_PHOTO(state, index) {
    console.log("REMOVE_CAMERA_PHOTO");
    state.cameraPhotos.splice(index, 1);
  },
  SET_DOCUMENT_PHOTOS(state, data) {
    state.documentPhotos = data;
  },
  SET_VOICE_MESSAGE(state, data) {
    console.log("SET_VOICE_MESSAGE");
    state.voiceMessage = data;
  },
  SET_REQUEST_PENDING(state, data) {
    state.requestPending = data;
  },
  SET_LAST_UPDATE(state, data) {
    state.lastUpdate = data;
  },
  /*
  UPDATE_REQUEST (state, data) {
    state.requests = state.requests.filter(o => o.fiascoId !== data.fiascoId)
    state.requests.push(data)
    console.log('UPDATE_REQUEST')
    console.log(state.requests)
  }
  */
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
