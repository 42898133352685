export default {
  methods: {
    test: function() {
      
    }
  },
  measurementId: function() {
    return 'G-0J9JK7P4BT';
  },

  // Returns true if the user opted out of Google analytics. Otherwise false is returned.
  userOptedOut: function() {
    return (document.cookie.split(';').some((item) => item.trim().startsWith('opt-out=')));
  },

  // This will opt-out the user from Google Analytics. It stores the decision in a cookie
  // and configures gtag not to send anything to Google servers.
  optOut: function() {
    // Don't need to do anything if nothing changes.
    if (this.userOptedOut()) {
      return;
    }

    
    
    document.cookie = "opt-out-for-Google-cookies=1; expires=Sun, 22 Dec 2199 23:59:59 GMT; SameSite=None; Secure"
    this.updateGTagConfig();
  },

  // This will opt-in the user to Google Analytics. It stores the decision in a cookie
  // and configures gtag to be able to send analytics to Google servers.
  optIn: function() {
    // Don't need to do anything if nothing changes.
    if (!this.userOptedOut()) {
      return;
    }

    document.cookie = "opt-out-for-Google-cookies=1; expires=Sun, 22 Dec 1996 00:00:00 GMT; SameSite=None; Secure"
    this.updateGTagConfig();
  },

  updateGTagConfig: function() {
    window[`ga-disable-${this.measurementId()}`] = this.userOptedOut();
  },
}