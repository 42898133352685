import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueGlide from 'vue-glide-js'
import 'vue-glide-js/dist/vue-glide.css'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

import VueQrcodeReader from 'vue-qrcode-reader'

import VueWebCam from "vue-web-cam"
import VueGtag from 'vue-gtag'

import PriceRating from './components/PriceRating.vue'
import S3Image from './components/S3Image.vue'
import DateFormatMixin from './mixins/dateDisplay'
import GoogleAnalytics from './plugins/googleAnalytics'

Vue.config.productionTip = false

Vue.use(VueGlide)
Vue.use(VueQrcodeReader)
Vue.use(VueWebCam)
Vue.use(VueGtag, {
  config: {
    id: GoogleAnalytics.measurementId(),
    params: {
      // TODO: The domain name should be dependent on the server environment. On localhost it should be 'none'.
      cookie_domain: "app.fiasco.de",
      cookie_flags: 'SameSite=None;Secure',
    }
  },
  enabled: !GoogleAnalytics.userOptedOut(),
})

Vue.mixin(DateFormatMixin)

Vue.component('s3-image', S3Image)
Vue.component('price-rating', PriceRating)
Vue.component('vue-timepicker', VueTimepicker)
Vue.component('vue-cropper', VueCropper)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
