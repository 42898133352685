<template>
  <div class="onboarding screen">
    <div class="onboarding-content">
      <img src="@/assets/gfx/fiasco-logo.png" v-if="!whitelabel" />
      <img
        v-if="whitelabel === 'mobilityconcept'"
        src="@/assets/gfx/mobility-concept-logo.png"
      />
      <p>Willkommen bei deiner <strong>digitalen Schadensmeldung</strong></p>
      <p>
        Hier kannst du deinen Schaden am Fahrzeug direkt deiner Werkstatt
        zusenden und stehst mit dem
        <img
          v-if="!whitelabel"
          class="inline-logo"
          src="@/assets/gfx/fiasco-logo.png"
        /><span v-if="!whitelabel">-</span>Chat in direktem Kontakt!
      </p>
      <button @click="close()">Weiter</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Onboarding",
  data() {
    return {
      hits: 0,
      password: "123",
      loginForm: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    goMain() {
      this.$router.push({ name: "main" });
    },
    close() {
      this.$store.commit("SET_ONBOARDING_VISIBLE", false);
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      loginError: "customers/loginError",
      whitelabel: "whitelabel",
    }),
  },
};
</script>
