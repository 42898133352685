<template>
  <div class="create-request-vin">
    <h2 class="center">Fahrzeug-Nummer scannen</h2>
    <p class="center" v-if="cropperVisible">Nummer ausschneiden:</p>

    <div class="create-request-vin-scan">
      <div v-if="cropperVisible">
        <vue-cropper
          v-show="selectedFile"
          ref="cropper"
          :src="selectedFile"
          alt="Source Image"
        ></vue-cropper>
      </div>
      <div class="create-request-vin-scan-preview-outer"></div>
    </div>

    <div class="create-request-vin-scan-nav">
      <label
        for="file-select"
        class="input-camera profile-btn-avatar"
        v-if="!cropperVisible"
      >
        <img
          class="input-camera-icon btn-icon"
          src="@/assets/gfx/icon_camera.png"
        />
      </label>
      <input
        id="file-select"
        class="input-camera profile-btn-avatar"
        type="file"
        capture="environment"
        accept="image/*"
        @change="onFileSelect"
        v-if="!cropperVisible"
      />
      <button
        class="btn-icon round small"
        @click="cancelCrop()"
        v-if="cropperVisible"
      >
        <img src="@/assets/gfx/icon_trash_white.png" />
      </button>
      <button
        class="btn-icon round small"
        @click="commitCrop()"
        v-if="cropperVisible"
      >
        <img src="@/assets/gfx/icon_check_white.png" />
      </button>
    </div>
    <div class="create-request-vin-scan-result" v-if="resultString">
      <p>Erkannte VIN:</p>
      <input type="text" v-model="resultString" />
      <p v-if="resultString.length !== 17">
        Achtung: Die VIN muss 17 Stellen haben. ({{ resultString.length }})
      </p>
    </div>
    <div class="create-request-vin-scan-subnav">
      <button @click="commitVin" v-if="resultString && !cropperVisible">
        VIN übernehmen
      </button>
      <button class="secondary" @click="close()" v-if="!cropperVisible">
        Abbrechen
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { createWorker } from "tesseract.js";

export default {
  name: "CreateRequestVin",
  data() {
    return {
      photoIndex: 0,
      player: null,
      canvas: null,
      resultString: "",
      worker: null,
      cropperVisible: false,
      selectedFile: false,
      croppedImage: null,
      editCar: {
        vin: "",
        kilometers: 0,
        owner: {},
      },
    };
  },
  methods: {
    async startScan() {
      this.$store.commit("SET_BUSY", true);
      // const rectangle = { left: 70, top: 290, width: 900, height: 160 }
      // const { data: { text } } = await this.worker.recognize(this.canvas, { rectangle })

      await this.worker.setParameters({
        tessedit_char_whitelist: this.VIN_WHITELIST,
      });

      const {
        data: { text },
      } = await this.worker.recognize(this.croppedImage);
      console.log(text);
      this.resultString = text.replace(/ /g, "");
      this.resultString = this.resultString.replace(/(\r\n|\n|\r)/gm, "");
      this.$store.commit("SET_BUSY", false);
      // await this.worker.terminate()
    },
    close() {
      // this.$emit("close");
      this.$router.push({ name: "CreateRequestCar" });
    },
    commitVin() {
      this.editCar.customer = this.customer._id;
      this.editCar.vin = this.resultString;
      this.$store.commit("cars/SET_CREATE_REQUEST_CAR", this.editCar);
      // this.$emit("commit");
      this.$router.push({ name: "CreateRequestDamageIntro" });
    },
    async initTesseract() {
      this.$store.commit("SET_BUSY", true);
      this.worker = createWorker({
        logger: (m) => console.log(m),
      });

      await this.worker.load();
      await this.worker.loadLanguage("eng");
      await this.worker.initialize("eng");
      this.$store.commit("SET_BUSY", false);
      // const { data: { text } } = await this.worker.recognize('https://tesseract.projectnaptha.com/img/eng_bw.png')
      // console.log(text)
    },
    onFileSelect(e) {
      // don't resize image
      this.resultString = "";
      const file = e.target.files[0];
      this.mime_type = file.type;
      console.log(this.mime_type);
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedFile = event.target.result;
          this.cropperVisible = true;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    cancelCrop() {
      this.selectedFile = null;
      this.cropperVisible = false;
    },
    commitCrop() {
      this.croppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.cropperVisible = false;
      this.selectedFile = false;
      this.startScan();
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      customerCars: "cars/customerCars",
      selectedRequest: "requests/selectedRequest",
      createRequestCar: "cars/createRequestCar",
      VIN_WHITELIST: "cars/VIN_WHITELIST",
    }),
  },
  mounted() {
    console.log("mounted VIN Scanner");
    this.initTesseract();
  },
  beforeDestroy() {
    if (this.worker) {
      this.worker.terminate().then(() => {
        console.log("worker terminated");
      });
    }
  },
};
</script>
