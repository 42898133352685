import axios from 'axios'

const state = {
  stations: [],
  customerStation: null
}

// getters
const getters = {
  stations: state => state.stations,
  customerStation: state => state.customerStation
}

// actions
const actions = {
  getAll (context) {
    return axios.get(context.rootGetters.API.baseUrl + 'stations', context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_ALL', response.data))
      .catch((error) => console.log(error))
  },
  getByCode (context, payload) {
    return axios.get(context.rootGetters.API.baseUrl + 'stations/code/' + payload, context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_CUSTOMER_STATION', response.data))
      .catch((error) => console.log(error))
  },
  getCustomerStation (context, payload) {
    return axios.get(context.rootGetters.API.baseUrl + 'stations/id/' + payload, context.rootGetters.axiosConfig)
      .then((response) => {
        context.commit('SET_CUSTOMER_STATION', response.data)
        console.log(response)
      })
      .catch((error) => console.log(error))
  },
  create (context, payload) {
    console.log('update station')
    return axios.put(context.rootGetters.API.baseURL + 'stations/', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  update (context, payload) {
    console.log('update station')
    return axios.patch(context.rootGetters.API.baseURL + 'stations/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  deletePoster (context, payload) {
    console.log('deletePoster')
    return axios.patch(
      context.rootGetters.API.baseURL + 'stations/poster/delete',
      payload,
      context.rootGetters.axiosConfig
    ).then(response => {
      // success callback
      console.log(response)
      console.log('DELETED')
    }).catch(error => {
      console.log(error)
    })
  },
}

// mutations
const mutations = {
  SET_ALL (state, data) {
    state.stations = data
  },
  SET_SELECTED_STATION (state, data) {
    state.selectedStation = data
  },
  SET_CUSTOMER_STATION (state, data) {
    state.customerStation = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
