<template>
  <div class="offer screen" v-if="selectedOffer">
    <div class="header">
      <div class="header-chevron-back" @click="goBack()"></div>
      <img
        class="header-avatar"
        :src="
          API.imageUrl +
          'requests/' +
          selectedRequest.images[selectedRequest.images.length - 1]
        "
        v-if="selectedRequest.s3Images.length === 0"
      />
      <s3-image
        class="header-avatar"
        :image="selectedRequest.s3Images[selectedRequest.s3Images.length - 1]"
        v-if="selectedRequest.s3Images.length > 0"
        @click="showProfile()"
      />
      <div class="header-caption">
        <div
          class="header-caption-main"
          v-if="!selectedOffer.calculation.useLumpSumPrice"
        >
          Angebot:
          {{ (selectedOffer.calculation.totalPrice * 1.19).toFixed(0) }},-€
        </div>
        <div
          class="header-caption-main"
          v-if="selectedOffer.calculation.useLumpSumPrice"
        >
          Angebot:
          {{ (selectedOffer.calculation.lumpSumPrice * 1.19).toFixed(0) }},-€
        </div>
        <div class="header-caption-sub">
          {{ selectedRequest.title }} vom
          {{ formatDate(selectedRequest.createdAt) }}
        </div>
      </div>
      <!--img
        class="header-edit"
        src="@/assets/gfx/icon_edit.png"
        @click="goEdit()"
      /-->
    </div>
    <div class="header-hero">
      <img
        class="header-hero-image"
        :src="
          API.imageUrl +
          'stations/posters/' +
          customer.selectedStation.posterImages[0]
        "
      />
      <div class="header-hero-caption">
        <h2>{{ customer.selectedStation.name }}</h2>
        <div class="header-hero-caption-sub">
          Registriert seit {{ formatDate(customer.selectedStation.createdAt) }}
        </div>
      </div>
      <div
        class="header-hero-status"
        v-if="selectedRequest.status !== 'OFFERED'"
      >
        <div class="header-hero-status-inner">
          <span class="header-hero-status-label">Status: </span>
          <img src="@/assets/gfx/icon_check.png" />
          {{ requestStatusName(selectedRequest.status) }}
        </div>
      </div>
      <div
        class="header-hero-chat"
        @click="chatVisible = true"
        v-if="recentMessage"
      >
        <div class="chat-line">
          <!--div class="chat-line-name" v-if="recentMessage.customer">
            {{recentMessage.customer.firstName}}  {{recentMessage.customer.lastName}}
          </div-->
          <div class="chat-line-name" v-if="recentMessage.user">
            {{ recentMessage.user.firstName }} {{ recentMessage.user.lastName }}
          </div>
          <div class="chat-line-inner">
            <span v-if="recentMessage.user">
              <img
                class="chat-line-avatar"
                :src="API.imageUrl + 'users/' + recentMessage.user.image"
                v-if="!recentMessage.user.s3Image"
              />
              <s3-image
                class="chat-line-avatar"
                :image="recentMessage.user.s3Image"
                v-if="recentMessage.user.s3Image"
              />
            </span>
            <span v-if="recentMessage.customer">
              <img
                class="chat-line-avatar"
                :src="
                  API.imageUrl + 'customers/' + recentMessage.customer.image
                "
                v-if="!recentMessage.customer.s3Image"
              />
              <s3-image
                class="chat-line-avatar"
                :image="recentMessage.customer.s3Image"
                v-if="recentMessage.customer.s3Image"
              />
            </span>
            <div class="chat-line-message">
              {{ recentMessage.content }}
              <div class="chat-line-message-date">
                {{ formatDate(recentMessage.deliveredAt) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="header-hero-actions"
        v-if="selectedRequest.status === 'OFFERED'"
      >
        <div class="header-hero-action" @click="showContact('REPAIR')">
          <img
            class="header-hero-action-icon"
            src="@/assets/gfx/icon_wrench_blue.png"
          />
          <div class="header-hero-action-caption">
            <div class="header-hero-action-caption-top">Reparatur</div>
            <div class="header-hero-action-caption-sub">
              Termin anfragen {{ requestMode }}
            </div>
          </div>
        </div>
        <div class="header-hero-action" @click="showContact('INSPECT')">
          <img
            class="header-hero-action-icon"
            src="@/assets/gfx/icon_search_blue.png"
          />
          <div class="header-hero-action-caption">
            <div class="header-hero-action-caption-top">Besichtigung</div>
            <div class="header-hero-action-caption-sub">Termin anfragen</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-floating">
      <div class="content-floating-inner">
        <div class="offer-price">
          <div class="offer-price-label">Kostenvoranschlag</div>
          <div class="offer-price-content">
            <div>
              <div
                class="offer-price-value"
                :class="
                  'reliability-level-' +
                  selectedOffer.calculationLevel.toLowerCase()
                "
                v-if="!selectedOffer.calculation.useLumpSumPrice"
              >
                {{ (selectedOffer.calculation.totalPrice * 1.19).toFixed(0) }},-
                bis {{ (offerMaxPrice(selectedOffer) * 1.19).toFixed(0) }},- €
              </div>
              <div
                class="offer-price-value"
                :class="
                  'reliability-level-' +
                  selectedOffer.calculationLevel.toLowerCase()
                "
                v-if="selectedOffer.calculation.useLumpSumPrice"
              >
                {{
                  (selectedOffer.calculation.lumpSumPrice * 1.19).toFixed(0)
                }},- bis
                {{ (offerMaxPrice(selectedOffer) * 1.19).toFixed(0) }},- €
              </div>
            </div>
            <div
              class="offer-price-level"
              v-if="!selectedOffer.calculation.useLumpSumPrice"
              :class="
                'reliability-level-' +
                selectedOffer.calculationLevel.toLowerCase()
              "
            >
              {{ selectedOffer.calculationLevel }}
            </div>
            <div
              class="offer-price-level reliability-level-a"
              v-if="selectedOffer.calculation.useLumpSumPrice"
            >
              F
            </div>
            <div
              class="offer-price-explanation"
              v-if="
                selectedOffer.calculationLevel == 'A' &&
                !selectedOffer.calculation.useLumpSumPrice
              "
            >
              Zuverlässige Kalkulation: Maximal 10% Abweichung
            </div>
            <div
              class="offer-price-explanation"
              v-if="
                selectedOffer.calculationLevel == 'B' &&
                !selectedOffer.calculation.useLumpSumPrice
              "
            >
              Grobe Kalkulation: Maximal 30% Abweichung
            </div>
            <div
              class="offer-price-explanation"
              v-if="
                selectedOffer.calculationLevel == 'C' &&
                !selectedOffer.calculation.useLumpSumPrice
              "
            >
              Schätzung: Mehr als 30% Abweichung
            </div>
            <div
              class="offer-price-explanation"
              v-if="selectedOffer.calculation.useLumpSumPrice"
            >
              Fixpreis!
            </div>
          </div>
        </div>

        <div class="offer-state" v-if="selectedRequest.status === 'OFFERED'">
          <img src="@/assets/gfx/icon_check.png" />
          {{ requestStatusName(selectedRequest.status) }}
        </div>

        <!--div class="offer-certificates">
          <div class="offer-certificates-label">Zertifizierung:</div>
          <div class="offer-certificates-content">
            <img class="offer-certificate" src="@/assets/gfx/certificate-fiasco.png">
            <img class="offer-certificate" src="@/assets/gfx/certificate-dekra.png">
            <img class="offer-certificate" src="@/assets/gfx/certificate-iso.png">
            <img class="offer-certificate" src="@/assets/gfx/certificate-eurogarant.png">
            <img class="offer-certificate" src="@/assets/gfx/certificate-zkf.png">
          </div>
        </div-->

        <div class="offer-services">
          <div class="offer-services-label">Service:</div>
          <div class="offer-services-content">
            <div
              class="offer-service"
              v-if="selectedOffer.services.includes('PICKUP')"
            >
              <div class="offer-service-icon">
                <img src="@/assets/gfx/icon_holundbring_04.svg" />
              </div>
              Hol- und Bring-Service
            </div>
            <div
              class="offer-service"
              v-if="selectedOffer.services.includes('CLEANING')"
            >
              <div class="offer-service-icon">
                <img src="@/assets/gfx/icon_reinigung_05.svg" />
              </div>
              Innen- und Außenreinigung
            </div>
            <div
              class="offer-service"
              v-if="selectedOffer.services.includes('FREE_RENTAL_CAR')"
            >
              <div class="offer-service-icon">
                <img src="@/assets/gfx/icon_rental_car.png" />
              </div>
              Mietwagen kostenfrei
            </div>
            <div
              class="offer-service"
              v-if="selectedOffer.services.includes('WARRANTY_3_YEARS')"
            >
              <div class="offer-service-icon">
                <img src="@/assets/gfx/icon_3jahre_03.svg" />
              </div>
              3 Jahre Garantie
            </div>
          </div>
        </div>

        <!--div class="details-line">
          <div class="details-item">
            <img class="details-item-icon" src="@/assets/gfx/icon_calendar.png">
            <div class="details-item-caption">
              <div class="details-item-caption-label">
                Möglicher Reparaturtermin:
              </div>
              <div class="details-item-caption-value">
                Ab 24.11.2020
              </div>
            </div>
          </div>

          <div class="details-item">
            <img class="details-item-icon" src="@/assets/gfx/icon_location.png">
            <div class="details-item-caption">
              <div class="details-item-caption-label">
                Entfernung: 23km
              </div>
              <div class="details-item-caption-value">
                Gablonzer Straße 9<br>
                75181 Pforzheim
              </div>
            </div>
          </div>
        </div-->

        <div class="details-item">
          <img
            class="details-item-avatar"
            :src="API.imageUrl + 'users/' + selectedOffer.contactPerson.image"
            v-if="!selectedOffer.contactPerson.s3Image"
          />
          <s3-image
            class="details-item-avatar"
            :image="selectedOffer.contactPerson.s3Image"
            v-if="selectedOffer.contactPerson.s3Image"
          />
          <div class="details-item-caption">
            <div class="details-item-caption-label">Ansprechpartner:</div>
            <div class="details-item-caption-value">
              {{ selectedOffer.contactPerson.firstName }}
              {{ selectedOffer.contactPerson.lastName }}
            </div>
          </div>
        </div>

        <div
          class="details-item"
          @click="chatVisible = true"
          v-if="selectedRequest.messages.length === 0"
        >
          <div class="details-item-btn">
            <img class="details-item-icon" src="@/assets/gfx/icon_chat.png" />
          </div>
          <div class="details-item-caption">
            <div class="details-item-caption-quote">FIASCO Chat starten</div>
          </div>
        </div>
      </div>
    </div>
    <OfferContact
      v-if="requestMode"
      @close="requestMode = null"
      :mode="requestMode"
    />
    <Chat v-if="chatVisible" @close="chatVisible = false" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import OfferContact from "./OfferContact.vue";
import Chat from "./Chat.vue";

export default {
  name: "Offer",
  components: {
    OfferContact,
    Chat,
  },
  data() {
    return {
      contactVisible: false,
      chatVisible: false,
      requestMode: "",
      // offer: nul
    };
  },
  methods: {
    goBack() {
      // this.$router.go(-1)
      this.$router.push({ name: "main" });
      // this.$router.push({name: 'request-offers', params: {id: this.selectedRequest._id}})
    },
    showContact(mode) {
      this.requestMode = mode;
      this.contactVisible = true;
    },
    goEdit() {
      this.$router.push({
        name: "request-edit",
        params: { id: this.selectedRequest._id },
      });
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      recentMessage: "requests/recentMessage",
      requestStatusName: "requests/requestStatusName",
      selectedOffer: "offers/selectedOffer",
      offerMaxPrice: "offers/maxPrice",
      customerStation: "stations/customerStation",
    }),
  },
  watch: {
    requests: {
      deep: true,
      handler: function () {
        console.log("WATCH requests");
      },
    },
  },
  mounted() {
    if (!this.selectedRequest) {
      this.$store.commit(
        "requests/SET_SELECTED_REQUEST",
        this.requests.find((o) => o._id === this.$route.params.requestId)
      );
    }

    // this.offer = this.activeOffers.find(o => o._id === this.$route.params.id)

    if (
      this.selectedRequest.statusHistory.some((o) => o.status === "OFFERED")
    ) {
      this.$store.dispatch("offers/getById", this.selectedRequest.offer);
      // this.$store.dispatch('offers/getById', this.selectedRequest.offer)
    }
  },
};
</script>
