var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-request-car create-request-content"},[(
      !_vm.qrScanActive &&
      !_vm.vinScanActive &&
      !_vm.enterCarActive &&
      !_vm.registrationScanActive
    )?_c('div',{staticClass:"create-request-car-main"},[_c('div',{staticClass:"create-request-car-main-item",on:{"click":function($event){return _vm.goRegistrationScan()}}},[_c('img',{staticClass:"create-request-car-main-item-image",attrs:{"src":require("@/assets/gfx/placeholder_kfz_schein.jpg")}}),_vm._m(0),_c('img',{staticClass:"create-request-car-main-item-icon",attrs:{"src":require("@/assets/gfx/icon_camera.png")}})]),_c('div',{staticClass:"create-request-car-main-item",on:{"click":function($event){return _vm.goEnterCar()}}},[_c('img',{staticClass:"create-request-car-main-item-image small",attrs:{"src":require("@/assets/gfx/icon_edit.png")}}),_vm._m(1),_c('img',{staticClass:"create-request-car-main-item-icon",attrs:{"src":require("@/assets/gfx/icon_camera.png")}})]),_vm._m(2)]):_vm._e(),(
      !_vm.qrScanActive &&
      !_vm.vinScanActive &&
      !_vm.enterCarActive &&
      !_vm.registrationScanActive
    )?_c('div',{staticClass:"create-request-car-pool"},[(!_vm.qrScanActive && !_vm.vinScanActive)?_c('h3',{staticClass:"center"},[_vm._v(" Aus meinem Fahrzeugpool wählen: ")]):_vm._e(),_vm._l((_vm.customerCars),function(item){return _c('div',{key:item._id,staticClass:"create-request-car-pool-item",on:{"click":function($event){return _vm.onCarSelect(item)}}},[_c('div',{staticClass:"create-request-car-pool-item-caption"},[_vm._v(" "+_vm._s(item.manufacturer)+" "+_vm._s(item.model)+" "),_c('div',{staticClass:"small"},[_vm._v(_vm._s(item.numberPlate))]),_c('div',{staticClass:"small"},[_vm._v("FIN "+_vm._s(item.vin))])])])})],2):_vm._e(),_c('div',{staticClass:"create-request-btn-prev",on:{"click":function($event){return _vm.goBack()}}},[_c('img',{attrs:{"src":require("@/assets/gfx/arrow-prev.png")}})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-request-car-main-item-caption"},[_c('h2',[_vm._v("Fahrzeugschein scannen")]),_c('p',[_vm._v("Die Fahrzeug-Identifikationsnummer muss gut lesbar sein.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-request-car-main-item-caption"},[_c('h2',[_vm._v("Manuell eingeben")]),_c('p',[_vm._v("Die 17-stellige VIN einfach eintippen")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-request-car-sub"},[_c('p',[_vm._v("oder")])])}]

export { render, staticRenderFns }