<template>
  <div class="modal">
    <div class="modal-clicker" @click="goBack()"></div>
    <div class="popup chat">
      <div class="chat-header">
        <div class="chat-header-main">
          <img
            v-if="!whitelabel"
            class="chat-header-logo"
            src="@/assets/gfx/fiasco-logo.png"
            @click="goBack()"
          />
          <img
            v-if="whitelabel === 'rep2go'"
            class="chat-header-logo"
            src="@/assets/gfx/Rep2Go_logo_white.png"
            @click="goBack()"
          />
          <img
            v-if="whitelabel === 'mobilityconcept'"
            class="chat-header-logo"
            src="@/assets/gfx/mobility-concept-logo.png"
            @click="goBack()"
          />
          <img
            class="popup-close"
            src="@/assets/gfx/icon_close_black.png"
            @click="goBack()"
          />
        </div>
        <div class="chat-header-sub">Chat mit: {{ customer.selectedStation.name }}</div>
      </div>

      <div class="chat-conversation">
        <div
          class="chat-line"
          v-for="item in filteredMessages"
          :key="item._id"
          :class="{ me: item.customer }"
        >
          <div class="chat-line-name" v-if="item.customer">
            {{ item.customer.firstName }} {{ item.customer.lastName }}
          </div>
          <div class="chat-line-name" v-if="item.user">
            {{ item.user.firstName }} {{ item.user.lastName }}
          </div>
          <div class="chat-line-inner">
            <img
              class="chat-line-avatar"
              :src="API.imageUrl + 'users/' + item.user.image"
              v-if="item.user && !item.user.s3Image"
            />
            <s3-image
              class="chat-line-avatar"
              :image="item.user.s3Image"
              v-if="item.user && item.user.s3Image"
            />

            <img
              class="chat-line-avatar"
              :src="API.imageUrl + 'customers/' + item.customer.image"
              v-if="item.customer && !item.customer.s3Image"
            />
            <s3-image
              class="chat-line-avatar"
              :image="item.customer.s3Image"
              v-if="item.customer && item.customer.s3Image"
            />

            <div class="chat-line-message">
              {{ item.content }}
              <div class="chat-line-message-date">
                {{ formatDate(item.deliveredAt) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="chat-control">
        <input type="text" v-model="messageInput" />
        <div class="chat-control-send" @click="sendMessage()"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Chat",
  data() {
    return {
      hits: 0,
      messageInput: "",
    };
  },
  methods: {
    goBack() {
      this.$emit("close");
    },
    sendMessage() {
      let id = this.selectedRequest._id;
      let payload = {
        message: this.messageInput,
        requestId: id,
      };
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("requests/sendMessage", payload).then(() => {
        this.$store.dispatch("requests/getByCustomer", this.customer._id).then(() => {
          this.$store.commit(
            "requests/SET_SELECTED_REQUEST",
            this.requests.find((o) => o._id === id)
          );
          this.messageInput = "";
          this.$store.commit("SET_BUSY", false);
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      whitelabel: "whitelabel",
      customer: "customers/customer",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      requestStatusName: "requests/requestStatusName",
      activeOffers: "offers/activeOffers",
      offerMaxPrice: "offers/maxPrice",
      customerStation: "stations/customerStation",
    }),
    filteredMessages() {
      return this.selectedRequest.messages.filter((o) => o.type === "PUBLIC");
    },
  },
};
</script>
