<template>
  <div class="modal">
    <div class="modal-clicker" @click="close()"></div>
    <div class="popup customer-info">
      <img class="popup-close" src="@/assets/gfx/icon_close_black.png" @click="close()">
      <div class="popup-legals-content">
        <h3>Kundeninformationen für Bestandskunden</h3>

        <h4>1. Kundenkonto erstellen</h4>
        
        <p>
          1.1	Kunden haben die Möglichkeit, durch Eingabe eines QR- oder eines Werkstatt-Codes auf der Plattform ein Profil anzulegen und in diesem Informationen 
          zu ihrer Person sowie zu ihrem Fahrzeug zu hinterlegen. Hierzu wählt der Kunde einen personalisierten Benutzernamen und das dazu gehörende Passwort.
        </p>

        <p>
          1.2	Der Kunde kann die in seinem Profil hinterlegten Informationen jederzeit durch Anklicken seines Profilbildes einsehen und sowohl die Informationen zu 
          seiner Person also auch zu den hinterlegen Fahrzeugen ändern bzw. ergänzen.
        </p>

        <h4>2. Digitale Anfragen</h4>

        <p>
          2.1. Möchte der Kunde eine digitale Reparaturanfrage erstellen, so muss er sich zunächst mit seinen Zugangsdaten anmelden. 
        </p>

        <p>
          2.2. Nach erfolgter Anmeldung kann der Kunde über den Button „neue Anfrage“ eine neue digitale Anfrage erstellen. Zu diesem Zweck wird der Kunde zunächst 
          aufgefordert, mindestens vier Fotos des Schadens, der Gegenstand einer Reparatur sein soll, zu hinterlegen. Die Fotos sollten folgende Bildaufnahmen umfassen:
        </p>

        <ul>
          <li>Fahrzeug diagonal von vorn</li>
          <li>Fahrzeug diagonal von hinten</li>
          <li>Beschädigtes Teil (bzw. alle beschädigten Teile), 1-2 m Abstand</li>
          <li>Schaden in Nahaufnahme (50 cm Abstand)</li>
        </ul>
        
        <p>
          2.3. Durch Anklicken des Buttons „los geht´s“ kann der Kunde die Schadensaufnahme starten und entsprechende Fotos von seinem Endgerät hochladen bzw. durch 
          Aktivierung seiner Fotokamera anfertigen und im Anschluss hochladen. Durch Anklicken des Buttons „weiter“ gelangt der Kunde zur Eingabe der Fahrzeuginformationen. 
          Die Fahrzeug-Identifikationsnummer kann der Kunde durch Einscannen des Fahrzeugscheins, durch manuelle Eingabe oder durch Zugriff auf die in seinem Profil 
          hinterlegten Fahrzeuginformationen eingeben. Der Kunde wird sodann aufgefordert, den Fahrzeugtyp durch Anklicken der Optionen „Limousine“, „Coupe“ oder „Kombi/Suv“ 
          zu identifizieren und die beschädigten Teile an einem angezeigten 3D-Model des Fahrzeugs zu markieren sowie den Schadenstyp anzugeben. Im letzten Schritt kann der 
          Kunde weitere Angaben zu dem Schaden und dem Fahrzeug machen. Sofern er dies wünscht, kann der Kunde zudem die kostengünstigere Reparaturlösung „Smart Repair“ auswählen.
        </p>

        <p>
          2.4. Durch Anklicken des Buttons „weiter“ gelangt der Kunde zur Eingabe seiner Daten. Es werden automatisch die im Profil des Kunden hinterlegten Informationen 
          angezeigt, welche der Kunde aber ergänzen, ändern oder - mit Ausnahme seiner E-Mail-Adresse - entfernen kann. Der Kunde kann vor Abschluss des Verfahrens durch 
          Anklicken der „Zurück“-Buttons jederzeit überprüfen, ob alle Angaben richtig sind. Durch Anklicken des Buttons „an meine Werkstatt senden“ wird die digitale Anfrage 
          an die Werkstatt übermittelt. Die so übermittelte digitale Anfrage stellt kein bindendes Angebot des Kunden dar.
        </p>

        <h4>3. Eingangsbestätigung</h4>

        <p>
          Nachdem der Kunde seine digitale Anfrage eingestellt hat, erhält er zur Information eine Empfangsbestätigung per E-Mail, welche seine digitale Anfrage noch einmal 
          aufführt; diese E-Mail dient ausschließlich der Information des Kunden, dass seine digitale Anfrage bei uns eingegangen ist.
        </p>

        <h4>4. Kostenvoranschläge; Vertragsschluss mit der Werkstatt</h4>

        <p>
          4.1. Die Werkstatt überprüft die digitale Anfrage des Kunden und die dort enthaltenen Informationen und erstellt einen Kostenvoranschlag. 
        </p>

        <p>
          4.2. Fiasco informiert den Kunden per E-Mail, sobald der Kostenvoranschlag verfügbar ist. 
        </p>

        <p>
          4.3. Der Kunde kann nach Erhalt des Kostenvoranschlags über den Fiasco-Chat einen Besichtigungstermin oder direkt einen verbindlichen Reparaturtermin in der Werkstatt 
          buchen. Im letzteren Fall kommt der Vertrag zwischen dem Kunden und der Werkstatt über den Fiasco-Chat zustande. Hat der Kunde einen Besichtigungstermin in der Werkstatt 
          vereinbart, kann ein Vertrag entweder vor Ort in der Werkstatt zustande kommen oder nachträglich über den FIASCO-Chat. Es gelten die allgemeinen Geschäftsbedingungen der 
          jeweiligen Werkstatt. In keinem Fall werden wir Vertragspartner.
        </p>

        <h4>5. Verbindlichkeitsaussage von Kostenvoranschlägen</h4>

        <p>
          5.1. Die Zuverlässigkeit der einem Kostenvoranschlag zugrundeliegenden Kostenschätzung wird dem Kunden in Form eines maximal in Betracht kommenden prozentualen Zuschlags 
          auf die dort veranschlagten Reparaturkosten (nachfolgend „Verbindlichkeitsaussage“) angezeigt. 
        </p>

        <p>
          5.2. Die durch die Verbindlichkeitsaussage festgelegte Kostenhöchstgrenze ist für die Werkstatt verbindlich. 
        </p>

        <p>
          5.3.	Sofern laut Verbindlichkeitsaussage der Werkstatt Abweichungen vom Kostenvoranschlag von mehr als 30% in Betracht kommen, wird dem Kunden durch eine automatische 
          Anzeige auf der Plattform geraten, zunächst einen Besichtigungstermin in der Werkstatt zu vereinbaren. 
        </p>

        <p>
          5.4. Der Werkstatt steht es frei, einen unverbindlichen Kostenvoranschlag unter dem Vorbehalt einer vor Ort Besichtigung des Kundenfahrzeugs an den Kunden zu übermitteln. 
        </p>

        <h4>Zahlung</h4>

        <p>
          Nach Ausführung des Reparaturauftrags stellt die Werkstatt dem Kunden die hierfür angefallenen Kosten in Rechnung. Es gelten die allgemeinen Geschäftsbedingungen der 
          jeweiligen Werkstatt.
        </p>

        <h4>7. Speicherung des Vertragstextes</h4>

        <p>
          Sofern ein Vertrag mit der Werkstatt über den Fiasco-Chat zustande kommt, sind die Vertragsinformationen jederzeit im Fiasco-Chat einsehbar. 
        </p>

        <h4>8. Vertragssprache</h4>

        <p>
          Die Vertragssprache ist Deutsch.
        </p>

        <h4>9. Beanstandungen/Online-Streitbeilegung</h4>

        <p>
          Sollten Sie Anlass zu einer Beanstandung haben, können Sie auf der von der Europäischen Kommission zur Verfügung gestellten Internetseite zur Online-Streitbeilegung 
          Ihre Beschwerde eingeben. Ihre Beschwerde wird dann an uns weitergeleitet. Bitte rufen Sie dafür folgenden Link auf und folgen Sie den Anweisungen dort: 
          <a href= "https://ec.europa.eu/consumers/odr/" target="_blank" ref="nofollow noopener noreferrer">https://ec.europa.eu/consumers/odr/</a><br>
          Selbstverständlich können Sie sich auch gerne direkt an unseren Kundenservice wenden:<br>
          E-Mail: <a href="mailto:info@fiasco.de">info@fiasco.de</a><br>
          Telefon: <a href="tel:+49 7151 167823-0">+49 7151 167823-0</a>
        </p>

        <h4>10. Verbraucherschlichtung</h4>

        <p>
          An einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle nehmen wir nicht teil und sind dazu auch nicht verpflichtet.
        </p>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PopupCustomerInfo",
  data() {
    return {
      test: false,
    };
  },
  methods: {
    close() {
      this.$store.commit("SET_CUSTOMER_INFO_VISIBLE", false);
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      recentMessage: "requests/recentMessage",
      requestStatusName: "requests/requestStatusName",
      selectedOffer: "offers/selectedOffer",
      customerStation: "stations/customerStation",
    }),
  },
};
</script>
