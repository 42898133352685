<template>
  <div class="create-request-photos create-request-content">
    <div class="create-request-photos-hint" v-if="hintVisible">
      <p>
        <strong>Fotos von deinem Fahrzeug</strong> und dem Schaden helfen der
        Werkstatt, ein Angebot zu erstellen.
      </p>
      <p>Wir zeigen dir gleich ein paar <strong>Beispielbilder</strong>.</p>
      <button @click="onHintConfirm()">Alles klar!</button>
    </div>

    <Camera @grab="onGrab" v-if="!alternativeCamera" />

    <div class="create-request-photos-step">
      <div class="create-request-photos-step-title">
        {{ photoStep.title }}
      </div>
      <img :src="'./examples/' + photoStep.icon" />
    </div>

    <div class="create-request-photos-buttons">
      <button
        class="create-request-photos-btn-problems"
        v-if="!alternativeCamera"
        @click="alternativeCamera = true"
      >
        Probleme mit der Kamera?
      </button>
      <button
        class="create-request-photos-btn-problems"
        v-if="alternativeCamera"
        @click="alternativeCamera = false"
      >
        Zurück zur Live-Kamera
      </button>
      <label for="camera-select" class="input-camera" v-if="alternativeCamera">
        <img class="input-camera-icon" src="@/assets/gfx/icon_camera.png" />
      </label>
      <input
        id="camera-select"
        class="input-camera"
        type="file"
        capture="environment"
        accept="image/*"
        @change="onFileSelect"
        v-if="alternativeCamera"
      />
      <label for="file-select" class="input-camera" v-if="alternativeCamera">
        <img class="input-camera-icon" src="@/assets/gfx/icon_gallery.png" />
      </label>
      <input
        id="file-select"
        class="input-camera"
        type="file"
        accept="image/*"
        @change="onFileSelect"
        v-if="alternativeCamera"
      />
    </div>

    <div class="create-request-btn-prev" @click="goBack()">
      <img src="@/assets/gfx/arrow-prev.png" />
    </div>

    <transition name="fade">
      <div
        class="create-request-photos-gallery-btn"
        v-if="cameraPhotos.length > 0"
        @click="collectionDetailsVisible = true"
      >
        <img
          v-if="cameraPhotos.length > 0"
          :src="cameraPhotos[cameraPhotos.length - 1]"
        />
        <div class="create-request-photos-gallery-btn-count">
          {{ cameraPhotos.length }}
        </div>
      </div>
    </transition>

    <div class="create-request-btn-next" @click="goNext()">
      <img src="@/assets/gfx/arrow-next.png" />
    </div>

    <div
      class="create-request-photos-collection"
      v-if="!collectionDetailsVisible"
    >
      <!--div
        class="create-request-photos-collection-item"
        v-for="(item, index) in cameraPhotos"
        :key="index + 'col'"
      >
        <img :src="item" />
        <div
          class="create-request-photos-collection-item-btn-del"
          @click="removePhotoByIndex(index)"
        >
          <img src="@/assets/gfx/icon_close_black.png" />
        </div>
      </div>
      <div
        class="create-request-photos-collection-item"
        v-for="n in untakenPictures"
        :key="n"
      ></div-->
    </div>

    <transition name="fade">
      <div class="modal" v-if="collectionDetailsVisible">
        <div
          class="modal-clicker"
          @click="collectionDetailsVisible = false"
        ></div>
        <div class="create-request-photos-collection-details popup">
          <img
            class="popup-close"
            src="@/assets/gfx/icon_close_black.png"
            @click="collectionDetailsVisible = false"
          />
          <h3>Meine Fotos zum Schaden</h3>
          <div class="create-request-photos-collection-details-content">
            <div
              class="create-request-photos-collection-item"
              v-for="(item, index) in cameraPhotos"
              :key="index + 'col'"
            >
              <img :src="item" />
              <div
                class="create-request-photos-collection-item-btn-del"
                @click="removePhotoByIndex(index)"
              >
                <img src="@/assets/gfx/icon_close_black.png" />
              </div>
            </div>
            <div
              class="create-request-photos-collection-item"
              v-for="n in untakenPictures"
              :key="n"
            />

            <!--div
              class="create-request-photos-collection-details-item"
              v-for="(item, index) in cameraPhotos"
              :key="index + 'ph'"
            >
              <img :src="item" />
              <div
                class="create-request-photos-collection-details-item-btn-del"
                v-if="deletePhotoRequestIndex !== index"
                @click="deletePhotoRequestIndex = index"
              ></div>
              <div
                class="create-request-photos-collection-details-item-confirm"
                v-if="deletePhotoRequestIndex === index"
              >
                <button class="secondary small" @click="cancelDeletePhoto()">
                  Abbrechen
                </button>
                <button class="secondary small" @click="removePhoto()">
                  Löschen
                </button>
              </div>
            </div-->
          </div>
        </div>
      </div>
    </transition>

    <!--div class="create-request-photos-sub">
      <button class="small">Bild aus Galerie</button>
    </div-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Camera from "./Camera.vue";

export default {
  name: "CreateRequestPhotos",
  components: {
    Camera,
  },
  data() {
    return {
      hintVisible: false,
      photoIndex: 0,
      deletePhotoRequestIndex: -1,
      deletePhotoRequest: null,
      collectionDetailsVisible: false,
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      cameraStarted: false,
      alternativeCamera: false,
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "CreateRequestIntro" });
    },
    goNext() {
      this.$router.push({ name: "CreateRequestCar" });
    },
    onGrab(photo) {
      this.$store.commit("requests/ADD_CAMERA_PHOTO", photo);
      // this.photos = this.cameraPhotos
      // this.photos.push(photo)
      // this.$store.commit('requests/SET_CAMERA_PHOTOS', this.photos)
    },
    onFileSelect(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.$store.commit("SET_BUSY", true);
      this.$store
        .dispatch("fileToSizedBlob", { file: files[0] })
        .then((blob) => {
          this.$store.commit("requests/ADD_CAMERA_PHOTO", blob);
          // this.photos = this.cameraPhotos
          // this.photos.push(blob)
          // this.$store.commit('requests/SET_CAMERA_PHOTOS', this.photos)
          this.$store.commit("SET_BUSY", false);
        });
    },
    removePhotoByIndex(index) {
      this.$store.commit("requests/REMOVE_CAMERA_PHOTO", index);
      // this.photos = this.cameraPhotos
      // this.photos.splice(index, 1)
      // this.$store.commit('requests/SET_CAMERA_PHOTOS', this.photos)
    },
    removePhoto() {
      console.log(this.deletePhotoRequestIndex);
      this.$store.commit(
        "requests/REMOVE_CAMERA_PHOTO",
        this.deletePhotoRequestIndex
      );
      this.deletePhotoRequestIndex = -1;
    },
    onHintConfirm() {
      this.hintVisible = false;
      this.examplesVisible = true;
    },
    cancelDeletePhoto() {
      console.log("cancelDeletePhoto");
      this.deletePhotoRequestIndex = -1;
      console.log(this.deletePhotoRequestIndex);
    },
    showCollection() {
      if (this.cameraPhotos.length > 0) {
        this.collectionDetailsVisible = true;
      }
    },
    onStarted(stream) {
      console.log("On Started Event", stream);
      this.cameraStarted = true;
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
      this.cameraStarted = false;
    },
    stopCamera() {
      this.$refs.webcam.stop();
    },
    startCamera() {
      console.log("On Start");
      this.$refs.webcam.start();
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      console.log("On Camera Change Event", deviceId);
    },
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
    untakenPictures() {
      return this.cameraPhotos.length < 4 ? 4 - this.cameraPhotos.length : 0;
    },
    photoStep() {
      let step = {
        title: "",
        icon: "",
      };
      if (this.cameraPhotos.length === 0) {
        step.title = "Diagonal von vorne";
        step.icon = "photo-template-diagonal-front.png";
      }
      if (this.cameraPhotos.length === 1) {
        step.title = "Diagonal von hinten";
        step.icon = "photo-template-diagonal-rear.png";
      }
      if (this.cameraPhotos.length === 2) {
        step.title = "Beschädigte Teil(e) 1-2m Abstand";
        step.icon = "photo-template-part.png";
      }
      if (this.cameraPhotos.length > 2) {
        step.title = "Beschädigte Teil(e) nah";
        step.icon = "photo-template-damage.png";
      }
      return step;
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      customerCars: "cars/customerCars",
      selectedRequest: "requests/selectedRequest",
      cameraPhotos: "requests/cameraPhotos",
    }),
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      console.log(tail);
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
        this.startCamera();
      }
    },
  },
  mounted() {
    // this.initCam()
    /*
    this.cameraPhotos.forEach(element => {
      this.photos.push(element)
    })
    */
  },
  beforeDestroy() {
    // this.stopCamera()
  },
};
</script>
