<template>
  <div class="modal">
    <div class="modal-clicker" @click="close()"></div>
    <div class="popup privacy">
      <img class="popup-close" src="@/assets/gfx/icon_close_black.png" @click="close()">
      <div class="popup-legals-content">
        <h3>Datenschutzinformation</h3>

        <p>
          <b>Wir</b> als Anbieter der Web-App „Fiasco“ (auch <b>„Web-App“</b>) sind <b>Verantwortliche</b> im Sinne
          des geltenden insbesondere der Datenschutz-Grundverordnung („DSGVO“), für die personenbezogenen Daten der
          Nutzer<sup>*</sup>in („<b>Sie</b>“) dieser App.
        </p>

        <p>
          Im Folgenden unterrichten wir Sie übersichtlich im Rahmen unserer Informationspflichten (Art. 13 ff. DSGVO)
          darüber, welche Daten bei Nutzung unserer Web-App verarbeitet werden und auf welcher Rechtsgrundlage dies
          geschieht. Sie erhalten außerdem Informationen welche Rechte Sie gegenüber uns und gegenüber der zuständigen
          Aufsichtsbehörde haben.
        </p>


        <h4>1. Informationen zum Verantwortlichen</h4>

        <p>
          FIASCO GmbH<br>
          Harun Coşkun &amp; Önder Aslan<br>
          Auf der Höhe 2<br>
          71394 Kernen im Remstal<br>
          <br>
          E-Mail: <a href="mailto:info@fiasco.de">info@fiasco.de</a><br>
          Telefon: <a href="tel:+49 7151 167823-0">+49 7151 167823-0</a>
        </p>

        <p>
          Hinweis: Wenn wir nachfolgend von „<b>Smartphone</b>“ sprechen, meinen wir jedes mobile Endgerät, also auch
          bspw. Tablets.
        </p>
        
        
        <h4>2. Informatorische Nutzung unserer Web-App</h4>

        <p>
          Wenn Sie unsere Website aufrufen, um sie lediglich zu besuchen, werden so genannte Logfiles verarbeitet, indem
          sie von unserem System automatisiert erfasst werden.
        </p>

        <p>
          Folgende Logfiles werden automatisiert verarbeitet:
        </p>

        <ul>
          <li>(anonymisierte) IP-Adresse des anfragenden Rechners</li>
          <li>Request-Zeile</li>
          <li>Timestamp (Tag und Uhrzeit des Website-Aufrufs)</li>
          <li>Status Code</li>
          <li>Größe des Response Bodies</li>
          <li>Referrer (Seite, von der Sie zu uns gekommen sind)</li>
          <li>User Agent (Art und Version des Browsers und Betriebssystems)</li>
        </ul>

        <p>
          Die Logfiles enthalten Ihre IP-Adresse und möglicherweise weitere personenbezogene Daten. Daher ist eine Zuordnung
          zu Ihnen grundsätzlich möglich. Wir speichern Ihre Daten jedoch nur vorübergehend und insbesondere nicht zusammen
          mit anderen personenbezogenen Daten.
        </p>

        <p>
          Für die Bereitstellung unserer Website ist die Verarbeitung der oben genannten Daten erforderlich. Unser
          Auftragsverarbeiter speichert die Daten auch zum Zwecke der Sicherheit unserer informationstechnischen Systeme für
          eine Dauer von sieben Tagen. In diesen Zwecken ist auch unser berechtigtes Interesse begründet, die Daten auf der
          Rechtsgrundlage Art. 6 (1) lit. f DSGVO zu verarbeiten. Die Logfiles, in denen auch Ihre IP-Adresse enthalten ist,
          werden nach sieben Tagen dauerhaft anonymisiert.
        </p>


        <h4>3. Registrierung in unserer Web-App</h4>

        <p>
          Sie können sich in unserer Web-App registrieren (Mit QR-Code anmelden oder Mit Werkstatt-Code anmelden). Hierfür
          erfassen wir folgende Informationen von Ihnen:
        </p>

        <ul>
          <li>E-Mail-Adresse</li>
          <li>Passwort</li>
          <li>Dein Werkstatt-Code (freiwillig)</li>
        </ul>

        <p>
          Nach Ihrer Registrierung erhalten Sie von uns eine E-Mail, mit der Sie Ihre Anmeldung durch Klicken des Links
          bestätigen müssen.
        </p>

        <p>
          Sodann können Sie im Profil weitere personenbezogenen Daten ergänzen:
        </p>

        <ul>
          <li>Vorname</li>
          <li>Nachname</li>
          <li>PLZ</li>
          <li>Stadt</li>
          <li>Adresse</li>
          <li>Mobilnummer</li>
          <li>Ihre Fahrzeuge (Hersteller, Modell, Erstzulassung, FIN, Fotos)</li>
        </ul>

        <p>
          Die <b>Rechtsgrundlage</b> für die Speicherung Ihrer Daten ist Art. 6 (1) lit. b DSGVO.
        </p>

        <p>
          Wir speichern Ihre Daten, die Sie uns im Rahmen Ihrer Anmeldung/Registrierung zur Verfügung gestellt haben,
          solange Sie Ihr Nutzerkonto bei uns nicht. Wenn Sie Änderungen an Ihren Angaben vornehmen, werden die alten
          Angaben gelöscht und nur die aktualisierten Daten. Sie können jederzeit Ihr Nutzerkonto bei uns löschen
          (über den hierfür vorgesehenen Button) oder abändern.
        </p>

        <p>
          Darüberhinausgehend speichern wir Ihre Daten nur, um unseren vertraglichen oder gesetzlichen Verpflichtungen
          (z.B. steuerlichen Pflichten) nachzukommen (Art. 6 (1) lit. c DSGVO). In diesem Fall sperren wir Ihre Daten insoweit,
          als dass sie nur noch für die notwendigen Zwecke verarbeitet werden. Zusätzlich zu diesen Daten speichern wir den
          Zeitpunkt (Datum und Uhrzeit) der Übermittlung Ihrer Daten an uns, sowie Ihre IP-Adresse. Die Verarbeitung dieser
          Daten entspricht unserem berechtigten Interesse (Art. 6 (1) lit. f DSGVO), um die Sicherheit unserer Systeme zu
          gewährleisten und Missbrauch entgegenzuwirken. Diese zusätzlichen Daten werden gelöscht, sobald sie nicht mehr
          benötigt werden, spätestens wenn der Vertrag mit Ihnen abgewickelt ist.
        </p>


        <h4>4. Anfrage</h4>

        <p>
          Sie können in unserer Web-App eine Anfrage erstellen. Hierfür erfassen wir folgende Informationen von Ihnen:
        </p> 

        <ul> 
          <li>Fotos (aufnehmen oder von der Bildergalerie hochladen) – Bitte laden Sie nur Fotos von Ihrem Kfz hoch, ohne weitere Personen im Bild</li>
          <li>Fahrzeugtyp/FIN</li>
          <li>Fahrzeugschein einscannen/FIN einscannen</li>
          <li>Fahrgestellnummer</li>
          <li>Angaben zur Stelle, an der das Fahrzeug beschädigt ist</li>
          <li>Kurzbeschreibung (Überschrift für die Anfrage)</li>
          <li>Angabe, wo das Fahrzeug steht (PLZ)</li>
          <li>Nachricht an uns (freiwillig)</li>
          <li>Sprachnachricht</li>
        </ul>

        <p>
          Sodann fragen wir einige Informationen ab (optionale Angaben):
        </p>

        <ul>
          <li>Art des Unfalls (Selbstverschuldet/Versicherungsfall/Gutachter)</li>
          <li>Vermittlung von weiteren Dienstleistern (z.B. Rechtsanwälten, Gutachtern</li>
          <li>Angaben zu Mietwagenbedarf</li>
        </ul>

        <p>
          Bereits angelegte Fahrzeuge können Sie in Ihrem Profil auch auswählen, ohne die Daten erneut einzugeben.
        </p>

        <p>
          Zuletzt fragen wir Sie nach Ihrer E-Mail-Adresse und einem Passwort. Andere Angaben, wie z.B.
        </p>

        <ul>
          <li>Vorname</li>
          <li>Nachname</li>
          <li>PLZ</li>
          <li>Ort</li>
          <li>Handy-Nummer</li>
        </ul>

        <p>
          Diese Angaben können Sie freiwillig ergänzen und bearbeiten. Sie können außerdem Werkstätte unter
          „Meine Werkstatt“ finden und speichern.
        </p>
        
        <p>
          Sie dürfen entscheiden, ob Sie Ihre Anfrage an:
        </p>
        
        <ul>
          <li>Ihre Wunschwerkstatt,</li>
          <li>eine Original-Herstellerwerkstatt oder</li>
          <li>an alle Werkstätte in der Umgebung verschicken.</li>
        </ul>

        <p>
          Es wird nur Ihre E-Mail-Adresse mit Ihrer Anfrage verschickt, Ihr Name und Ihre sonstigen Kontaktdaten werden erst
          weitergegeben, wenn Sie das Angebot Ihrer Werkstatt annehmen und eine Reparatur beauftragen.
        </p>

        <p>
          Wenn Sie <b>Ihre Anfrage</b> abschicken, speichern wir zusammen mit Ihren angegebenen Daten Ihre <b>IP-Adresse
          und Datum und Zeitpunkt</b> Ihrer Registrierung (Zeitstempel für die initiale Registrierung). Ein weiterer
          Zeitstempel erfolgt für das jeweils letzte Update der Datensätze (Datum und Uhrzeit).
        </p>

        <p>
          Ihre Daten werden zum Zwecke der Verwaltung Ihres Nutzerkontos und Bereitstellung der damit verbundenen Funktionen,
          wie z.B. die Bearbeitung Ihrer Kundendaten, und die Anzeige Ihrer Anfragen verwendet. Die <b>Rechtsgrundlage</b>
          für die Speicherung Ihrer Daten ist Art. 6 (1) lit. b DSGVO. Wir speichern Ihre Daten, die Sie uns im Rahmen Ihrer
          Anmeldung/Registrierung zur Verfügung gestellt haben, solange Sie Ihr Nutzerkonto bei uns nicht löschen. Wenn Sie
          Änderungen an Ihren Angaben vornehmen, werden die alten Angaben gelöscht und nur die aktualisierten Daten gespeichert. 
          Sie können jederzeit Ihr Nutzerkonto bei uns löschen (über den hierfür vorgesehenen Button) oder abändern.
        </p>

        <p>
          Darüberhinausgehend speichern wir Ihre Daten nur, um unseren vertraglichen oder gesetzlichen Verpflichtungen (z.B.
          steuerlichen Pflichten) nachzukommen (Art. 6 (1) lit. c DSGVO). In diesem Fall sperren wir Ihre Daten insoweit, als
          dass sie nur noch für die notwendigen Zwecke verarbeitet werden. Zusätzlich zu diesen Daten speichern wir den Zeitpunkt
          (Datum und Uhrzeit) der Übermittlung Ihrer Daten an uns, sowie Ihre IP-Adresse. Die Verarbeitung dieser Daten entspricht
          unserem berechtigten Interesse (Art. 6 (1) lit. f DSGVO), um die Sicherheit unserer Systeme zu gewährleisten und
          Missbrauch entgegenzuwirken. Diese zusätzlichen Daten werden gelöscht, sobald sie nicht mehr benötigt werden,
          spätestens wenn der Vertrag mit Ihnen abgewickelt ist.
        </p>


        <h4>5. Berechtigungen (wenn die Web-App mit dem mobilen Endgerät verwendet wird)</h4>

        <p>
          Für einige Funktionen der Web-App sind bestimmte Zugriffe auf Funktionen Ihres Smartphones durch die Web-App
          erforderlich. Im Folgenden finden Sie die Übersicht der jeweiligen Berechtigungen:
        </p>
        
        <p>
          Ihr Android-Gerät fordert folgende Berechtigungen an:
        </p>
        
        <ul>
          <li><b>Kamera/Fotos:</b> Die Berechtigung wird abgefragt, wenn Sie ein Foto hochladen oder über Ihre Kamera
            aufnehmen möchten</li>
          <li><b>Standortdaten:</b> Wir rufen mit Ihrer Zustimmung Ihren Standort ab.</li>
          <li><b>Medien/Dateien/Galerie:</b> Wir greifen mit Ihrer Zustimmung auf Dateien Ihres Smartphones zu (z.B. um ein Foto hochzuladen oder Dateianhänge zu ermöglichen).</li>
          <li><b>Mikrofon:</b> Die Web-App erhält für Tonaufnahmen mit Ihrer Zustimmung Zugriff auf das Mikrofon.</li>
        </ul>

        <p>
          Ihr iOS-Gerät fordert folgende Berechtigungen an:
        </p>

        <ul>
          <li><b>Fotos:</b> Die Berechtigung wird abgefragt, wenn Sie ein Profilbild hochladen möchten.</li>
          <li><b>Kamera:</b> Die Berechtigung wird abgefragt, wenn Sie ein Profilbild über Ihre Kamera aufnehmen möchten.</li>
          <li><b>Standortdaten:</b> Wir rufen mit Ihrer Zustimmung Ihren Standort ab.</li>
          <li><b>Medien/Dateien/Galerie:</b> Wir greifen mit Ihrer Zustimmung auf Dateien Ihres Smartphones zu (z.B. um ein Profilbild hochzuladen oder Dateianhänge zu ermöglichen).</li>
          <li><b>Mikrofon:</b> Die Web-App erhält für Tonaufnahmen mit Ihrer Zustimmung Zugriff auf das Mikrofon.</li>
        </ul>

        <p>
          Rechtsgrundlagen für die Datenverarbeitung sind Art. 6 (1) lit. a, b und f DSGVO. Sie können auf Ihrem Smartphone
          selbst steuern, ob Sie uns die Berechtigung erteilen oder wieder entziehen möchten. Durch die Berechtigungen werden
          keine (zusätzlichen) personenbezogenen Daten dauerhaft gespeichert.
        </p>


        <h4>6. Kontaktaufnahme per E-Mail oder Telefon</h4>

        <p>
          Sie haben die Möglichkeit, uns per <b>E-Mail oder Telefon</b> zu kontaktieren. Ihre auf diesem Wege übermittelten 
          personenbezogenen Daten werden bei uns gespeichert. Die Daten werden ausschließlich verarbeitet, um Ihre
          Kontaktaufnahme zu bearbeiten. Die <b>Rechtsgrundlage</b> für die Verarbeitung Ihrer personenbezogenen Daten ist
          Art. 6 (1) lit. f DSGVO. Die Daten werden gespeichert, bis sie zur Erreichung des Zwecks der Konversation mit Ihnen
          nicht mehr erforderlich sind und das Anliegen Ihrer Kontaktaufnahme umfassend geklärt ist.
        </p>

        <p>
          Wenn Ihre Kontaktaufnahme darauf abzielt, einen Vertrag mit uns abzuschließen, ist die zusätzliche
          <b>Rechtsgrundlage</b> für die Verarbeitung Ihrer personenbezogenen Daten Art. 6 (1) lit. b DSGVO. Diese Daten werden
          so lange gespeichert, wie sie zur Durchführung des Vertrags erforderlich sind.Darüberhinausgehend speichern wir Ihre
          Daten nur, um vertraglichen oder gesetzlichen Verpflichtungen (z.B. steuerlichen Pflichten) nachzukommen
          (Art. 6 (1) lit. c DSGVO).
        </p>

        <p>
          Zusätzlich zu den Daten, die Sie uns freiwillig mitteilen, erhalten wir ggf. den Zeitpunkt (Datum und Uhrzeit) der
          Übermittlung Ihrer Daten an uns, die der Nachricht zugehörige Message-ID sowie Kennungen jedes Servers, der an der
          Übermittlung der E-Mail von Sender zum Empfänger durch Weiterleitung beteiligt war. Die Verarbeitung dieser Daten
          entspricht unserem berechtigten Interesse (Art. 6 (1) lit. f DSGVO), um die Sicherheit unserer Systeme zu gewährleisten
          und Missbrauch entgegenzuwirken. Diese Daten, die wir während Ihrer Kontaktaufnahme zusätzlich erheben, werden gelöscht,
          sobald sie nicht mehr benötigt werden, spätestens wenn das Anliegen Ihrer Kontaktaufnahme umfassend geklärt ist.
        </p>

        <p>
          Sie können uns jederzeit mitteilen (siehe oben Ziffer 1), dass wir die im Rahmen der Konversation mitgeteilten Daten
          löschen sollen. In diesem Fall werden, soweit zulässig, alle personenbezogenen Daten der Konversation gelöscht und eine
          Fortführung der Konversation ist nicht möglich.
        </p>


        <h4>7. Google Maps</h4>

        <p>
          Auf unserer Webseite ist der Kartendienst Google Maps (Google LLC, 1600 Amphitheatre Parkway, Mountain View,
          CA 94043, USA bzw. in Europa: Google Ireland Limited, Gordon House, Barrow Street Dublin 4, Irland) eingebunden
          (im Folgenden: „<b>Google Maps</b>“ oder “<b>Google</b>”).  Wir nutzen den Dienst, damit Sie uns schnell verorten und
          ggf. Ihre Anreise zu uns planen können.
        </p>

        <p>
          Wenn Sie unsere „Kontakt“-Seite aufrufen und über die Cookie-Auswahl Google Maps-Cookies zugelassen haben, kann es sein,
          dass Ihre IP-Adresse auf Google-Server in die USA übertragen wird, da automatisch eine Verbindung zu Servern von Google
          aufgebaut wird. Neben der IP-Adresse kann es sein, dass Google Maps Datum und Uhrzeit des Aufrufs unserer Seite,
          Internetadresse oder URL unserer Seite erhält; ferner kann es sein, dass Google Geräte-IDs, Cookie-Informationen und
          Standortinformationen zu verschiedenen Zwecken verarbeitet. Falls Sie bei einem Google-Dienst angemeldet sind, kann es
          sein, dass Google diese Daten Ihrem Konto zuordnet (wir haben darauf keinen Einfluss).
        </p>

        <p>
          Informationen dazu, wie Google Standortinformationen verwendet, finden Sie unter:
          <a href="https://policies.google.com/technologies/location-data#why-use" target="_blank" ref="nofollow noopener noreferrer">https://policies.google.com/technologies/location-data#why-use</a>.
          Allgemeine Informationen zum Datenschutz bei Google finden Sie unter: <a href="https://policies.google.com/privacy?hl=de" target="_blank" ref="nofollow noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.
        </p>

        <p>
          Inkognito-Modus: Wenn Sie unsere Seite mit ihrem iPad, iPhone oder Android-Gerät aufrufen, auf Ihrem Gerät die App
          Google Maps installiert haben und dort den Inkognitus-Modus ausgewählt haben, werden einige Dienste von Google Maps
          deaktiviert. Dies kann auch die Nutzung von Google Maps auf unserer Seite betreffen. Mehr Informationen zum
          Inkognito-Modus finden Sie unter:
        </p> 

        <p>
          <a href="https://support.google.com/maps/answer/9430563?co=GENIE.Platform%3DiOS&amp;hl=de&amp;oco=0" target="_blank" ref="nofollow noopener noreferrer">https://support.google.com/maps/answer/9430563?co=GENIE.Platform%3DiOS&amp;hl=de&amp;oco=0</a> (iPhone/iPad) und <a href="https://support.google.com/maps/answer/9430563?co=GENIE.Platform%3DAndroid&amp;hl=de" target="_blank" ref="nofollow noopener noreferrer">https://support.google.com/maps/answer/9430563?co=GENIE.Platform%3DAndroid&amp;hl=de</a> (Android).
        </p> 

        <p>
          Rechtsgrundlage für die Einbindung von Google Maps und ggf. Übermittlung Ihrer Daten an Google ist
          Art. 6 Abs. 1 S. 1 lit. f DSGVO. Zwischen uns und Google Maps besteht eine Vereinbarung über die gemeinsame
          Verantwortlichkeit gem. Art. 26 DSGVO. Diese können Sie abrufen unter:
          <a href="https://privacy.google.com/intl/de/businesses/mapscontrollerterms/" target="_blank" ref="nofollow noopener noreferrer">https://privacy.google.com/intl/de/businesses/mapscontrollerterms/</a>.
        </p> 

        <p>
          Die Datenschutzerklärung von Google finden Sie unter folgendem Link:
          <a href="https://policies.google.com/privacy?hl=de" target="_blank" ref="nofollow noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.
        </p>

        <p>
          Google ist immer noch unter dem Privacy Shield zertifiziert, aber für Datenübermittlungen stützt sich Google
          inzwischen auf die Standardvertragsklauseln. Hierzu:
          <a href="https://support.google.com/analytics/answer/6004245?hl=de&amp;ref_topic=2919631" target="_blank" ref="nofollow noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=de&amp;ref_topic=2919631</a>
          und zum Privacy Shield:
          <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI" target="_blank" ref="nofollow noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI</a>
          sowie
          <a href="https://policies.google.com/privacy/frameworks?hl=de&amp;gl=de" target="_blank" ref="nofollow noopener noreferrer">https://policies.google.com/privacy/frameworks?hl=de&amp;gl=de</a>.
        </p>


        <h4>8. Inanspruchnahme von Dienstleistern </h4>

        <p>
          Wir möchten darauf hinweisen, dass wir ggf. bei der Verarbeitung Ihrer personenbezogener Daten Dienstleister einsetzen,
          mit denen wir Auftragsverarbeitungsverträge abgeschlossen haben (z.B. für das Website-Hosting). Sofern
          Auftragsverarbeiter in einem Drittstaat (nicht innerhalb der EU) die Datenverarbeitung vornehmen, stellen wir sicher,
          dass das durch die DSGVO gewährleistete Schutzniveau Ihrer Daten nicht untergraben wird (Art. 44 ff. DSGVO).
          Rechtsgrundlage für den Einsatz von Dienstleistern ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.  Die Beauftragung von
          Dienstleistern (Spezialisten oder sonstige Leistungserbringer in Bereichen, die wir nicht selbst bedienen können)
          entspricht unserem berechtigten Interesse.  Falls Sie eine Kopie der geeigneten oder angemessenen Garantien erhalten
          möchten, teilen Sie uns dies gerne (s.o. Ziff. 1) mit.
        </p>


        <h4>9. Ihre Rechte</h4>

        <p>
          Wenn wir Ihre Daten verarbeiten, sind Sie „Betroffener“ im Sinne der DSGVO. Ihnen stehen folgende Rechte zu:
          <b>Auskunftsrecht</b>, Recht auf <b>Berichtigung</b>, Recht auf <b>Einschränkung</b> der Verarbeitung, Recht auf
          <b>Löschung</b>, Recht auf <b>Unterrichtung</b> sowie Recht auf <b>Datenübertragbarkeit</b>. Darüber hinaus haben Sie
          ein <b>Widerspruchsrecht</b> und ein <b>Widerrufsrecht</b> und das Recht, sich bei der <b>Aufsichtsbehörde</b> zu
          beschweren.
        </p>

        <p>
          Im Folgenden finden Sie einige Details zu den einzelnen Rechten:
        </p>


        <h5>a. Auskunftsrecht</h5>

        <p>
          Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob wir Ihre personenbezogenen Daten verarbeiten.
          Sofern wir Ihre personenbezogenen Daten verarbeiten, haben Sie das Recht, insbesondere Auskunft über Verarbeitungszwecke,
          Kategorien personenbezogener Daten, Empfänger oder Empfängerkategorien, ggf. Speicherdauer zu erhalten.
        </p>


        <h5>b. Berichtigungsrecht</h5>

        <p>
          Sie haben das Recht auf Berichtigung und/oder Vervollständigung der Daten, die wir über Sie gespeichert haben,
          wenn diese Daten unrichtig oder unvollständig sind. Wir nehmen die Berichtigung oder Vervollständigung unverzüglich
          vor.
        </p>

        <h5>c. Recht zur Einschränkung der Verarbeitung</h5>

        <p>
          Unter bestimmten Voraussetzungen haben Sie das Recht, von uns die Einschränkung der Verarbeitung Ihrer personenbezogenen
          Daten zu verlangen. Ein Beispiel hierfür ist, dass Sie die Richtigkeit Ihrer personenbezogenen Daten bestreiten und
          wir für eine bestimmte Dauer die Richtigkeit überprüfen müssen. Für die Dauer der Prüfung werden Ihre Daten nur
          eingeschränkt verarbeitet. Ein anderes Beispiel für die Einschränkung ist es, dass wir zwar Ihre Daten nicht mehr
          brauchen, Sie sie aber für einen Rechtsstreit benötigen.
        </p>

        <h5>d. Löschungsrecht</h5>

        <p>
          Sie haben in bestimmten Situationen das Recht, von uns zu verlangen, dass wir Ihre personenbezogenen Daten unverzüglich
          löschen.  Dies ist beispielsweise der Fall, wenn wir Ihre personenbezogenen Daten für die Zwecke, für die wir die Daten
          erhoben haben, nicht länger benötigen oder wenn wir Ihre Daten unrechtmäßig verarbeitet haben. Ein weiteres Beispiel
          wäre es, dass wir Ihre Daten aufgrund Ihrer Einwilligung verarbeiten, Sie Ihre Einwilligung widerrufen und wir die Daten
          nicht aufgrund einer anderweitigen Rechtsgrundlage verarbeiten. Ihr Löschungsrecht besteht aber nicht immer. Es kann
          beispielsweise sein, dass wir Ihre personenbezogenen Daten verarbeiten, um einer rechtlichen Verpflichtung nachzukommen
          oder weil wir sie für einen Rechtsstreit benötigen.
        </p>

        <h5>e. Unterrichtungsrecht</h5>

        <p>
          Wenn Sie Ihr Berichtigungs-, Löschungs- oder Einschränkungsrecht gegenüber uns geltend gemacht haben, sind wir dazu
          verpflichtet, allen Empfängern, denen wir Ihre personenbezogenen Daten offengelegt haben, die Berichtigung, Löschung
          oder Einschränkung der Verarbeitung Ihrer Daten mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit
          einem unverhältnismäßigen Aufwand verbunden.
        </p>

        <h5>f. Recht auf Datenübertragbarkeit</h5>

        <p>
          Sie haben unter bestimmten Bedingungen das Recht, die personenbezogenen Daten, die Sie uns bereitgestellt haben, in
          einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und das Recht, dass diese Daten einem anderen
          Verantwortlichen übermittelt werden. Das ist dann der Fall, wenn wir die Daten entweder aufgrund Ihrer Einwilligung
          verarbeiten oder aufgrund eines Vertrags mit Ihnen und dass wir die Daten mithilfe automatisierter Verfahren verarbeiten.
          Sie haben dabei das Recht zu erwirken, dass wir Ihre personenbezogenen Daten direkt an einen anderen Verantwortlichen
          übermitteln, soweit dies technisch machbar ist und Freiheiten und Rechte anderer Personen hierdurch nicht beeinträchtigt
          werden.
        </p>

        <h5>g. Widerspruchsrecht</h5>

        <p>
          Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung
          Ihrer personenbezogenen Daten, die auf von Art. 6 (1) lit. e oder lit. f DSGVO beruht, Widerspruch einzulegen. Dies
          gilt auch für ein auf diese Bestimmungen genanntes Profiling. Wir verarbeiten nach einem Widerspruch Ihre
          personenbezogenen nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen,
          die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder
          Verteidigung von Rechtsansprüchen.
        </p>

        <p>
          Wenn wir Ihre personenbezogenen Daten verarbeiten, um <b>Direktwerbung</b> zu betreiben, haben Sie das Recht,
          jederzeit Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen.
          Dies gilt auch für Profiling, soweit es mit der Direktwerbung in Verbindung steht. Wenn Sie der Verarbeitung Ihrer
          personenbezogenen Daten zu Zwecken der Direktwerbung widersprechen, werden wir diese nicht mehr für diese Zwecke
          verarbeiten.
        </p>
        
        <h5>h. Widerrufsrecht</h5>

        <p>
          Sie haben gem. Art. 7 (3) DSGVO das Recht, Ihre Einwilligung jederzeit zu widerrufen.  Durch den Widerruf der
          Einwilligung wird die Rechtmäßigkeit der Verarbeitung nicht rückwirkend unwirksam.
        </p>

        <h5>i. Beschwerderecht bei einer Aufsichtsbehörde</h5>

        <p>
          Sie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde, unbeschadet eines anderweitigen verwaltungsrechtlichen
          oder gerichtlichen Rechtsbehelfs. Sie können insbesondere im Mitgliedstaat Ihres Wohnortes, Ihres Arbeitsplatzes oder
          des Orts des mutmaßlichen Verstoßes Ihr Beschwerderecht geltend machen, wenn Sie der Ansicht sind, dass die Verarbeitung
          Ihrer personenbezogenen Daten gegen die DSGVO verstößt.
        </p>

        <p>
          Eine Übersicht über die jeweiligen Landesdatenschutzbeauftragten der Länder sowie deren Kontaktdaten finden Sie unter
          dem folgenden Link:
          <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" ref="nofollow noopener noreferrer">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
        </p>


        <h4>10. Aktualität und Änderung dieser Datenschutzbestimmungen</h4>

        <p>
          Stand: Juni 2021
        </p>


        <h3>Cookie-Information</h3>

        <p>
          Bitte beachten Sie folgenden Hinweis: Sie können selbst dafür sorgen, dass überhaupt keine Cookies auf Ihrem Rechner
          gespeichert werden, oder dass die Speicherung nur von bestimmten Cookies zugelassen wird. Dies können Sie in Ihren
          Internetbrowser-Einstellungen auswählen. Sie können dort auch die gespeicherten Cookies einsehen und löschen.
        </p>

        <p>
          Wenn Sie alle Cookies blockieren, kann es sein, dass Ihnen nicht alle Funktionen unserer Website zur Verfügung stehen.
        </p>

        <p>
          Wir verwenden auf unserer Website Cookies. Cookies sind Textdateien, die im Rahmen Ihres Besuchs unserer Website von
          unserem Webserver an Ihren Browser gesandt und von diesem auf Ihrem Rechner für einen späteren Abruf vorgehalten werden.
          Durch ein Cookie kann Ihr Internetbrowser also identifiziert werden, wenn Sie die Website erneut aufrufen. Es gibt
          Session-Cookies, das sind solche, die sich bei Schließen des Browsers wieder löschen und es gibt persistente Cookies, die auf der Festplatte gespeichert werden, bis ihr voreingestelltes Ablaufdatum erreicht ist oder bis sie aktiv durch Sie entfernt
          werden.
        </p>


        <h4>1. Eigene Cookies (First-Party)</h4>

        <p>
          Wir nutzen eigene Cookies, um die <b>unktionalität unserer Website zu gewährleisten</b>.  Einige Elemente unserer
          Internetseite setzen notwendig voraus, dass Ihr Internetbrowser nach einem Seitenwechsel wiedererkannt wird. Wir
          verwenden wenige technische Cookies, wie z.B. Login-Cookies, in der Web-App.
        </p>

        <p>
          Für die Verarbeitung personenbezogener Daten in Cookies, die wir auf unserer Website setzen, um die Funktionalität
          unserer Website und unseres Angebots zu gewährleisten, ist die Rechtsgrundlage Art. 6 (1) lit. f DSGVO, sofern
          personenbezogene Daten in den Cookies enthalten sind.
        </p> 

        <h4>Widerspruchs- und Beseitigungsmöglichkeit</h4>

        <p>
          Wie einleitend in diesem Abschnitt mitgeteilt, können Sie durch Änderung der Einstellungen in Ihrem Internetbrowser
          die Übertragung von Cookies ermöglichen oder beschränken. Cookies, die von Ihrem Internetbrowser bereits gespeichert
          wurden, können Sie dort jederzeit löschen. Wenn Cookies für unsere Website beschränkt oder deaktiviert werden, kann es
          sein, dass nicht alle Funktionalitäten genutzt werden können.
        </p>


        <h4>2. Fremde Cookies (Third-Party-Cookies/Drittanbieter-Cookies)</h4>

        <p>
          Wir verwenden auf unserer Website Cookies von sog. „Drittanbietern“. Das bedeutet, dass im Rahmen Ihres Besuchs
          unserer Website Daten von in Ihrem Web-Browser an den Webserver des Dritten übertragen werden und dort gespeichert
          werden. Der Verarbeitungsvorgang, der auf unserer Website ausgelöst wird, ist daher eine Übermittlung. Wir erhalten
          Ihre personenbezogenen Daten nicht, sondern können nur statistische Informationen sehen.
        </p>

        <h5>a. Google Analytics</h5>

        <p>
          Auf unserer Website ist der Analysedienst Google Analytics der Google LLC, 1600 Amphitheatre Parkway Mountain View,
          CA 94043, USA (im Folgenden: „<b>Google Analytics</b>“) implementiert.
        </p>

        <p>
          Google Analytics setzt Cookies, die folgende Informationen speichern:
        </p>

        <ul>
          <li>Typ des verwendeten Internet-Browsers</li>
          <li>Version des Internetbrowsers</li>
          <li>das von Ihnen verwendete Betriebssystem</li>
          <li>Referrer (zuvor besuchte Website)</li>
          <li>hre gekürzte IP-Adresse</li>
          <li>Uhrzeit der Server-Anfrage</li>
        </ul>

        <p>
          Name, Zweck und Speicherdauer des Cookie:
        </p>

        <p>
          <em>_ga</em>; dient zur Unterscheidung von Benutzern, um statistische Daten über die Nutzung der Website zu generieren; Dauer: 2 Jahre
        </p>

        <p>
          <em>_gat</em>; beschränkt die Anforderungsrate für Google Analytics; Dauer: bis zum Beenden der Browsersitzung
        </p>

        <p>
          <em>_gid</em>; dient zur Unterscheidung von Benutzern, um statistische Daten über die Nutzung der Website zu
          generieren; Dauer: 24 Stunden
        </p>

        <p>
          Wir nutzen eine Funktion von Google Analytics, mit der Ihre IP-Adresse vor der Speicherung oder Verarbeitung
          anonymisiert wird.Ihre IP-Adresse wird in der Regel noch innerhalb der Europäischen Union/des EWR gekürzt und erst
          danach z.B. an Google-Server in den USA übertragen. Die Verarbeitung Ihrer Informationen erfolgt pseudonym und wir
          werden keine Zusammenführung mit anderen personenbezogenen Daten von Ihnen vornehmen.
        </p>

        <p>
          Uns werden über das Tool lediglich Statistiken angezeigt, anhand derer wir unsere Website und Angebote optimieren
          können.
        </p>

        <p>
          Bevor wir die Cookies setzen, holen wir hierfür Ihre Einwilligung (Art. 6 (1) lit. a DSGVO) ein.
        </p>

        <p>
          Sie können die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten
          (inkl. Ihrer (anonymisierten) IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
          indem Sie das unter dem folgenden Link
          (<a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" ref="nofollow noopener noreferrer">http://tools.google.com/dlpage/gaoptout?hl=de</a>)
          verfügbare Browser-Plugin herunterladen und installieren.
        </p>

        <!-- TODO: Insert link to disable Google cookies in the parapraph below. -->
        <p>
          Sie können die Speicherung von Google-Cookies entweder direkt in Ihren Browser-Einstellungen selbst unterbinden, oder
          die Verarbeitung Ihrer Daten unterbinden, indem Sie folgenden Link anklicken und ein „Opt-Out“ herbeiführen:
          <span v-show="showOptOutLinkForGoogle">
            <a href="#" @click="optOut">Opt-Out für Google-Cookies</a>.
          </span>
          <span v-show="!showOptOutLinkForGoogle">
            Sie haben bereits das Opt-Out für Google-Cookies gewählt.
            <a href="#" @click="optIn">Google-Cookies wieder aktivieren</a>.
          </span>
          <br>
          Dabei wird ein „Opt-Out Cookie“ gesetzt, der eine Erfassung Ihrer Nutzerdaten auf dieser Webseite zukünftig verhindert,
          sofern nicht das Opt-Out-Cookie gelöscht wird.
        </p>

        <p>
          Die Datenschutzerklärung von Google finden Sie unter folgendem Link:
          <a href="https://policies.google.com/privacy?hl=de" target="_blank" ref="nofollow noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.
        </p>

        <p>
          Google ist immer noch unter dem Privacy Shield zertifiziert, aber für Datenübermittlungen stützt sich Google inzwischen
          auf die Standardvertragsklauseln. Hierzu:
          <a href="https://support.google.com/analytics/answer/6004245?hl=de&amp;ref_topic=2919631" target="_blank" ref="nofollow noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=de&amp;ref_topic=2919631</a>
          und zum Privacy Shield:
          <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI" target="_blank" ref="nofollow noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI</a>
          sowie 
          <a href="https://policies.google.com/privacy/frameworks?hl=de&amp;gl=de" target="_blank" ref="nofollow noopener noreferrer">https://policies.google.com/privacy/frameworks?hl=de&amp;gl=de</a>.
        </p>


        <h4>Widerspruchs- und Beseitigungsmöglichkeit</h4>

        <p>
          Wie einleitend in diesem Abschnitt mitgeteilt, können Sie durch Änderung der Einstellungen in Ihrem Internetbrowser
          die Übertragung von Cookies ermöglichen oder beschränken. Cookies, die von Ihrem Internetbrowser bereits gespeichert
          wurden, können Sie dort jederzeit löschen. Wenn Cookies für unsere Website beschränkt oder deaktiviert werden, kann
          es sein, dass nicht alle Funktionalitäten genutzt werden können.
        </p>


        <h4>3. Stand und Aktualität dieser Cookie-Information</h4>

        <p>
          Stand: Juni 2021
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GoogleAnalytics from '../plugins/googleAnalytics'


export default {
  name: 'PopupPrivacy',
  data () {
    return {
      showOptOutLinkForGoogle: !GoogleAnalytics.userOptedOut()
    }
  },
  methods: {
    optIn () {
      GoogleAnalytics.optIn();
      this.showOptOutLinkForGoogle = !GoogleAnalytics.userOptedOut()
    },

    optOut () {
      GoogleAnalytics.optOut();
      this.showOptOutLinkForGoogle = !GoogleAnalytics.userOptedOut()

    },

    close () {
      this.$store.commit('SET_PRIVACY_VISIBLE', false)
    },
  },
  computed: {
    ...mapGetters({
      API: 'API',
      busy: 'busy',
      customer: 'customers/customer',
      requests: 'requests/requests',
      selectedRequest: 'requests/selectedRequest',
      recentMessage: 'requests/recentMessage',
      requestStatusName: 'requests/requestStatusName',
      selectedOffer: 'offers/selectedOffer',
      customerStation: 'stations/customerStation',
    }),
  }
}

</script>
