
import axios from 'axios'

const state = {
  activeOffers: [],
  selectedOffer: null,
}

// getters
const getters = {
  activeOffers: state => state.activeOffers,
  selectedOffer: state => state.selectedOffer,
  // eslint-disable-next-line no-unused-vars
  maxPrice: state => offer => {
    let max = '?'
    if (offer.calculation.useLumpSumPrice) {
      if (offer.calculationLevel === 'A') max = offer.calculation.lumpSumPrice * 1.1
      if (offer.calculationLevel === 'B') max = offer.calculation.lumpSumPrice * 1.3
      if (offer.calculationLevel === 'C') max = '?'
    } else {
      if (offer.calculationLevel === 'A') max = offer.calculation.totalPrice * 1.1
      if (offer.calculationLevel === 'B') max = offer.calculation.totalPrice * 1.3
      if (offer.calculationLevel === 'C') max = '?'
    }
    return Math.floor(max)
  }
}

// actions
const actions = {
  getAll(context) {
    return axios.get(context.rootGetters.API.baseUrl + 'offers/', context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_ACTIVE', response.data))
      .catch((error) => console.log(error))
  },
  getById(context, payload) {
    return axios.get(context.rootGetters.API.baseUrl + 'offers/' + payload, context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_SELECTED_OFFER', response.data))
      .catch((error) => console.log(error))
  },
  create(context, payload) {
    console.log('create offer')
    return axios.put(context.rootGetters.API.baseURL + 'offers/', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  update(context, payload) {
    console.log('update offer')
    return axios.patch(context.rootGetters.API.baseURL + 'offers/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
}

// mutations
const mutations = {
  SET_ACTIVE(state, data) {
    state.activeOffers = data
  },
  SET_SELECTED_OFFER(state, data) {
    state.selectedOffer = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
