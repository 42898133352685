<template>
  <div class="intro screen register">
    <div class="intro-inner">
      <img v-if="!whitelabel" src="@/assets/gfx/fiasco-logo.png" />
      <img
        v-if="whitelabel === 'rep2go'"
        src="@/assets/gfx/Rep2Go_logo_white.png"
      />
      <img
        v-if="whitelabel === 'mobilityconcept'"
        src="@/assets/gfx/mobility-concept-logo.png"
      />

      <h3>Registrieren</h3>
      <div class="intro-nav">
        <form @submit.prevent="onSubmit()" class="login-form">
          <!--label>E-Mail</label>
          <input type="text" v-model="loginForm.email" placeholder="E-Mail">
          <label>Wähle ein Passwort (mind. 6 Zeichen)</label>
          <input type="password" v-model="loginForm.password" placeholder="Passwort">
          <label>Passwort wiederholen</label>
          <input type="password" v-model="loginForm.passwordRepeat" placeholder="Passwort wiederholen"-->
          <label>Dein Werkstatt-Code</label>
          <input
            type="text"
            v-model="loginForm.inviteCode"
            placeholder="ABCDEF12"
          />
          <input type="submit" value="Anmelden" :disabled="!submitAvailable" />
        </form>
        <button @click="goIntro()">Abbrechen</button>
      </div>
      <p v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Intro",
  data() {
    return {
      error: null,
      loginForm: {
        email: "",
        password: "",
        passwordRepeat: "",
        inviteCode: "",
      },
    };
  },
  methods: {
    goIntro() {
      this.$router.push({ name: "intro" });
    },
    goMain() {
      this.$router.push({ name: "main" });
    },
    onSubmit() {
      this.$store.commit("SET_BUSY", true);
      this.error = null;
      this.$store
        .dispatch("stations/getByCode", this.loginForm.inviteCode)
        .then(() => {
          this.$store.commit("SET_BUSY", false);
          if (this.customerStation) {
            console.log(this.customerStation);
            this.$store.commit("customers/TEMP_LOGIN");
            this.$emit("login");
          } else {
            this.error = "Keine Werkstatt für diesen Code gefunden.";
          }
        });
    },
  },
  computed: {
    submitAvailable() {
      let available = false;
      // if (this.loginForm.password.length > 5 && this.loginForm.password === this.loginForm.passwordRepeat && this.loginForm.inviteCode.length > 3 && this.loginForm.email.includes('@') && this.loginForm.email.length > 4) available = true
      if (this.loginForm.inviteCode.length > 2) available = true;
      return available;
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      customerStation: "stations/customerStation",
      whitelabel: "whitelabel",
    }),
  },
  mounted() {
    console.log("REGI CODE MOUNTED");
  },
};
</script>
