<template>
  <div
    class="create-request-interview create-request-content"
    v-if="editRequest"
  >
    <div class="create-request-interview-line">
      <label>Schadensursache</label>
      <!--div class="create-request-interview-line-option"
        v-for="(item, index) in caseDescriptions" :key="index + 'ACED'"
        @click="selectedCaseDescription = item">
        {{item}}
      </div-->
      <select
        v-model="selectedCaseDescription"
        @change="onCaseDescriptionChange()"
      >
        <option v-for="(item, index) in caseDescriptions" :key="index + 'ACED'">
          {{ item }}
        </option>
      </select>
      <input
        type="text"
        placeholder="Sonstige Ursache"
        v-model="editRequest.title"
        v-if="selectedCaseDescription === 'Sonstiges'"
        @input="onInput()"
      />
    </div>
    <div class="create-request-interview-line">
      <label>Wo steht das Fahrzeug? (PLZ des aktuellen Standorts) </label>
      <div class="input-line-additional">
        <input
          type="number"
          placeholder="70123"
          v-model="editRequest.carLocation.zip"
          @input="onInput()"
        />
        <span class="critical-hint" v-if="zipScanned">Bitte prüfen</span>
      </div>
    </div>
    <div class="create-request-interview-line" v-if="!editRequest.car">
      <label>Kilometerstand</label>
      <input
        type="number"
        placeholder="km"
        v-model="editKilometers"
        @input="onInput()"
      />
    </div>
    <p class="create-request-interview-intro">Zusatzinfos (optional)</p>
    <div class="create-request-interview-message">
      <label
        ><span class="smart-repair">Smart Repair</span> auch erlauben</label
      >
      <input type="checkbox" v-model="editRequest.smartRepairAccepted" />
      <div
        class="create-request-interview-message-hint"
        v-if="whitelabel != 'mobilityconcept'"
      >
        Mehr erfahren im
        <a
          href="https://fiasco.de/10-praktische-tipps-um-als-autofahrer-geld-zu-sparen-2/"
          target="_blank"
          >FIASCO Blog</a
        >
      </div>
    </div>
    <div class="create-request-interview-message">
      <label>Meine Nachricht (mind. 4 Zeichen)</label>
      <textarea
        placeholder="Genaue Beschreibung, Zustand des Fahrzeugs..."
        v-model="editRequest.description"
        @input="onInput()"
      ></textarea>
    </div>
    <!--div class="create-request-interview-voice">
      <label for="audio-file-select" class="input-mic">
        <img class="input-mic-icon" src="@/assets/gfx/icon_voice.png">
      </label>
      <input id="audio-file-select" class="input-mic" type="file" accept="audio/*" @change="onAudioFileSelect"/>
      <div class="create-request-interview-voice-caption">Meine Sprachnachricht (1:00 min)</div>
      <button @click="onAudioFileSelect()">select</button>
      <button @click="startVoiceRecord()">start</button>
      <br>
      <button @click="stopVoiceRecord()">stop</button>
      <audio controls v-if="audioSource">
        <source :src="audioSource" type="audio/ogg">
      </audio>
    </div-->
    <p v-if="audioError">{{ audioError }}</p>
    <div class="create-request-interview-action">
      <label for="doc-camera-select" class="input-camera">
        <img class="input-camera-icon" src="@/assets/gfx/icon_camera.png" />
      </label>
      <input
        id="doc-camera-select"
        class="input-camera"
        type="file"
        capture="environment"
        accept="image/*"
        @change="onDocumentFileSelect"
      />
      <label for="file-select" class="input-camera">
        <img class="input-camera-icon" src="@/assets/gfx/icon_gallery.png" />
      </label>
      <input
        id="file-select"
        class="input-camera"
        type="file"
        accept="image/*"
        @change="onDocumentFileSelect"
      />
      Foto von Dokument hinzufügen
    </div>
    <div class="create-request-interview-docs">
      <img
        class="create-request-interview-doc-preview"
        :src="item"
        v-for="(item, index) in editDocumentPhotos"
        :key="index + 'doc'"
      />
      <!--img class="request-edit-image-btn-delete" src="@/assets/gfx/icon_trash_white.png" @click="deleteRequestImage = item"-->
    </div>

    <div class="create-request-btn-prev" @click="goBack()">
      <img src="@/assets/gfx/arrow-prev.png" />
    </div>

    <div
      class="create-request-btn-next"
      :disabled="!nextAvailable"
      @click="goNext()"
    >
      <img src="@/assets/gfx/arrow-next.png" />
    </div>

    <!--div class="create-request-interview-wizard">
    <h2 class="center">fiASCO-Fragen</h2>
      <div class="create-request-interview-wizard-item" v-if="questions[0].answer === 0">
        <div class="create-request-interview-wizard-item-question">
          {{questions[questionIndex].title}}
        </div>
        <div class="create-request-interview-wizard-item-answer">
          <div class="create-request-interview-wizard-item-answer-option" @click="questions[questionIndex].answer = 1">
            Ja
          </div>
          <div class="create-request-interview-wizard-item-answer-option" @click="questions[questionIndex].answer = 2">
            Nein
          </div>
        </div>
      </div>
      <div class="create-request-interview-wizard-message" v-if="questions[0].answer !== 0">
        Danke! Die fiASCO Fragen sind beantwortet.
      </div>
    </div-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CreateRequestCar",
  data() {
    return {
      questionIndex: 0,
      editRequest: null,
      editKilometers: 0,
      audioError: null,
      editDocumentPhotos: [],
      mediaRecorder: null,
      recordStopRequested: false,
      recordStopped: false,
      recorderState: null,
      audioSource: null,
      caseDescriptions: [
        "Parkschaden/Streifschaden",
        "Kollision mit einem weiteren Fahrzeug",
        "Kollision mit Tier (z.B. Reh)",
        "Kollision mit Gegenstand (z.B. Pfosten, Stein)",
        "Hagel/Unwetter",
        "Fremdkörper (z.B. Steinschlag)",
        "Sonstiges",
      ],
      selectedCaseDescription: null,
      questions: [
        {
          title: "Ist der Schaden selbstverschuldet?",
          answer: 0,
        },
        {
          title: "Ist der Schaden selbstverschuldet?",
          answer: 0,
        },
        {
          title: "Möchtest du einen Anwalt hinzuziehen?",
          answer: 0,
        },
      ],
      zipScanned: false,
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "CreateRequestDamage" });
    },
    goNext() {
      this.$router.push({ name: "CreateRequestReady" });
    },
    getEditData() {
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest));
      /*
      if (this.customer.zip) {
        this.editRequest.carLocation.zip = this.customer.zip;
        this.zipScanned = true;
      }
      */
      this.selectedCaseDescription = this.editRequest.title;
      if (this.createRequestCarData) {
        if (this.createRequestCarData.kilometers) {
          this.editKilometers = this.createRequestCarData.kilometers;
        }
      }
    },
    onInput() {
      if (this.createRequestCarData) {
        let editCar = JSON.parse(JSON.stringify(this.createRequestCarData));
        this.editKilometers = Number(this.editKilometers).toString();
        editCar.kilometers = this.editKilometers;
        this.$store.commit("cars/SET_CREATE_REQUEST_CAR", editCar);
        console.log(this.createRequestCarData);
      }
      this.$store.commit(
        "requests/SET_SELECTED_REQUEST",
        JSON.parse(JSON.stringify(this.editRequest))
      );
    },
    onAudioFileSelect(e) {
      this.audioError = null;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      var reader = new FileReader();
      reader.onload = (e) => {
        console.log(e.target.result);
        this.$store.commit("requests/SET_VOICE_MESSAGE", e.target.result);
      };
      if (files[0].size < 3000 * 1024) {
        reader.readAsDataURL(files[0]);
      } else {
        this.audioError = "Datei zu groß oder Aufnahme zu lang.";
      }
    },
    onDocumentFileSelect(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      var reader = new FileReader();
      reader.onload = (e) => {
        console.log(e.target.result);
        this.editDocumentPhotos.push(e.target.result);
        this.$store.commit(
          "requests/SET_DOCUMENT_PHOTOS",
          this.editDocumentPhotos
        );
      };
      reader.readAsDataURL(files[0]);
    },
    initVoiceRecord() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        console.log("getUserMedia supported.");
        let that = this;
        navigator.mediaDevices
          .getUserMedia(
            // constraints - only audio needed for this app
            {
              audio: true,
            }
          )
          // Success callback
          .then(function (stream) {
            that.mediaRecorder = new MediaRecorder(stream);
            let chunks = [];
            that.mediaRecorder.ondataavailable = function (e) {
              console.log(e);
              chunks.push(e.data);
            };
            that.mediaRecorder.onstop = function (e) {
              console.log(e);
              console.log("recorder stopped");
              const blob = new Blob(chunks, { type: "audio/webm;codecs=opus" });
              chunks = [];
              /*
                that.audioSource = window.URL.createObjectURL(blob)
                that.$store.commit('requests/SET_VOICE_MESSAGE', that.audioSource)
                // audio.src = audioURL
                */
              console.log(blob);
              var reader = new FileReader();
              reader.onload = (e) => {
                that.audioSource = window.URL.createObjectURL(blob);
                that.$store.commit(
                  "requests/SET_VOICE_MESSAGE",
                  e.target.result
                );
              };
              reader.readAsDataURL(blob);
            };
          })
          // Error callback
          .catch(function (err) {
            console.log("The following getUserMedia error occurred: " + err);
          });
      } else {
        console.log("getUserMedia not supported on your browser!");
      }
    },
    startVoiceRecord() {
      this.mediaRecorder.start(1000);
      this.recorderState = this.mediaRecorder.state;
      console.log("recorder started");
    },
    stopVoiceRecord() {
      this.mediaRecorder.stop();
      this.recorderState = this.mediaRecorder.state;
      console.log("recorder stopped");
    },
    onCaseDescriptionChange() {
      if (this.selectedCaseDescription !== "Sonstiges") {
        this.editRequest.title = this.selectedCaseDescription;
      } else {
        this.editRequest.title = "";
      }
      this.onInput();
    },
  },
  computed: {
    nextAvailable() {
      if (this.selectedRequest.title) {
        if (this.createRequestCar) {
          if (
            this.selectedRequest.title.length < 4 ||
            this.selectedRequest.carLocation.zip.length < 5 ||
            !this.createRequestCar.kilometers
          )
            return false;
        } else {
          if (
            this.selectedRequest.title.length < 4 ||
            this.selectedRequest.carLocation.zip.length < 5
          )
            return false;
        }
      } else {
        return false;
      }
      return true;
    },
    ...mapGetters({
      API: "API",
      whitelabel: "whitelabel",
      axiosConfig: "axiosConfig",
      busy: "busy",
      customer: "customers/customer",
      selectedRequest: "requests/selectedRequest",
      createRequestCarData: "cars/createRequestCar",
      cameraPhotos: "requests/cameraPhotos",
    }),
  },
  mounted() {
    this.getEditData();
    // this.initVoiceRecord()
  },
};
</script>
