<template>
  <div class="create-request-enter-car create-request-content">
    <h2 class="center">Mein Fahrzeug:</h2>
    <form class="form">
      <div class="form-line">
        <label>
          Fahrzeug-Identifikationsnummer:*
          <div
            class="form-line-hint"
            :class="{ check: editCar.vin.length === 17 }"
          >
            {{ editCar.vin.length }} / 17 Stellen
          </div>
        </label>
        <input
          type="text"
          placeholder="z.B.: JH4DA9460MS032070"
          v-model="editCar.vin"
        />
      </div>
      <!--div class="form-line">
        <label>Kilometerstand*</label>
        <input type="number" v-model="editCar.kilometers"/>
      </div-->
      <p class="small">* benötigt</p>
      <div class="form-line">
        <label>Kennzeichen</label>
        <input
          type="text"
          v-model="editCar.numberPlate"
          placeholder="AS-CO 123"
        />
      </div>
      <div class="form-line">
        <label>Erstzulassung (JJJJ-MM-TT)</label>
        <input type="text" v-model="editCar.firstRegistration" />
      </div>
      <div class="form-line">
        <label>Hersteller</label>
        <input type="text" v-model="editCar.manufacturer" />
      </div>
      <div class="form-line">
        <label>Modell</label>
        <input type="text" v-model="editCar.model" />
      </div>
      <br />
      <br />
      <br />

      <div class="create-request-btn-prev" @click="goBack()">
        <img src="@/assets/gfx/arrow-prev.png" />
      </div>

      <div
        class="create-request-btn-next"
        @click="confirm()"
        :disabled="editCar.vin.length !== 17"
      >
        <img src="@/assets/gfx/arrow-next.png" />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CreateRequestEnterCar",
  data() {
    return {
      code: "Code",
      editCar: {
        vin: "",
        model: "",
        manufacturer: "",
        firstRegistration: "",
        numberPlate: "",
        kilometers: 0,
        owner: {},
      },
      isDecoded: false,
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "CreateRequestCar" });
      // this.$emit("close");
    },
    confirm() {
      this.editCar.customer = this.customer._id;
      console.log("confirm editCar:");
      console.log(this.editCar);
      this.$store.commit("cars/SET_CREATE_REQUEST_CAR", this.editCar);
      console.log(this.createRequestCar);
      // this.$emit("commit");
      this.$router.push({ name: "CreateRequestDamageIntro" });
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      createRequestCar: "cars/createRequestCar",
    }),
  },
};
</script>
