<template>
  <div class="menu-modal modal">
    <div class="modal-clicker" @click="close()"></div>
    <div class="menu">
      <div class="menu-header">
        <div class="menu-header-chevron-back" @click="close()"></div>
        <img
          v-if="!whitelabel"
          class="menu-logo"
          src="@/assets/gfx/fiasco-logo.png"
          @click="showMenu()"
        />
        <img
          v-if="whitelabel === 'rep2go'"
          class="menu-logo"
          src="@/assets/gfx/Rep2Go_logo_white.png"
          @click="showMenu()"
        />
        <img
          v-if="whitelabel === 'mobilityconcept'"
          class="menu-logo"
          src="@/assets/gfx/mobility-concept-logo.png"
          @click="showMenu()"
        />
      </div>
      <!--img class="menu-tutorial-btn" src="@/assets/gfx/tutorial_btn.png"-->
      <div class="menu-nav">
        <!--div class="menu-nav-item highlight">Tutorial</div-->
        <div v-if="!whitelabel" class="menu-nav-item">
          <a href="https://fiasco.de/" target="_blank">Über FIASCO</a>
        </div>
        <div v-if="whitelabel === 'rep2go'" class="menu-nav-item">
          Über REP2GO
        </div>
        <div class="menu-nav-item">
          <a href="mailto:support@fiasco.de" target="_blank">Feedback</a>
        </div>
        <div class="menu-nav-item" @click="logout()">Abmelden</div>
      </div>
      <div class="menu-legals">
        <div class="menu-legals-item" @click="openCustomerInfo()">
          Kundeninformationen
        </div>
        <div class="menu-legals-item" @click="openImprint()">Impressum</div>
        <div class="menu-legals-item" @click="openPrivacy()">Datenschutz</div>
      </div>
      <div class="menu-version">v 2021-12-07 1704</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Menu",
  data() {
    return {
      menuVisible: false,
    };
  },
  methods: {
    goRequest() {
      this.$router.push({ name: "request-offers" });
    },
    createRequest() {
      this.$router.push({ name: "create-request" });
    },
    close() {
      this.$emit("close");
    },
    logout() {
      this.$emit("logout");
    },
    openImprint() {
      this.$store.commit("SET_IMPRINT_VISIBLE", true);
    },
    openPrivacy() {
      this.$store.commit("SET_PRIVACY_VISIBLE", true);
    },
    openCustomerInfo() {
      this.$store.commit("SET_CUSTOMER_INFO_VISIBLE", true);
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      whitelabel: "whitelabel",
      customer: "customers/customer",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      requestPending: "requests/requestPending",
      offers: "offers/offers",
      requestStatusName: "requests/requestStatusName",
      customerStation: "stations/customerStation",
    }),
  },
};
</script>
