<template>
  <div class="create-request-ready create-request-content">
    <div class="create-request-ready-content" v-if="requestSent">
      <img
        class="create-request-ready-fox"
        src="@/assets/gfx/fox-victory-transparent.png"
      />
      <h2>Geschafft!</h2>
      <div class="create-request-ready-check"></div>

      <!--p v-if="!requestSent && !requestSaved">Du kannst die Anfrage sofort senden
      oder speichern, um sie zu bearbeiten und 
      später zu senden.</p-->

      <p v-if="requestSent">Deine Anfrage wurde versendet!</p>
      <p v-if="requestSent">
        Wir haben dir eine E-Mail mit weiteren Informationen zum Ablauf
        gesendet.
      </p>

      <!--button class="transparent" v-if="!requestSent && !requestSaved" @click="requestSaved = true">An alle Werkstätten senden</button>
      <button class="transparent" v-if="!requestSent && !requestSaved" @click="requestSaved = true">An Hersteller-Werkstätten senden</button-->
      <button v-if="requestSent" @click="goMain()">Zurück zur Übersicht</button>
    </div>

    <div class="popup" v-if="contactPopupVisible && !emailAlreadyExists">
      <div v-if="!customer._id">
        <h2>Einen Moment!</h2>
        <p>
          Wir brauchen noch deine Kontaktdaten, damit die Anfrage gespeichert
          werden kann:
        </p>

        <div class="popup-form-line">
          <label>E-Mail</label>
          <input
            type="text"
            placeholder="ich@email.de"
            v-model="editCustomer.email"
          />
        </div>
        <div class="popup-form-line" v-if="!customer._id">
          <label>Passwort (mind. 6 Zeichen)</label>
          <input type="password" v-model="editCustomer.password" />
        </div>
        <div class="popup-form-line" v-if="!customer._id">
          <label>Passwort bestätigen</label>
          <input type="password" v-model="editCustomer.passwordCopy" />
        </div>
      </div>

      <div v-if="customer._id">
        <h2>Gleich fertig!</h2>
        <p>Ist die Anfrage für dich oder eine andere Person?</p>
      </div>

      <div class="popup-form-owner-tabs">
        <div
          class="popup-form-owner-tab"
          :class="{ active: !deviatingOwner }"
          @click="deviatingOwner = false"
        >
          <div class="popup-form-owner-tab-marker" />
          <span>Ich bin Fahrzeughalter</span>
        </div>
        <div
          class="popup-form-owner-tab"
          :class="{ active: deviatingOwner }"
          @click="deviatingOwner = true"
        >
          <div class="popup-form-owner-tab-marker" />
          <span>Abweichender Fahrzeughalter</span>
        </div>
      </div>

      <div class="popup-form-optional" v-if="!customer._id || deviatingOwner">
        <div class="popup-form-optional-caption">optional</div>
        <h3 v-if="deviatingOwner && !customer._id">Meine Daten</h3>
        <div class="popup-form-line" v-if="!customer._id && !deviatingOwner">
          <label>Telefonnr. für Rückfragen</label>
          <input type="text" v-model="editCustomer.phone" />
        </div>
        <div class="popup-form-line" v-if="deviatingOwner">
          <label>Telefonnr. für Rückfragen</label>
          <input type="text" v-model="editRequest.phone" />
        </div>

        <div v-if="!customer._id">
          <div class="popup-form-line">
            <label>Vorname</label>
            <input type="text" v-model="editCustomer.firstName" />
          </div>
          <div class="popup-form-line">
            <label>Nachname</label>
            <input type="text" v-model="editCustomer.lastName" />
          </div>
          <div class="popup-form-line">
            <label>Adresse</label>
            <input type="text" v-model="editCustomer.address" />
          </div>
          <div class="popup-form-line">
            <label>PLZ</label>
            <input type="text" v-model="editCustomer.zip" />
          </div>
          <div class="popup-form-line">
            <label>Ort</label>
            <input type="text" v-model="editCustomer.city" />
          </div>
        </div>
        <div v-if="deviatingOwner">
          <h3>Fahrzeughalter</h3>
          <div class="popup-form-line">
            <label>Vorname</label>
            <input type="text" v-model="editOwner.firstName" />
          </div>
          <div class="popup-form-line">
            <label>Nachname</label>
            <input type="text" v-model="editOwner.lastName" />
          </div>
          <div class="popup-form-line">
            <label>Adresse</label>
            <input type="text" v-model="editOwner.address" />
          </div>
          <div class="popup-form-line">
            <label>PLZ</label>
            <input type="text" v-model="editOwner.zip" />
          </div>
          <div class="popup-form-line">
            <label>Ort</label>
            <input type="text" v-model="editOwner.city" />
          </div>
        </div>
      </div>
      <div class="popup-form-error" v-if="error">
        {{ error }}
      </div>

      <div class="popup-nav" :class="{ inactive: !submitAvailable }">
        <div class="create-request-ready-btn-prev" @click="goBack()">
          <img src="@/assets/gfx/arrow-prev.png" />
        </div>
        <button
          v-if="!requestSent"
          class="create-request-ready-btn-submit"
          @click="onSubmit()"
        >
          <span v-if="!whitelabel">An meine Werkstatt senden</span>
          <span v-if="whitelabel == 'mobilityconcept'"
            >An Mobility Concept senden</span
          >
        </button>
        <!--button class="btn-icon round small" @click="closePopup()">
          <img src="@/assets/gfx/icon_check_white.png">
        </button-->
      </div>
    </div>

    <div class="popup" v-if="emailAlreadyExists">
      <h2>Diese E-Mail ist schon registriert!</h2>
      <p>Möchtest du dich anmelden?</p>
      <form
        @submit.prevent="login()"
        class="login-form"
        :class="{ error: loginError }"
      >
        <input type="text" v-model="editCustomer.email" disabled />
        <input
          type="password"
          v-model="editCustomer.password"
          placeholder="Passwort"
        />
        <div class="popup-nav" :class="{ inactive: !submitAvailable }">
          <div class="create-request-ready-btn-prev" @click="cancelLogin()">
            <img src="@/assets/gfx/arrow-prev.png" />
          </div>
          <input type="submit" value="Login" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import async from "async";

export default {
  name: "CreateRequestReady",
  data() {
    return {
      contactPopupVisible: true,
      requestSent: false,
      error: null,
      deviatingOwner: false,
      editRequest: {},
      emailAlreadyExists: false,
      editCustomer: {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        passwordCopy: "",
        phone: "",
        address: "",
        city: "",
        zip: "",
        _id: "",
      },
      loginForm: {
        password: "",
      },
      editOwner: {
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        zip: "",
      },
    };
  },
  methods: {
    goMain() {
      this.$router.push({ name: "main" });
    },
    goBack() {
      this.$router.push({ name: "CreateRequestInterview" });
    },
    closePopup() {
      this.contactPopupVisible = false;
      this.$store.commit("SET_BUSY", false);
    },
    onSubmit() {
      if (this.customer._id) {
        this.updateCustomer().then(() => {
          console.log("updated customer");
          this.createCar().then(() => {
            this.sendRequest();
          });
        });
      } else {
        // TODO: check if email and customer exists
        this.registerCustomer().then(() => {
          if (!this.emailAlreadyExists) {
            console.log("new customer registered");
            this.login().then(() => {
              console.log("new customer loggend in");
              this.createCar().then(() => {
                this.sendRequest();
              });
            });
          }
        });
      }
    },
    registerCustomer() {
      this.$store.commit("SET_BUSY", true);
      this.error = null;
      let payload = {
        email: this.editCustomer.email,
        password: this.editCustomer.password,
        station: this.customerStation._id,
        firstName: this.editCustomer.firstName,
        lastName: this.editCustomer.lastName,
        phone: this.editCustomer.phone,
        address: this.editCustomer.address,
        city: this.editCustomer.city,
        zip: this.editCustomer.zip,
      };
      return this.$store.dispatch("customers/register", payload).then((res) => {
        console.log(res);
        this.$store.commit("SET_BUSY", false);
        if (res.success) {
          return 1;
        } else {
          this.error = "Diese E-Mail ist schon registriert.";
          this.emailAlreadyExists = true;
          return -1;
        }
      });
    },
    login() {
      let payload = {
        email: this.editCustomer.email,
        password: this.editCustomer.password,
      };
      this.$store.commit("SET_BUSY", true);
      return this.$store.dispatch("customers/login", payload).then(() => {
        this.editRequest.customer = this.customer._id;
        this.$store.dispatch("requests/getRequestStates").then(() => {
          this.$store.commit("SET_BUSY", false);
          if (!this.loginError) {
            this.emailAlreadyExists = false;
            this.error = false;
            return 1;
          } else {
            this.error = this.loginError;
            return -1;
          }
        });
      });
    },
    cancelLogin() {
      this.editCustomer.email = "";
      this.emailAlreadyExists = false;
    },
    createCar() {
      if (this.createRequestCar) {
        this.$store.commit("SET_BUSY", true);
        console.log("creating new car");
        let editCar = JSON.parse(JSON.stringify(this.createRequestCar));
        editCar.customer = this.customer._id;
        if (this.deviatingOwner) {
          editCar.owner.firstName = this.editOwner.firstName;
          editCar.owner.lastName = this.editOwner.lastName;
          editCar.owner.address = this.editOwner.address;
          editCar.owner.city = this.editOwner.city;
          editCar.owner.zip = this.editOwner.zip;
        } else {
          editCar.owner.firstName = this.editCustomer.firstName;
          editCar.owner.lastName = this.editCustomer.lastName;
          editCar.owner.address = this.editCustomer.address;
          editCar.owner.city = this.editCustomer.city;
          editCar.owner.zip = this.editCustomer.zip;
        }
        return this.$store
          .dispatch("cars/create", editCar)
          .then((res) => {
            this.$store.commit("cars/SET_CREATE_REQUEST_CAR", null);
            this.editRequest.car = res.data;
            this.$store.commit(
              "requests/SET_SELECTED_REQUEST",
              this.editRequest
            );
          })
          .then(() => {
            return this.$store
              .dispatch("cars/getByCustomer", this.customer._id)
              .then(() => {
                this.$store.commit("SET_BUSY", false);
              });
          });
      } else {
        console.log("using existing car");
        if (this.deviatingOwner) {
          let editCar = {
            _id: this.editRequest.car,
            deviatingOwner: true,
            owner: {
              firstName: this.editOwner.firstName,
              lastName: this.editOwner.lastName,
              address: this.editOwner.address,
              city: this.editOwner.city,
              zip: this.editOwner.zip,
            },
          };
          return this.$store.dispatch("cars/update", editCar).then(() => {
            return this.$store
              .dispatch("cars/getByCustomer", this.customer._id)
              .then(() => {
                this.$store.commit("SET_BUSY", false);
                return true;
              });
          });
        } else {
          return new Promise((resolve) => {
            return resolve(1);
          });
        }
      }
    },
    sendRequest() {
      console.log("sendRequest");
      this.contactPopupVisible = false;
      this.$store.commit("SET_BUSY", true);
      this.editRequest.status = "REQUESTED";
      this.editRequest.type = "DIRECT";
      this.$store.dispatch("requests/create", this.editRequest).then((res) => {
        console.log(res);
        console.log("uploadPhotos");
        console.log(this.uploadPhotos);
        let requestId = res.data;
        let photoIndex = 0;
        if (this.uploadPhotos.length > 0) {
          let that = this;
          let images = [];
          async.each(
            that.uploadPhotos,
            function (photo, callback) {
              let payload = {
                image: photo,
                key: "images/requests/" + requestId + "-" + photoIndex,
              };
              photoIndex++;
              that.$store
                .dispatch("uploadS3CameraPhoto", payload)
                .then((res) => {
                  console.log(res);
                  images.push(res);
                  let payload = {
                    _id: requestId,
                    s3Images: images,
                  };
                  that.$store
                    .dispatch("requests/update", payload)
                    .then((res) => {
                      console.log(res);
                      callback();
                    });
                });
            },
            function (err) {
              if (err) {
                console.log("A photo failed to process");
              } else {
                console.log("All photos have been processed successfully");
                console.log("And the voice message is:");
                console.log(that.voiceMessage);
                if (that.voiceMessage) {
                  let payload = {
                    audio: that.voiceMessage,
                  };
                  that.$store
                    .dispatch("requests/uploadVoiceMessage", payload)
                    .then((res) => {
                      let payload = {
                        _id: requestId,
                        voiceMessage: res.data,
                      };
                      that.$store
                        .dispatch("requests/update", payload)
                        .then((res) => {
                          console.log(res);
                          that.finalizeRequestCreation(requestId);
                        });
                    });
                } else {
                  that.finalizeRequestCreation(requestId);
                }
              }
            }
          );
        } else {
          // illegal, photos are needed
          this.closePopup();
        }
      });
    },
    updateCustomer() {
      return this.$store.dispatch("customers/update", this.editCustomer);
    },
    finalizeRequestCreation(requestId) {
      if (this.customer.selectedStation.contactPerson) {
        let payload = {
          message: "Vielen Dank für die Anfrage! Wir melden uns bald bei dir.",
          requestId: requestId,
          userId: this.customer.selectedStation.contactPerson,
        };
        this.$store.dispatch("requests/sendMessage", payload);
      }
      this.$store.commit("requests/SET_REQUEST_PENDING", false);
      this.$store
        .dispatch("requests/getByCustomer", this.customer._id)
        .then(() => {
          this.requestSent = true;
          this.closePopup();
        });
    },
    getEditData() {
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest));
      if (this.customer._id) {
        this.editCustomer._id = this.customer._id;
        this.editCustomer.email = this.customer.email;
        this.editCustomer.phone = this.customer.phone;
      }
      this.editCustomer.firstName = this.customer.firstName;
      this.editCustomer.lastName = this.customer.lastName;
      this.editCustomer.address = this.customer.address;
      this.editCustomer.city = this.customer.city;
      this.editCustomer.zip = this.customer.zip;
      // sync owner data if present
      let car;
      if (!this.createRequestCar) {
        car = this.customerCars.find((o) => o._id === this.editRequest.car);
      } else {
        car = this.createRequestCar;
      }
      this.editOwner.firstName = car.owner?.firstName;
      this.editOwner.lastName = car.owner?.lastName;
      this.editOwner.address = car.owner?.address;
      this.editOwner.city = car.owner?.city;
      this.editOwner.zip = car.owner?.zip;
      // }
    },
  },
  computed: {
    submitAvailable() {
      if (
        this.editCustomer.email.includes("@") &&
        this.editCustomer.email.includes(".") &&
        this.editCustomer.email.length > 6
      ) {
        if (this.customer._id) {
          return true;
        } else {
          if (
            this.editCustomer.password === this.editCustomer.passwordCopy &&
            this.editCustomer.password.length > 5
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      loginError: "customers/loginError",
      whitelabel: "whitelabel",
      customer: "customers/customer",
      customerCars: "cars/customerCars",
      customerStation: "stations/customerStation",
      selectedRequest: "requests/selectedRequest",
      requestPending: "requests/requestPending",
      cameraPhotos: "requests/cameraPhotos",
      documentPhotos: "requests/documentPhotos",
      uploadPhotos: "requests/uploadPhotos",
      createRequestCar: "cars/createRequestCar",
      voiceMessage: "requests/voiceMessage",
    }),
  },
  mounted() {
    this.getEditData();
    console.log("MOUNTED voice msg:");
    console.log(this.voiceMessage);
  },
};
</script>
