<template>
  <div class="qr-decoder">
    <h2 class="center">QR Code lesen:</h2>
    <qrcode-stream @decode="onDecode"></qrcode-stream>
    <div class="center">
      <p>{{code}}</p>
      <button @click="goBack()">Fertig</button>
    </div>
    <div class="modal" v-if="isDecoded || error">
      <div class="modal-clicker" @click="closePopup()"></div>
      <div class="popup" v-if="isDecoded">
        <h2>Fahrzeug erkannt:</h2>
        <p>{{car.manufacturer}}</p>
        <p>{{car.type}}</p>
        <p>Erstzulassung {{car.firstRegistration}}</p>
      </div>
      <div class="popup" v-if="error">
        <h2>{{error}}</h2>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'QrDecoder',
  data () {
    return {
      code: 'Code',
      car: {

      },
      isDecoded: false,
      error: null
    }
  },
  methods: {
    goBack () {
      this.$emit('close')
    },
    closePopup() {
      this.isDecoded = false
      this.error = null
    },
    onDecode (decodedString) {
      if (decodedString.includes('"manufacturer"')) {
        this.code = decodedString
        this.car = JSON.parse(this.code)
        this.isDecoded = true
      } else {
        this.code = decodedString
        this.error = 'Das ist kein FIASCO QR Code.'
      }
    }
  }
}

/*
{
  "manufacturer": "Mercedes Benz",
  "type": "C200",
  "firstRegistration": "2012"
}
*/

</script>
