<template>
  <div class="offer-contact modal" v-if="selectedOffer">
    <div class="modal-clicker" @click="goBack()"></div>
    <div class="popup">
      <img
        class="popup-close"
        src="@/assets/gfx/icon_close_black.png"
        @click="goBack()"
      />
      <h3 class="popup-title">
        <span v-if="mode === 'REPAIR'">Reparatur</span>
        <span v-if="mode === 'INSPECT'">Besichtigung</span>
        vereinbaren mit:<br />{{ customerStation.name }}
      </h3>
      <div class="details-item" v-if="customerStation.contactPerson">
        <img
          class="details-item-avatar"
          :src="API.imageUrl + 'users/' + customerStation.contactPerson.image"
        />
        <div class="details-item-caption">
          <div class="details-item-caption-label">Ansprechpartner:</div>
          <div class="details-item-caption-value">
            {{ customerStation.contactPerson.firstName }}
            {{ customerStation.contactPerson.lastName }}
          </div>
        </div>
      </div>
      <p>Die Werkstatt stimmt sich mit dir per FIASCO-Chat ab.</p>

      <div class="offer-contact-form">
        <div class="offer-contact-form-item">
          <label>Mein Vorame:</label>
          <input type="text" v-model="form.firstName" />
        </div>
        <div class="offer-contact-form-item">
          <label>Mein Nachname:</label>
          <input type="text" v-model="form.lastName" />
        </div>
        <!--div class="offer-contact-form-item" v-if="contactMode.phone || contactMode.whatsapp || contactMode.sms || contactMode.telegram">
          <label>Meine Telefonnummer:</label>
          <input type="text"/>
        </div-->
        <div class="offer-contact-form-consent">
          <input type="checkbox" v-model="legalsAccepted" />
          <span
            >Ich bin einverstanden, dass meine Kontaktdaten an die angegebene Werkstatt
            übermittelt werden.</span
          >
        </div>
        <p>Termin:</p>
        <div class="offer-contact-form-openers">
          <div
            class="offer-contact-form-opener"
            :class="{ active: opener === 'SOONEST' }"
            @click="opener = 'SOONEST'"
          >
            <div class="offer-contact-form-opener-marker"></div>
            <div class="offer-contact-form-opener-label">so bald wie möglich</div>
          </div>
          <div
            class="offer-contact-form-opener"
            :class="{ active: opener === 'DATE' }"
            @click="opener = 'DATE'"
          >
            <div class="offer-contact-form-opener-marker"></div>
            <div class="offer-contact-form-opener-label">ab dem</div>
            <input type="date" v-model="soonestDate" placeholder="Datum" />
          </div>
        </div>
        <div class="offer-contact-form-item">
          <label>Meine Nachricht: {{ formatDate(soonestDate) }}</label>
          <textarea v-model="customerMessage"></textarea>
        </div>
      </div>

      <button @click="sendMessage()" :disabled="!sendAvailable">
        FIASCO-Chat starten
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dateDisplayMixin from "../mixins/dateDisplay";

export default {
  name: "OfferContact",
  data() {
    return {
      photoIndex: 0,
      contactMode: {
        phone: false,
        whatsapp: false,
        telegram: false,
        sms: false,
        email: false,
      },
      soonestDate: new Date().toISOString().split("T")[0],
      opener: "SOONEST",
      legalsAccepted: false,
      offer: null,
      form: {
        firstName: "",
        lastName: "",
        email: "",
      },
      customerMessage: "",
    };
  },
  mixins: [dateDisplayMixin],
  methods: {
    goBack() {
      this.$emit("close");
    },
    sendMessage() {
      let payload = {
        message: this.customerMessage,
        requestId: this.selectedRequest._id,
      };
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("requests/sendMessage", payload).then(() => {
        this.$store.dispatch("requests/getByCustomer", this.customer._id).then(() => {
          let statusPayload = {
            _id: this.selectedRequest._id,
            status: this.mode === "INSPECT" ? "INSPECTION_REQUESTED" : "REPAIR_REQUESTED",
            statusHistory: JSON.parse(JSON.stringify(this.selectedRequest.statusHistory)),
          };
          let statusHistory = {
            status: statusPayload.status,
            customer: this.customer._id,
            changedAt: new Date(),
          };
          statusPayload.statusHistory.unshift(statusHistory);
          this.$store.dispatch("requests/update", statusPayload).then(() => {
            this.$store.dispatch("requests/getByCustomer", this.customer._id).then(() => {
              this.$store.commit("SET_BUSY", false);
              this.goBack();
            });
          });
        });
      });
    },
  },
  /*
  this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest))
      this.editRequest.status = status
      let statusHistory = {
        status: this.editRequest.status,
        user: this.userId,
        changedAt: new Date()
      }
      this.editRequest.statusHistory.unshift(statusHistory)
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('requests/update', this.editRequest).then(() => {
        this.$store.dispatch('requests/getActive').then(() => {
          this.$store.commit('SET_BUSY', false)
        })
      })
  */
  watch: {
    opener() {
      this.customerMessage = this.messageTemplate;
    },
    soonestDate() {
      this.customerMessage = this.messageTemplate;
    },
  },
  computed: {
    messageTemplate() {
      let goal = this.mode === "INSPECT" ? "Besichtigung" : "Reparatur";
      let messageString;
      if (this.opener === "SOONEST") {
        messageString =
          "Danke für das Angebot! Ich hätte gerne einen Terminvorschlag so bald wie möglich für eine " +
          goal +
          ".";
      }
      if (this.opener === "DATE") {
        messageString =
          "Danke für das Angebot! Ich hätte gerne einen Terminvorschlag für eine " +
          goal +
          " ab dem " +
          this.formatDate(this.soonestDate) +
          ".";
      }
      return messageString;
    },
    sendAvailable() {
      // let available = (this.contactMode.phone || this.contactMode.email || this.contactMode.sms || this.contactMode.whatsapp || this.contactMode.telegram) && this.legalsAccepted
      let available = this.legalsAccepted;
      return available;
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      requestStatusName: "requests/requestStatusName",
      selectedOffer: "offers/selectedOffer",
      offerMaxPrice: "offers/maxPrice",
      customerStation: "stations/customerStation",
    }),
  },
  props: {
    mode: String,
  },
  mounted() {
    /*
    this.offer = this.activeOffers.find(o => o._id === this.$route.params.id)
    */
    if (this.customer.firstName) {
      this.form.firstName = this.customer.firstName;
    }
    if (this.customer.lastName) {
      this.form.lastName = this.customer.lastName;
    }
    if (this.customer.firstName) {
      this.form.email = this.customer.email;
    }
    this.customerMessage = this.messageTemplate;
  },
};
</script>
