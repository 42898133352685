import axios from 'axios'

const state = {
  selectedCar: null,
  createRequestCar: null,
  customerCars: [],
  editCarVisible: false,
  VIN_WHITELIST: '1234567890ABCDEFGHJKLMNPRSTUVWXYZ',
}

// getters
const getters = {
  customerCars: state => state.customerCars,
  selectedCar: state => state.selectedCar,
  createRequestCar: state => state.createRequestCar,
  VIN_WHITELIST: state => state.VIN_WHITELIST,
  editCarVisible: state => state.editCarVisible,
}

// actions
const actions = {
  getByCustomer (context, payload) {
    return axios.get(context.rootGetters.API.baseUrl + 'cars/customer/' + payload, context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_CUSTOMER_CARS', response.data))
      .catch((error) => console.log(error))
  },
  create (context, payload) {
    console.log('create car')
    return axios.put(context.rootGetters.API.baseURL + 'cars/', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  update (context, payload) {
    console.log('update car')
    console.log(payload)
    return axios.patch(context.rootGetters.API.baseURL + 'cars/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  uploadCameraPhoto (context, payload) {
    console.log('uploadCameraPhoto')
    return axios.post(
      context.rootGetters.API.baseURL + 'cars/upload/camera',
      payload,
      context.rootGetters.axiosConfig
    ).then(response => {
      return response
    }).catch(error => {
      return error
    })
  },
}


// mutations
const mutations = {
  SET_CUSTOMER_CARS (state, data) {
    state.customerCars = data
  },
  SET_SELECTED_CAR (state, data) {
    state.selectedCar = data
  },
  SET_CREATE_REQUEST_CAR (state, data) {
    state.createRequestCar = data
  },
  SET_EDIT_CAR_VISIBLE (state, data) {
    state.editCarVisible = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
