<template>
  <div class="create-request-car create-request-content">
    <div
      class="create-request-car-main"
      v-if="
        !qrScanActive &&
        !vinScanActive &&
        !enterCarActive &&
        !registrationScanActive
      "
    >
      <div class="create-request-car-main-item" @click="goRegistrationScan()">
        <img
          class="create-request-car-main-item-image"
          src="@/assets/gfx/placeholder_kfz_schein.jpg"
        />
        <div class="create-request-car-main-item-caption">
          <h2>Fahrzeugschein scannen</h2>
          <p>Die Fahrzeug-Identifikationsnummer muss gut lesbar sein.</p>
        </div>
        <img
          class="create-request-car-main-item-icon"
          src="@/assets/gfx/icon_camera.png"
        />
      </div>

      <!--div class="create-request-car-main-item" @click="goVinScan()">
        <img
          class="create-request-car-main-item-image"
          src="@/assets/gfx/vin-windshield.jpg"
        />
        <div class="create-request-car-main-item-caption">
          <h2>FIN scannen</h2>
          <p>Am besten an der Windschutzscheibe</p>
        </div>
        <img
          class="create-request-car-main-item-icon"
          src="@/assets/gfx/icon_camera.png"
        />
      </div-->

      <!--div class="create-request-car-main-item" @click="goQrScan()">
        <img class="create-request-car-main-item-image" src="@/assets/gfx/qr-code.jpg">
        <div class="create-request-car-main-item-caption">
          <h2>QR Code scannen</h2>
          <p>Den QR Code erhältst du von deiner Werkstatt.</p>
        </div>
        <img class="create-request-car-main-item-icon" src="@/assets/gfx/icon_camera.png">
      </div-->

      <div class="create-request-car-main-item" @click="goEnterCar()">
        <img
          class="create-request-car-main-item-image small"
          src="@/assets/gfx/icon_edit.png"
        />
        <div class="create-request-car-main-item-caption">
          <h2>Manuell eingeben</h2>
          <p>Die 17-stellige VIN einfach eintippen</p>
        </div>
        <img
          class="create-request-car-main-item-icon"
          src="@/assets/gfx/icon_camera.png"
        />
      </div>

      <div class="create-request-car-sub">
        <p>oder</p>
      </div>
    </div>

    <div
      class="create-request-car-pool"
      v-if="
        !qrScanActive &&
        !vinScanActive &&
        !enterCarActive &&
        !registrationScanActive
      "
    >
      <h3 class="center" v-if="!qrScanActive && !vinScanActive">
        Aus meinem Fahrzeugpool wählen:
      </h3>
      <div
        class="create-request-car-pool-item"
        v-for="item in customerCars"
        :key="item._id"
        @click="onCarSelect(item)"
      >
        <!--img class="create-request-car-pool-item-avatar" :src="API.imageUrl + 'cars/' + item.image"-->
        <div class="create-request-car-pool-item-caption">
          {{ item.manufacturer }}&nbsp;{{ item.model }}
          <div class="small">{{ item.numberPlate }}</div>
          <div class="small">FIN {{ item.vin }}</div>
        </div>
      </div>
    </div>

    <div class="create-request-btn-prev" @click="goBack()">
      <img src="@/assets/gfx/arrow-prev.png" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CreateRequestCar",
  data() {
    return {
      photoIndex: 0,
      editRequest: null,
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "CreateRequestPhotos" });
    },
    goRegistrationScan() {
      this.$router.push({ name: "CreateRequestRegistrationScan" });
      // this.$store.commit("SET_REGISTRATION_SCAN_ACTIVE", true);
    },
    goVinScan() {
      console.log("goVinScan");
      this.$router.push({ name: "CreateRequestVinScan" });
      // this.$store.commit("SET_VIN_SCAN_ACTIVE", true);
    },
    goQrScan() {
      // this.$store.commit("SET_QR_SCAN_ACTIVE", true);
    },
    goEnterCar() {
      this.$router.push({ name: "CreateRequestEnterCar" });
      // this.$store.commit("SET_ENTER_CAR_ACTIVE", true);
    },
    onCarSelect(item) {
      this.editRequest.car = item._id;
      this.$store.commit("requests/SET_SELECTED_REQUEST", this.editRequest);
      this.$store.commit("cars/SET_CREATE_REQUEST_CAR", null);
      this.$emit("carSelected");
      this.$router.push({ name: "CreateRequestDamageIntro" });
    },
    onCarEditCancel() {
      this.$store.commit("SET_REGISTRATION_SCAN_ACTIVE", false);
      this.$store.commit("SET_VIN_SCAN_ACTIVE", false);
      this.$store.commit("SET_QR_SCAN_ACTIVE", false);
      this.$store.commit("SET_ENTER_CAR_ACTIVE", false);
    },
    onCarEditCommit() {
      console.log("onCarEditClose");
      console.log(this.createRequestCar);
      this.$store.commit("requests/SET_SELECTED_REQUEST", this.editRequest);
      this.$store.commit("SET_REGISTRATION_SCAN_ACTIVE", false);
      this.$store.commit("SET_VIN_SCAN_ACTIVE", false);
      this.$store.commit("SET_QR_SCAN_ACTIVE", false);
      this.$store.commit("SET_ENTER_CAR_ACTIVE", false);
      // this.$emit("carSelected");
      this.$router.push({ name: "CreateRequestDamageIntro" });
    },
    getEditData() {
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest));
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      customerCars: "cars/customerCars",
      selectedRequest: "requests/selectedRequest",
      createRequestCar: "cars/createRequestCar",
      vinScanActive: "vinScanActive",
      registrationScanActive: "registrationScanActive",
      qrScanActive: "qrScanActive",
      enterCarActive: "enterCarActive",
    }),
  },
  mounted() {
    console.log("CREATE REQUEST CAR MOUNTED");
    this.getEditData();
    this.editRequest.car = null;
    this.$store.commit("requests/SET_SELECTED_REQUEST", this.editRequest);
    this.getEditData();
  },
};
</script>
