<template>
  <div class="create-request-header">
    <div class="create-request-header-caption">
      <span class="create-request-header-caption-title">Neue Anfrage: </span>
      <span class="create-request-header-caption-step">{{ stepName }}</span>
    </div>
    <div class="create-request-header-progress">
      <div
        class="create-request-header-progress-bar"
        :style="{ width: stepPercent + '%' }"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CreateRequestHeader",
  data() {
    return {
      step: 1,
    };
  },
  computed: {
    stepName() {
      let name = "Start";
      switch (this.$route.name) {
        case "CreateRequestPhotos":
          name = "Fotos";
          break;
        case "CreateRequestCar":
          name = "Fahrzeug";
          break;
        case "CreateRequestRegistrationScan":
          name = "Fahrzeugschein";
          break;
        case "CreateRequestVinScan":
          name = "FIN scannen";
          break;
        case "CreateRequestEnterCar":
          name = "FIN eingeben";
          break;
        case "CreateRequestDamageIntro":
          name = "Schaden - Modell";
          break;
        case "CreateRequestDamage":
          name = "Schaden markieren";
          break;
        case "CreateRequestInterview":
          name = "Beschreibung";
          break;
        case "CreateRequestReady":
          name = "Senden";
          break;
      }
      return name;
    },
    stepPercent() {
      let percent = 0;
      switch (this.$route.name) {
        case "CreateRequestPhotos":
          percent = 15;
          break;
        case "CreateRequestCar":
          percent = 25;
          break;
        case "CreateRequestRegistrationScan":
          percent = 30;
          break;
        case "CreateRequestVinScan":
          percent = 30;
          break;
        case "CreateRequestEnterCar":
          percent = 30;
          break;
        case "CreateRequestDamageIntro":
          percent = 40;
          break;
        case "CreateRequestDamage":
          percent = 50;
          break;
        case "CreateRequestInterview":
          percent = 75;
          break;
        case "CreateRequestReady":
          percent = 85;
          break;
      }
      return percent;
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      createRequestCar: "cars/createRequestCar",
      selectedRequest: "requests/selectedRequest",
      requestPending: "requests/requestPending",
      cameraPhotos: "requests/cameraPhotos",
      vinScanActive: "vinScanActive",
      registrationScanActive: "registrationScanActive",
      qrScanActive: "qrScanActive",
      enterCarActive: "enterCarActive",
      editRequest: "editRequest",
      damageDummyCar: "damageDummyCar",
    }),
  },
};
</script>
