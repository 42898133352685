<template>
  <div class="intro screen register-qr">
    <div class="intro-inner">
      <img v-if="!whitelabel" src="@/assets/gfx/fiasco-logo.png" />
      <img
        v-if="whitelabel === 'rep2go'"
        src="@/assets/gfx/Rep2Go_logo_white.png"
      />
      <img
        v-if="whitelabel === 'mobilityconcept'"
        src="@/assets/gfx/mobility-concept-logo.png"
      />

      <h3>Registrieren per QR Code</h3>
      <div class="intro-nav">
        <qrcode-stream
          @decode="onDecode"
          @init="onScannerReady"
          v-if="!isDecoded && !$route.params.code"
        />

        <div class="register-qr-instructions" v-if="customerStation">
          <h2>Code erkannt!</h2>
          <p>Deine Werkstatt:</p>
          <h2>{{ customerStation.name }}</h2>
        </div>

        <p v-if="error">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Intro",
  data() {
    return {
      error: null,
      loginForm: {
        email: "",
        password: "",
        passwordRepeat: "",
        inviteCode: "",
      },
      isDecoded: false,
      invitationInfo: {
        system: "",
        inviteCode: "",
        stationName: "",
        staionId: "",
      },
    };
  },
  methods: {
    goIntro() {
      this.$router.push({ name: "intro" });
    },
    goMain() {
      this.$router.push({ name: "main" });
    },
    async onScannerReady(promise) {
      console.log("onScannerReady");
      // show loading indicator

      try {
        const { capabilities } = await promise;
        console.log(capabilities);
        this.$store.commit("SET_BUSY", false);
        // successfully initialized
      } catch (error) {
        if (error.name === "NotAllowedError") {
          // user denied camera access permisson
        } else if (error.name === "NotFoundError") {
          // no suitable camera device installed
        } else if (error.name === "NotSupportedError") {
          // page is not served over HTTPS (or localhost)
        } else if (error.name === "NotReadableError") {
          // maybe camera is already in use
        } else if (error.name === "OverconstrainedError") {
          // did you requested the front camera although there is none?
        } else if (error.name === "StreamApiNotSupportedError") {
          // browser seems to be lacking features
        }
      } finally {
        // hide loading indicator
        // this.$store.commit('SET_BUSY', false)
      }
    },
    onDecode(decodedString) {
      console.log(decodedString);
      if (decodedString.includes("werkstatt.fiasco.de")) {
        // this.invitationInfo = JSON.parse(decodedString)
        this.loginForm.inviteCode = decodedString.replace(
          "https://werkstatt.fiasco.de/",
          ""
        ); // TODO: Move to an environment variable.
        this.$store
          .dispatch("stations/getByCode", this.loginForm.inviteCode)
          .then(() => {
            if (this.customerStation) {
              this.isDecoded = true;
              /*
          this.loginForm.inviteCode = this.invitationInfo.inviteCode
          this.$store.commit('SET_BUSY', false)
          */
              this.$store.commit("SET_BUSY", false);
              if (this.customerStation) {
                console.log(this.customerStation);
                this.$store.commit("customers/TEMP_LOGIN");
                this.$emit("login");
              } else {
                this.error = "Keine Werkstatt für diesen Code gefunden.";
              }
            } else {
              this.$store.commit("SET_BUSY", false);
              this.error = "Keine Werkstatt für diesen Code gefunden.";
            }
          });
      } else {
        this.error = "Das ist kein FIASCO QR Code.";
      }
    },
  },
  computed: {
    submitAvailable() {
      let available = false;
      if (
        this.loginForm.password.length > 5 &&
        this.loginForm.password === this.loginForm.passwordRepeat &&
        this.loginForm.inviteCode.length > 3 &&
        this.loginForm.email.includes("@") &&
        this.loginForm.email.length > 4
      )
        available = true;
      return available;
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      customerStation: "stations/customerStation",
      whitelabel: "whitelabel",
    }),
  },
  mounted() {
    console.log("REGI QR MOUNTED");
    if (this.$route.params.code) {
      this.loginForm.inviteCode = this.$route.params.code;
      this.$store
        .dispatch("stations/getByCode", this.loginForm.inviteCode)
        .then(() => {
          this.$store.commit("SET_BUSY", false);
          if (this.customerStation) {
            this.isDecoded = true;
            this.$store.commit("customers/TEMP_LOGIN");
            this.$emit("login");
          }
        });
    } else {
      this.$store.commit("SET_BUSY", true);
    }
  },
};
</script>
