<template>
  <div class="request-edit screen" v-if="editItem">
    <div class="header large">
      <div class="header-top">
        <div class="header-chevron-back" @click="goBack()"></div>
        <img
          class="header-avatar"
          :src="
            API.imageUrl +
            'requests/' +
            editItem.images[editItem.images.length - 1]
          "
          v-if="editItem.s3Images.length === 0"
        />
        <s3-image
          class="header-avatar"
          :image="editItem.s3Images[editItem.s3Images.length - 1]"
          v-if="editItem.s3Images.length > 0"
        />
        <div class="header-caption">
          <div class="header-caption-main">
            {{ this.editItem.title }} {{ this.editItem.fiascoId }}
          </div>
          <div class="header-caption-sub">
            {{ formatDate(this.editItem.createdAt) }}
          </div>
        </div>
      </div>
    </div>

    <div class="content bright">
      <div class="request-edit-state">
        <div class="request-edit-label">Status</div>
        {{ requestStatusName(editItem.status) }}
      </div>

      <div class="form-line">
        <label>Titel</label>
        <input type="text" v-model="editItem.title" @input="onChange()" />
      </div>
      <div class="form-line">
        <label>Beschreibung</label>
        <textarea v-model="editItem.description" @input="onChange()" />
      </div>
      <div class="form-line">
        <label>Schaden</label>
        <div
          class="request-edit-damage"
          v-for="item in editItem.damages"
          :key="item._id"
        >
          <span>{{ damageLocationName(item.location) }}</span>
          <span>{{ damageTypeName(item.type) }}</span>
        </div>
        <p class="small">
          Schäden und Fahrzeug können nicht bearbeitet werden. Erzeuge eine neue
          Anfrage für grundsätzliche Änderungen.
        </p>
      </div>
      <div class="form-line">
        <label>Fahrzeug</label>
        <div class="request-edit-car">
          <!--img class="request-edit-car-avatar" :src="API.imageUrl + 'cars/' + requestCar.image"-->
          <div class="request-edit-car-caption">
            {{ requestCar.manufacturer }}&nbsp;{{ editItem.car.model }}
            <div class="small">{{ requestCar.numberPlate }}</div>
            <div class="small">{{ requestCar.vin }}</div>
          </div>
        </div>
      </div>
      <div class="form-line">
        <label>Fotos</label>

        <div class="request-edit-images">
          <div
            class="request-edit-image-outer"
            v-for="(item, index) in editItem.images"
            :key="index + 'img'"
          >
            <img
              class="request-edit-image"
              :src="API.imageUrl + 'requests/' + item"
            />
            <img
              class="request-edit-image-btn-delete"
              src="@/assets/gfx/icon_trash_white.png"
              @click="deleteRequestImage = item"
            />
          </div>
          <div
            class="request-edit-image-outer"
            v-for="(item, index) in editItem.s3Images"
            :key="index + 'img'"
          >
            <s3-image class="request-edit-image" :image="item" />
            <img
              class="request-edit-image-btn-delete"
              src="@/assets/gfx/icon_trash_white.png"
              @click="deleteRequestImage = item"
            />
          </div>
          <div class="request-edit-image-add" v-if="editItem.images.length < 9">
            <label for="camera-select" class="input-camera">
              <img
                class="input-camera-icon"
                src="@/assets/gfx/icon_camera.png"
              />
            </label>
            <input
              id="camera-select"
              class="input-camera"
              type="file"
              capture="environment"
              accept="image/*"
              @change="onFileSelect"
            />
            <label for="file-select" class="input-camera profile-btn-avatar">
              <img
                class="input-camera-icon gallery btn-icon"
                src="@/assets/gfx/icon_gallery.png"
              />
            </label>
            <input
              id="file-select"
              class="input-camera"
              type="file"
              accept="image/*"
              @change="onFileSelect"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-confirm" v-if="deleteRequestImage">
      <div class="modal-dialog">
        <h2>Bild löschen?</h2>
        <div class="modal-dialog-buttons">
          <button @click="deleteRequestImage = null">Abbrechen</button>
          <button @click="deletePhoto()">Bestätigen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Camera from './Camera.vue'
import async from "async";

export default {
  name: "RequestEdit",
  components: {
    // Camera
  },
  data() {
    return {
      editItem: null,
      changeTimeout: null,
      requestCar: null,
      photos: [],
      cameraActive: false,
      deleteRequestImage: null,
    };
  },
  methods: {
    goBack() {
      // this.$router.push({name: 'main'})
      this.$router.go(-1);
    },
    getEditData() {
      console.log(this.$route.params.id);
      this.editItem = JSON.parse(
        JSON.stringify(
          this.requests.find((o) => o._id === this.$route.params.id)
        )
      );
      // this.editItem = JSON.parse(JSON.stringify(this.selectedRequest))
      this.requestCar = this.customerCars.find(
        (o) => o._id === this.editItem.car
      );
    },
    onChange() {
      console.log("onChange");
      if (this.changeTimeout) {
        window.clearTimeout(this.changeTimeout);
      }
      this.changeTimeout = window.setTimeout(() => {
        console.log("now we change");
        this.updateRequest();
        window.clearTimeout(this.changeTimeout);
      }, this.CHANGE_TIMEOUT_MS);
    },
    updateRequest() {
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("requests/update", this.editItem).then(() => {
        this.$store
          .dispatch("requests/getByCustomer", this.customer._id)
          .then(() => {
            this.getEditData();
            this.$store.commit("SET_BUSY", false);
          });
      });
    },
    onGrab(photo) {
      this.photos.push(photo);
      this.$store.commit("requests/SET_CAMERA_PHOTOS", this.photos);
    },
    resetCameraPhotos() {
      this.photos = [];
      this.$store.commit("requests/SET_CAMERA_PHOTOS", this.photos);
    },
    uploadPhotos() {
      if (this.cameraPhotos.length > 0) {
        this.$store.commit("SET_BUSY", true);
        console.log("uploading photos: ");
        console.log(this.cameraPhotos);
        let that = this;
        let images = that.editItem.s3Images;
        let uploadIndex = that.editItem.s3Images.length;
        async.each(
          that.cameraPhotos,
          function (photo, callback) {
            let payload = {
              image: photo,
              key: "images/requests/" + that.editItem._id + "-" + uploadIndex,
            };
            uploadIndex++;
            that.$store.dispatch("uploadS3CameraPhoto", payload).then((res) => {
              console.log(res);
              images.push(res);
              let payload = {
                _id: that.editItem._id,
                s3Images: images,
              };
              that.$store.dispatch("requests/update", payload).then((res) => {
                console.log(res);
                callback();
              });
            });
          },
          function (err) {
            if (err) {
              console.log("A photo failed to process");
              that.$store.commit("SET_BUSY", false);
            } else {
              console.log("All photos have been processed successfully");
              that.$store.commit("requests/SET_REQUEST_PENDING", false);
              that.$store
                .dispatch("requests/getByCustomer", that.customer._id)
                .then(() => {
                  that.getEditData();
                  that.resetCameraPhotos();
                  that.cameraActive = false;
                  that.$store.commit("SET_BUSY", false);
                });
            }
          }
        );
      }
    },
    deletePhoto() {
      let item = this.deleteRequestImage;
      console.log(item);
      console.log(this.editItem.s3Images);
      this.editItem.images = this.editItem.images.filter((o) => o !== item);
      this.editItem.s3Images = this.editItem.s3Images.filter((o) => o !== item);
      this.deleteRequestImage = null;
      this.updateRequest();

      /*
      let payload = {
        file: item
      } 
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('requests/deletePhoto', payload).then((res) => { 
        console.log(res)
        this.editItem.images = this.editItem.images.filter(o => o !== item)
        this.deleteRequestImage = null
        this.updateRequest()
        this.resetCameraPhotos()
      })
      */
    },
    onFileSelect(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.$store.commit("SET_BUSY", true);
      this.$store
        .dispatch("fileToSizedBlob", { file: files[0] })
        .then((blob) => {
          this.photos.push(blob);
          this.$store.commit("requests/SET_CAMERA_PHOTOS", this.photos);
          this.$store.commit("SET_BUSY", false);
          this.uploadPhotos();
        });

      /*
      // this.createImage(files[0])
      console.log(files[0])
      var reader = new FileReader()
      reader.onload = (e) => {
        this.photos.push(e.target.result)
        this.$store.commit('requests/SET_CAMERA_PHOTOS', this.photos)
        this.uploadPhotos()
      }
      reader.readAsDataURL(files[0])
      */
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      CHANGE_TIMEOUT_MS: "CHANGE_TIMEOUT_MS",
      customer: "customers/customer",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      requestStatusName: "requests/requestStatusName",
      activeOffers: "offers/activeOffers",
      damageLocationName: "requests/damageLocationName",
      damageTypeName: "requests/damageTypeName",
      cameraPhotos: "requests/cameraPhotos",
      customerCars: "cars/customerCars",
      customerStation: "stations/customerStation",
    }),
  },
  mounted() {
    this.getEditData();
  },
};
</script>
