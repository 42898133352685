import Vue from 'vue'
import Router from 'vue-router'
import Intro from "@/components/Intro.vue"
import RegisterByCode from "@/components/RegisterByCode.vue"
import RegisterByQr from "@/components/RegisterByQr.vue"
import RegisterVerify from "@/components/RegisterVerify.vue"
import Main from "@/components/Main.vue"
import RequestOffers from "@/components/RequestOffers.vue"
// import CreateRequest from "@/components/CreateRequest.vue"
import CreateRequestHeader from "@/components/CreateRequestHeader.vue"
import CreateRequestIntro from "@/components/CreateRequestIntro.vue"
import CreateRequestPhotos from "@/components/CreateRequestPhotos.vue";
import CreateRequestCar from "@/components/CreateRequestCar.vue";
import CreateRequestVinScan from "@/components/CreateRequestVinScan.vue";
import CreateRequestRegistrationScan from "@/components/CreateRequestRegistrationScan.vue";
import CreateRequestEnterCar from "@/components/CreateRequestEnterCar.vue";
import CreateRequestDamageIntro from "@/components/CreateRequestDamageIntro.vue";
import CreateRequestDamage from "@/components/CreateRequestDamage.vue";
import CreateRequestInterview from "@/components/CreateRequestInterview.vue";
import CreateRequestOwner from "@/components/CreateRequestOwner.vue";
import CreateRequestReady from "@/components/CreateRequestReady.vue";
import Offer from "@/components/Offer.vue"
import RequestEdit from "@/components/RequestEdit.vue"
import QrDecoder from "@/components/QrDecoder.vue"

Vue.use(Router)

const routes = [
  {
    path: "/impressum",
    name: "Imprint",
    component: Intro,
  },
  {
    path: "/datenschutzerklaerung",
    name: "Privacy",
    component: Intro,
  },
  {
    path: "/neue-anfrage",
    name: "CreateRequestIntro",
    components: {
      default: CreateRequestIntro,
      header: CreateRequestHeader
    }
  },
  {
    path: "/neue-anfrage/photos",
    name: "CreateRequestPhotos",
    components: {
      default: CreateRequestPhotos,
      header: CreateRequestHeader
    }
  },
  {
    path: "/neue-anfrage/fahrzeug",
    name: "CreateRequestCar",
    components: {
      default: CreateRequestCar,
      header: CreateRequestHeader
    }
  },
  {
    path: "/neue-anfrage/fahrzeug/vin-scan",
    name: "CreateRequestVinScan",
    components: {
      default: CreateRequestVinScan,
      header: CreateRequestHeader
    }
  },
  {
    path: "/neue-anfrage/fahrzeug/vin-eingabe",
    name: "CreateRequestEnterCar",
    components: {
      default: CreateRequestEnterCar,
      header: CreateRequestHeader
    }
  },
  {
    path: "/neue-anfrage/fahrzeug/schein-scan",
    name: "CreateRequestRegistrationScan",
    components: {
      default: CreateRequestRegistrationScan,
      header: CreateRequestHeader
    }
  },
  {
    path: "/neue-anfrage/schaden-modell",
    name: "CreateRequestDamageIntro",
    components: {
      default: CreateRequestDamageIntro,
      header: CreateRequestHeader
    }
  },
  {
    path: "/neue-anfrage/schaden",
    name: "CreateRequestDamage",
    component: CreateRequestDamage,
  },
  {
    path: "/neue-anfrage/beschreibung",
    name: "CreateRequestInterview",
    components: {
      default: CreateRequestInterview,
      header: CreateRequestHeader
    }
  },
  {
    path: "/neue-anfrage/halter",
    name: "CreateRequestOwner",
    components: {
      default: CreateRequestOwner,
      header: CreateRequestHeader
    }
  },
  {
    path: "/neue-anfrage/senden",
    name: "CreateRequestReady",
    components: {
      default: CreateRequestReady,
      header: CreateRequestHeader
    }
  },
  {
    path: "/qr-decode",
    name: "qr-decode",
    component: QrDecoder,
  },
  {
    path: "/registrieren/code",
    name: "registerCode",
    component: RegisterByCode,
  },
  {
    path: "/registrieren/qr-code/:code?",
    name: "registerQr",
    component: RegisterByQr,
  },
  {
    path: "/registrieren/bestaetigen/:code/:email",
    name: "registerVerify",
    component: RegisterVerify,
  },
  {
    path: "/zentrale",
    name: "main",
    component: Main,
  },
  {
    path: "/anfrage/:id/angebote",
    name: "request-offers",
    component: RequestOffers,
  },
  {
    path: "/anfrage/:id/bearbeiten",
    name: "request-edit",
    component: RequestEdit,
  },
  {
    path: "/anfrage/:requestId/angebot/:id",
    name: "offer",
    component: Offer,
  },
  {
    path: "/:partner?",
    name: "intro",
    component: Intro,
  },
]

const router = new Router({
  routes
})

export default router
