<template>
  <div class="main screen">
    <div class="header large">
      <div class="header-top">
        <!--img class="header-logo" src="@/assets/gfx/cloud-werkstatt-logo.png" @click="showMenu()"-->
        <div class="header-menu-btn" @click="showMenu()">
          <div class="header-menu-btn-line"></div>
          <div class="header-menu-btn-line"></div>
          <div class="header-menu-btn-line"></div>
        </div>
        <div class="header-caption">
          <img
            v-if="!whitelabel"
            class="header-logo"
            src="@/assets/gfx/fiasco-logo.png"
          />
          <img
            v-if="whitelabel === 'rep2go'"
            class="header-logo"
            src="@/assets/gfx/Rep2Go_logo_white.png"
          />
          <img
            v-if="whitelabel === 'mobilityconcept'"
            class="header-logo"
            src="@/assets/gfx/mobility-concept-logo.png"
          />
        </div>
        <div @click="showProfile()">
          <img
            class="header-profile"
            :src="API.imageUrl + 'customers/' + customer.image"
            v-if="customer.image && !customer.s3Image"
          />
          <s3-image
            class="header-profile"
            :image="customer.s3Image"
            v-if="customer.s3Image"
          />
          <img
            class="header-profile"
            src="@/assets/gfx/profile-placeholder.png"
            v-if="!customer.image && !customer.s3Image"
          />
        </div>
      </div>
      <div class="header-my-garage" v-if="whitelabel != 'mobilityconcept'">
        <img
          class="header-my-garage-poster"
          :src="
            API.imageUrl + 'stations/posters/' + customerStation.posterImages[0]
          "
          v-if="customerStation.s3PosterImages.length === 0"
        />
        <s3-image
          class="header-my-garage-poster"
          :image="customerStation.s3PosterImages[0]"
          v-if="customerStation.s3PosterImages.length > 0"
        />
        <img
          class="header-my-garage-logo"
          :src="API.imageUrl + 'stations/logos/' + customerStation.logoImage"
          v-if="customerStation.logoImage && !customerStation.s3LogoImage"
        />
        <s3-image
          class="header-my-garage-logo"
          :image="customerStation.s3LogoImage"
          v-if="customerStation.s3LogoImage"
        />
        <div class="header-my-garage-caption">
          <div class="small">Deine Wunschwerkstatt:</div>
          <div>{{ customerStation.name }}</div>
          <div class="header-my-garage-caption-links">
            <a
              v-if="customerStation.phone"
              :href="'tel:' + customerStation.phone"
              ><img src="@/assets/gfx/icon_phone_blue.png"
            /></a>
            <a
              v-if="customerStation.web"
              :href="customerStation.web"
              target="_blank"
              ><img src="@/assets/gfx/icon_web_blue.png"
            /></a>
            <a
              v-if="customerStation.instagram"
              :href="customerStation.instagram"
              target="_blank"
              ><img src="@/assets/gfx/icon-insta.png"
            /></a>
            <a
              v-if="customerStation.facebook"
              :href="customerStation.facebook"
              target="_blank"
              ><img src="@/assets/gfx/icon-fb.png"
            /></a>
            <a
              v-if="customerStation.twitter"
              :href="customerStation.twitter"
              target="_blank"
              ><img src="@/assets/gfx/icon-twitter.png"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="list main-list" :class="{ extended: !customer._id }">
        <div class="main-welcome" v-if="!customer.emailVerified">
          <h2 v-if="whitelabel != 'mobilityconcept'">Willkommen bei FIASCO!</h2>
          <h2 v-if="whitelabel == 'mobilityconcept'">
            Willkommen bei Mobility Concept!
          </h2>
          <p v-if="customer._id">
            Bitte bestätige deine E-Mail innerhalb von 48 Stunden.
          </p>
          <button
            v-if="customer._id && !emailSentAgain"
            @click="resendRegister()"
          >
            E-Mail noch einmal senden
          </button>
          <div v-if="!customer._id" class="main-welcome-menu">
            <img
              class="main-welcome-fox"
              src="@/assets/gfx/fox-victory-transparent.png"
            />
            <button class="main-welcome-btn-primary" @click="createRequest()">
              Fall melden
            </button>
            <button @click="showProfile()">Profil erstellen</button>
          </div>
          <p v-if="emailSentAgain">Bestätigungs-Email wurde versendet.</p>
        </div>

        <!-- OPEN -->
        <div
          class="list-item"
          v-for="item in openRequests"
          :key="item._id"
          @click="goRequest(item)"
        >
          <div class="list-item-top">
            <img
              class="list-item-image"
              v-if="item.s3Images.length === 0"
              :src="
                API.imageUrl + 'requests/' + item.images[item.images.length - 1]
              "
            />
            <s3-image
              class="list-item-image"
              v-if="item.s3Images.length > 0"
              :image="item.s3Images[item.s3Images.length - 1]"
            />
            <div class="list-item-id">Auftrag-Nr. {{ item.fiascoId }}</div>
            <div class="list-item-caption">
              <div class="list-item-caption-main">
                {{ item.title }}
              </div>
              <div class="list-item-caption-sub">
                {{ formatDate(item.createdAt) }}
              </div>
            </div>
          </div>
          <div class="list-item-state">
            <div class="list-item-state-caption">
              <span v-if="item.offer && item.status === 'OFFERED'"
                >Dein Angebot ist da</span
              >
              <span v-if="item.offer && item.status !== 'OFFERED'">{{
                requestStatusName(item.status)
              }}</span>
              <span v-if="!item.offer">Noch keine Angebote</span>
            </div>
            <img
              v-if="item.offer"
              class="list-item-state-icon"
              src="@/assets/gfx/icon_mail.png"
            />
          </div>
        </div>

        <!-- FIXED AND READY -->
        <div
          class="list-item ready"
          v-for="item in fixedAndReadyRequests"
          :key="item._id"
          @click="goRequest(item)"
        >
          <div class="list-item-top">
            <img
              class="list-item-image"
              v-if="item.s3Images.length === 0"
              :src="
                API.imageUrl + 'requests/' + item.images[item.images.length - 1]
              "
            />
            <s3-image
              class="list-item-image"
              v-if="item.s3Images.length > 0"
              :image="item.s3Images[item.s3Images.length - 1]"
            />
            <div class="list-item-id">Auftrag-Nr. {{ item.fiascoId }}</div>
            <div class="list-item-caption">
              <div class="list-item-caption-main">
                {{ item.title }}
              </div>
              <div class="list-item-caption-sub">
                {{ formatDate(item.createdAt) }}
              </div>
            </div>
          </div>
          <div class="list-item-state">
            <div class="list-item-state-caption">
              <span v-if="item.offer && item.status === 'OFFERED'"
                >1 Angebot</span
              >
              <span v-if="item.offer && item.status !== 'OFFERED'">{{
                requestStatusName(item.status)
              }}</span>
              <span v-if="!item.offer">Noch keine Angebote</span>
            </div>
            <img
              v-if="item.offer"
              class="list-item-state-icon"
              src="@/assets/gfx/icon_mail.png"
            />
          </div>
        </div>

        <button
          class="main-list-btn-closed-cases transparent"
          v-if="fixedRequests.length > 0"
          @click="showFixed = !showFixed"
        >
          <span v-if="!showFixed"
            >Abgeschlossene Fälle anzeigen ({{ fixedRequests.length }})</span
          >
          <span v-if="showFixed">Abgeschlossene Fälle verbergen</span>
        </button>

        <!-- FIXED -->
        <div v-if="showFixed">
          <div class="list-item" v-for="item in fixedRequests" :key="item._id">
            <div class="list-item-top">
              <img
                class="list-item-image"
                v-if="item.s3Images.length === 0"
                :src="
                  API.imageUrl +
                  'requests/' +
                  item.images[item.images.length - 1]
                "
              />
              <s3-image
                class="profile-avatar"
                v-if="item.s3Images.length > 0"
                :image="item.s3Images[item.s3Images.length - 1]"
              />
              <div class="list-item-id">
                Auftrag-Nr. {{ item.fiascoId }} IMG
                {{ item.s3Images[item.s3Images.length - 1] }}
              </div>
              <div class="list-item-caption">
                <div class="list-item-caption-main">
                  {{ item.title }}
                </div>
                <div class="list-item-caption-sub">
                  {{ formatDate(item.createdAt) }}
                </div>
              </div>
            </div>
            <div class="list-item-state">
              <div class="list-item-state-caption">
                <span v-if="item.offer">{{
                  requestStatusName(item.status)
                }}</span>
              </div>
              <img
                v-if="item.offer"
                class="list-item-state-icon"
                src="@/assets/gfx/icon_mail.png"
              />
            </div>
            <div class="list-item-rating">
              <div
                class="list-item-rating-hint"
                v-if="!item.rating"
                @click="startRating(item)"
              >
                Bist du zufrieden?
                <div class="list-item-rating-hint-cta">Jetzt bewerten</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="main-add-btn" @click="createRequest()" v-if="customer._id">
        <img class="main-add-btn-icon" src="@/assets/gfx/sign_crash.png" />
        Neue Anfrage
      </button>
    </div>
    <transition name="menu">
      <Menu
        v-if="menuVisible"
        @close="menuVisible = false"
        @logout="onLogout"
      />
    </transition>
    <transition name="profile">
      <Profile v-if="profileVisible" @close="profileVisible = false" />
    </transition>

    <div class="modal" v-if="ratingRequest">
      <div class="modal-clicker" @click="ratingRequest = null"></div>
      <div class="rating popup">
        <img
          class="popup-close"
          src="@/assets/gfx/icon_close_black.png"
          @click="ratingRequest = null"
        />
        <h3>Reparatur bewerten:<br />{{ ratingRequest.title }}</h3>
        <p>
          vom
          {{
            formatDate(
              ratingRequest.statusHistory.find((o) => o.status === "FIXED")
                .changedAt
            )
          }}
        </p>

        <div class="rating-line">
          <div class="rating-line-label">Preis/Leistung</div>
          <div class="rating-line-stars">
            <span
              v-for="n in 5"
              :key="n + 'star'"
              :class="{ active: ratingRequest.rating.performance >= n }"
              @click="setRating('performance', n)"
            >
              &starf;
            </span>
          </div>
        </div>
        <div class="rating-line">
          <div class="rating-line-label">Kommunikation</div>
          <div class="rating-line-stars">
            <span
              v-for="n in 5"
              :key="n + 'star'"
              :class="{ active: ratingRequest.rating.communication >= n }"
              @click="setRating('communication', n)"
            >
              &starf;
            </span>
          </div>
        </div>
        <div class="rating-line">
          <div class="rating-line-label">Zufriedenheit</div>
          <div class="rating-line-stars">
            <span
              v-for="n in 5"
              :key="n + 'star'"
              :class="{ active: ratingRequest.rating.satisfaction >= n }"
              @click="setRating('satisfaction', n)"
            >
              &starf;
            </span>
          </div>
        </div>

        <textarea
          v-model="ratingRequest.rating.comment"
          placeholder="Kommentar"
        />
        <button @click="sendRating()" :disabled="!ratingReady()">Senden</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Menu from "./Menu.vue";
import Profile from "./Profile.vue";

export default {
  name: "Main",
  components: {
    Menu,
    Profile,
  },
  data() {
    return {
      menuVisible: false,
      profileVisible: false,
      showFixed: false,
      ratingRequest: null,
      emailSentAgain: false,
    };
  },
  methods: {
    goRequest(item) {
      this.$store.commit("requests/SET_SELECTED_REQUEST", item);
      this.$store.commit("offers/SET_SELECTED_OFFER", null);
      this.$router.push({ name: "request-offers", params: { id: item._id } });
      /*
      if (item.status === 'REQUESTED') {
        this.$router.push({name: 'request-edit', params: {id: item._id}})
      }
      if (item.status === 'OFFERED' && item.offer) {
        this.$router.push({name: 'request-offers', params: {id: item._id}})
      }
      */
      /*
      if (item.status !== 'OFFERED' && item.offer && item.statusHistory.some(o => o.status === 'OFFERED')) {
        this.$router.push({name: 'offer', params: { 
          requestId: this.selectedRequest._id,
          id: item.offer
        }})
      }
      */
    },
    createRequest() {
      let request = {
        customer: this.customer._id,
        carLocation: {
          zip: "",
        },
      };
      this.$store.commit("requests/SET_CAMERA_PHOTOS", []);
      this.$store.commit("requests/SET_DOCUMENT_PHOTOS", []);
      this.$store.commit("requests/SET_REQUEST_PENDING", true);
      this.$store.commit("requests/SET_SELECTED_REQUEST", request);
      this.$router.push({ name: "CreateRequestIntro" });
    },
    showMenu() {
      this.menuVisible = true;
    },
    onLogout() {
      this.$emit("logout");
    },
    resendRegister() {
      this.$store
        .dispatch("customers/resendRegister", this.customer)
        .then(() => {
          this.emailSentAgain = true;
        });
    },
    showProfile() {
      this.profileVisible = true;
    },
    startRating(item) {
      this.ratingRequest = JSON.parse(JSON.stringify(item));
      this.ratingRequest.rating = {
        performance: 0,
        communication: 0,
        satisfaction: 0,
        comment: "",
      };
    },
    setRating(category, level) {
      this.ratingRequest.rating[category] = level;
      this.$forceUpdate();
    },
    sendRating() {
      this.ratingRequest.rating.ratedAt = new Date();
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("requests/update", this.ratingRequest).then(() => {
        this.$store
          .dispatch("requests/getByCustomer", this.customer._id)
          .then(() => {
            this.$store.commit("SET_BUSY", false);
            this.ratingRequest = null;
          });
      });
    },
    ratingReady() {
      if (!this.ratingRequest) {
        return false;
      } else {
        return (
          this.ratingRequest.rating.performance > 0 &&
          this.ratingRequest.rating.communication > 0 &&
          this.ratingRequest.rating.satisfaction > 0
        );
      }
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      whitelabel: "whitelabel",
      customer: "customers/customer",
      requests: "requests/requests",
      openRequests: "requests/openRequests",
      fixedAndReadyRequests: "requests/fixedAndReadyRequests",
      fixedRequests: "requests/fixedRequests",
      selectedRequest: "requests/selectedRequest",
      requestPending: "requests/requestPending",
      offers: "offers/offers",
      requestStatusName: "requests/requestStatusName",
      customerStation: "stations/customerStation",
      newDataAvailable: "newDataAvailable",
      lastUpdateCall: "lastUpdateCall",
      lastUpdate: "requests/lastUpdate",
    }),
  },
};
</script>
