<template>
  <div class="create-request-registration-scan create-request-content">
    <h2 class="center" v-if="!resultVisible">Fahrzeugschein scannen</h2>
    <h2 class="center" v-if="resultVisible">Scan fertig!</h2>

    <div class="center" v-if="!resultVisible">
      <p>Aufklappen und Innenseite scannen:</p>
      <img
        class="create-request-registration-scan-example-image"
        src="@/assets/gfx/placeholder_kfz_schein.jpg"
      />
    </div>

    <div class="create-request-registration-scan-nav" v-if="!resultVisible">
      <label for="file-select" class="input-camera profile-btn-avatar">
        <img
          class="input-camera-icon btn-icon"
          src="@/assets/gfx/icon_camera.png"
        />
      </label>
      <input
        id="file-select"
        class="input-camera profile-btn-avatar"
        type="file"
        capture="environment"
        accept="image/*"
        @change="onFileChange"
      />
    </div>
    <img
      class="create-request-registration-scan-preview"
      v-if="previewPhoto"
      :src="previewPhoto"
    />
    <div class="center" v-if="resultVisible">Bitte Daten überprüfen:</div>
    <div
      class="create-request-registration-scan-result"
      v-if="scanResult.errorMsg"
    >
      <p>Fehler:</p>
      <p>{{ scanResult.errorMsg }}</p>
    </div>
    <div class="create-request-registration-scan-result" v-if="resultVisible">
      <label>FIN</label>
      <input v-model="scanResult.vin" />
      <label>Nummernschild</label>
      <input v-model="scanResult.numberPlate" />
      <label>Erstzulassung (JJJJ-MM-TT)</label>
      <input v-model="scanResult.firstRegistration" />
      <label>Vorname</label>
      <input v-model="scanResult.firstName" />
      <label>Nachname</label>
      <input v-model="scanResult.lastName" />
      <label>Adresse</label>
      <input v-model="scanResult.address" />
      <label>PLZ</label>
      <input v-model="scanResult.zip" />
      <label>Ort</label>
      <input v-model="scanResult.city" />
    </div>

    <!--div class="create-request-registration-scan-subnav">
      <button class="secondary" @click="close()">Abbrechen</button>
      <button @click="commitVin" v-if="scanResult.vin">Daten übernehmen</button>
    </div-->

    <div class="create-request-btn-prev" @click="close()">
      <img src="@/assets/gfx/arrow-prev.png" />
    </div>

    <div
      class="create-request-btn-next"
      @click="commitVin()"
      :disabled="!scanResult.vin"
    >
      <img src="@/assets/gfx/arrow-next.png" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "CreateRequestVin",
  data() {
    return {
      resultString: "",
      selectedFile: false,
      editCar: {
        vin: "",
        kilometers: 0,
        owner: {},
      },
      previewPhoto: "",
      scanResult: {
        vin: null,
        numberPlate: null,
        contructionYear: null,
        zip: null,
      },
      customerData: {},
      resultVisible: false,
    };
  },
  methods: {
    close() {
      // this.$emit("close");
      this.$router.push({ name: "CreateRequestCar" });
    },
    onFileChange: function (e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.$store.commit("SET_BUSY", true);
      this.$store
        .dispatch("fileToSizedBlob", { file: files[0] })
        .then((blob) => {
          this.previewPhoto = blob;
          this.$store.commit("SET_BUSY", false);
          this.scanK();
        });
    },
    resetForm: function () {
      this.errorMsg = null;
      this.scanResult.vin = null;
      this.scanResult.numberPlate = null;
      this.scanResult.zip = null;
      this.scanResult.city = null;
      this.scanResult.address = null;
      this.scanResult.firstRegistration = null;
    },
    scanK() {
      this.resetForm();
      this.$store.commit("SET_BUSY", true);
      let fd = new FormData();
      let url = "https://api.fahrzeugschein-digital.de/api/v1/zb1"; // TODO: Move to an environment variable.
      fd.append(
        "image",
        this.previewPhoto.replace("data:image/jpeg;base64,", "")
      );
      // fd.append('api_token', 'fyiyFzXsy1Z71L1JqFDeH0NSl9lAsGXC')
      fd.append("api_token", "aL0gDPCRpHIQsGFnrBuznOzpDVhnXzJe"); // TODO: This needs to be removed from the app and moved into an environment variable of the api (including new endpoints etc.)
      axios
        .post(url, fd)
        .then((res) => {
          console.log(res);
          this.$store.commit("SET_BUSY", false);
          if (res.data) {
            console.log("SCAN DONE");
            console.log(res.data);
            if (res.data.datum_erstzulassung_lang) {
              if (res.data.datum_erstzulassung_lang.length > 0) {
                if (res.data.datum_erstzulassung_lang[0].probability > 0.8) {
                  this.scanResult.firstRegistration =
                    res.data.datum_erstzulassung_lang[0].value;
                }
              }
            }
            if (res.data.fin) {
              if (res.data.fin.length > 0) {
                if (res.data.fin[0].probability > 0.9) {
                  this.scanResult.vin = res.data.fin[0].value;
                }
              }
            }
            if (res.data.hsn_tsn) {
              if (res.data.hsn_tsn.length > 0) {
                if (res.data.hsn_tsn[0].probability > 0.9) {
                  this.scanResult.hsnTsn = res.data.hsn_tsn[0].value;
                }
              }
            }
            if (res.data.kennzeichen) {
              if (res.data.kennzeichen.length > 0) {
                if (res.data.kennzeichen[0].probability > 0.8) {
                  let rawNumberPlate = res.data.kennzeichen[0].value;
                  let position = rawNumberPlate.search(/\d/);
                  let formatted =
                    rawNumberPlate.substring(0, position) +
                    " " +
                    rawNumberPlate.substring(position);
                  this.scanResult.numberPlate = formatted; // res.data.kennzeichen[0].value
                }
              }
            }
            if (res.data.plz) {
              if (res.data.plz.length > 0) {
                if (res.data.plz[0].probability > 0.8) {
                  this.scanResult.zip = res.data.plz[0].value;
                }
              }
            }

            if (res.data.name) {
              if (res.data.name.length > 0) {
                if (res.data.name[0].probability > 0.8) {
                  this.scanResult.lastName = res.data.name[0].value;
                }
              }
            }
            if (res.data.vorname) {
              if (res.data.vorname.length > 0) {
                if (res.data.vorname[0].probability > 0.8) {
                  this.scanResult.firstName = res.data.vorname[0].value;
                }
              }
            }
            if (res.data.strasse) {
              if (res.data.strasse.length > 0) {
                if (res.data.strasse[0].probability > 0.8) {
                  this.scanResult.address = res.data.strasse[0].value;
                }
              }
            }
            if (res.data.ort) {
              if (res.data.ort.length > 0) {
                if (res.data.ort[0].probability > 0.8) {
                  this.scanResult.city = res.data.ort[0].value;
                }
              }
            }
            console.log(this.customerData);
          } else {
            this.errorMsg = "Konnte nicht scannen.";
          }
          if (this.scanResult.vin) {
            // this.commitVin()

            this.customerData.firstName = this.scanResult.firstName;
            this.customerData.lastName = this.scanResult.lastName;
            this.customerData.city = this.scanResult.city;
            this.customerData.address = this.scanResult.address;
            this.customerData.zip = this.scanResult.zip;
            this.resultVisible = true;
            // existing values will not be overridden:
            this.$store.commit(
              "customers/SET_CUSTOMER_DATA",
              this.customerData
            );
          } else {
            this.errorMsg = "Daten nicht erkennbar.";
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("SET_BUSY", false);
          this.errorMsg = "Konnte nicht scannen.";
        });
    },
    commitVin() {
      this.editCar.customer = this.customer._id;
      this.editCar.vin = this.scanResult.vin;
      this.editCar.numberPlate = this.scanResult.numberPlate;
      this.editCar.firstRegistration = this.scanResult.firstRegistration;
      this.editCar.hsnTsn = this.scanResult.hsnTsn;

      this.editCar.owner.firstName = this.scanResult.firstName;
      this.editCar.owner.lastName = this.scanResult.lastName;
      this.editCar.owner.address = this.scanResult.address;
      this.editCar.owner.zip = this.scanResult.zip;
      this.editCar.owner.city = this.scanResult.city;
      this.$store.commit("requests/ADD_CAMERA_PHOTO", this.previewPhoto); // previewPhoto has already been processed by 'fileToSizedBlob'
      this.$store.commit("cars/SET_CREATE_REQUEST_CAR", this.editCar);
      console.log(this.createRequestCar);
      this.$router.push({ name: "CreateRequestDamageIntro" });
      // this.$emit("commit");
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      customerCars: "cars/customerCars",
      selectedRequest: "requests/selectedRequest",
      createRequestCar: "cars/createRequestCar",
    }),
  },
  mounted() {
    console.log("mounted registration Scanner");
  },
};
</script>
