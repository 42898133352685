<template>
  <div class="profile-modal modal" v-if="editCustomer">
    <div class="modal-clicker" @click="close()"></div>
    <div class="profile">
      <div class="profile-header">
        <div class="profile-header-chevron-back" @click="close()"></div>
        <h2 class="center">Mein Profil</h2>
      </div>

      <div class="profile-content">
        <div class="profile-avatar-outer" v-if="customer._id">
          <img
            class="profile-avatar"
            :src="API.imageUrl + 'customers/' + editCustomer.image"
            v-if="editCustomer.image && !editCustomer.s3Image"
          />
          <s3-image
            class="profile-avatar"
            v-if="editCustomer.s3Image"
            :image="editCustomer.s3Image"
          />

          <div
            class="profile-avatar"
            v-if="!editCustomer.image && !editCustomer.s3Image"
          />
          <button
            class="btn-icon round tiny"
            @click="goEditMode()"
            v-if="!editMode"
          >
            <img src="@/assets/gfx/icon_edit.png" />
          </button>
          <label
            for="file-select"
            class="input-camera profile-btn-avatar"
            v-if="editMode"
          >
            <img
              class="input-camera-icon btn-icon"
              src="@/assets/gfx/icon_camera.png"
            />
          </label>
          <input
            id="file-select"
            class="input-camera profile-btn-avatar"
            type="file"
            capture="user"
            accept="image/*"
            @change="onFileSelect"
            v-if="editMode"
          />
          <label
            for="file-select"
            class="input-camera profile-btn-avatar"
            v-if="editMode"
          >
            <img
              class="input-camera-icon gallery btn-icon"
              src="@/assets/gfx/icon_gallery.png"
            />
          </label>
          <input
            id="file-select"
            class="input-camera profile-btn-avatar"
            type="file"
            accept="image/*"
            @change="onFileSelect"
            v-if="editMode"
          />
        </div>
        <div class="profile-form-line">
          <label>Vorname</label>
          <input
            type="text"
            v-model="editCustomer.firstName"
            :disabled="!editMode"
          />
        </div>
        <div class="profile-form-line">
          <label>Nachname</label>
          <input
            type="text"
            v-model="editCustomer.lastName"
            :disabled="!editMode"
          />
        </div>
        <div class="profile-form-line">
          <label>E-Mail</label>
          <input
            type="email"
            v-model="editCustomer.email"
            :disabled="customer.email.length > 3 || !editMode"
          />
          <div
            class="form-message check"
            v-if="!editMode && customer.emailVerified"
          >
            <img src="@/assets/gfx/icon_check_white.png" />
            E-Mail-Adresse bestätigt.
          </div>
          <div class="form-message" v-if="!editMode && !customer.emailVerified">
            Bitte E-Mail-Adresse bestätigen.
          </div>
          <div
            class="form-message"
            v-if="editMode && customer.email.length > 3"
          >
            Deine E-Mail-Adresse kann nicht geändert werden. Lege für eine neue
            E-Mail ein neues Konto an.
          </div>
        </div>
        <div class="profile-form-line" v-if="!customer._id">
          <label>Passwort (mind. 6 Zeichen)</label>
          <input type="password" v-model="editCustomer.password" />
        </div>
        <div class="profile-form-line" v-if="!customer._id">
          <label>Passwort bestätigen</label>
          <input type="password" v-model="editCustomer.passwordCopy" />
        </div>
        <div class="profile-form-line">
          <label>PLZ</label>
          <input type="text" v-model="editCustomer.zip" :disabled="!editMode" />
        </div>
        <div class="profile-form-line">
          <label>Stadt</label>
          <input
            type="text"
            v-model="editCustomer.city"
            :disabled="!editMode"
          />
        </div>
        <div class="profile-form-line">
          <label>Adresse</label>
          <input
            type="text"
            v-model="editCustomer.address"
            :disabled="!editMode"
          />
        </div>
        <div class="profile-form-line">
          <label>Mobil Nr.</label>
          <input
            type="text"
            v-model="editCustomer.phone"
            :disabled="!editMode"
          />
        </div>
        <div class="profile-form-line" v-if="editMode">
          <button :disabled="!submitAvailable" @click="updateCustomer()">
            Speichern
          </button>
        </div>
        <div class="profile-form-line" v-if="editMode">
          <button class="secondary" @click="editMode = false">Abbrechen</button>
          {{ error }}
        </div>

        <div class="profile-cars-outer" v-if="!editMode && customer.email">
          <h2>Meine Fahrzeuge</h2>
          <div class="profile-cars">
            <div
              class="profile-car-pool-item"
              v-for="item in customerCars"
              :key="item._id"
              @click="onCarClick(item)"
            >
              <!--img class="create-request-car-pool-item-avatar" :src="API.imageUrl + 'cars/' + item.image"-->
              <div class="create-request-car-pool-item-caption">
                {{ item.manufacturer }} &nbsp; {{ item.model }}&nbsp;
                {{ item.firstRegistration }}
                <div class="small">{{ item.numberPlate }}</div>
                <div class="small">{{ item.vin }}</div>
              </div>
            </div>
            <button @click="createCar()">Fahrzeug hinzufügen</button>
          </div>
        </div>
      </div>
    </div>
    <car-edit v-if="editCarVisible" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import CarEdit from "./CarEdit.vue";

export default {
  name: "Profile",
  data() {
    return {
      menuVisible: false,
      editMode: false,
      editCustomer: null,
      avatarUpload: null,
      miniAvatarUpload: null,
      error: null,
    };
  },
  components: {
    CarEdit,
  },
  methods: {
    close() {
      this.$emit("close");
    },
    goEditMode() {
      this.editMode = !this.editMode;
    },
    updateCustomer() {
      if (this.customer.email.length < 3) {
        this.registerCustomer().then(() => {
          this.login().then(() => {
            this.editMode = false;
            this.close();
          });
        });
      } else {
        this.$store.commit("SET_BUSY", true);
        delete this.editCustomer.password;
        this.$store.dispatch("customers/update", this.editCustomer).then(() => {
          this.$store
            .dispatch("customers/getById", this.customer._id)
            .then(() => {
              this.getEditData();
              this.$store.commit("SET_BUSY", false);
            });
        });
      }
    },
    registerCustomer() {
      this.$store.commit("SET_BUSY", true);
      this.error = null;
      let payload = {
        email: this.editCustomer.email,
        password: this.editCustomer.password,
        station: this.customerStation._id,
        firstName: this.editCustomer.firstName,
        lastName: this.editCustomer.lastName,
        phone: this.editCustomer.phone,
        address: this.editCustomer.address,
        city: this.editCustomer.city,
        zip: this.editCustomer.zip,
      };
      return this.$store.dispatch("customers/register", payload).then((res) => {
        console.log(res);
        this.$store.commit("SET_BUSY", false);
        if (res.success) {
          return 1;
        } else {
          this.error = "Diese E-Mail ist schon registriert.";
          return -1;
        }
      });
    },
    login() {
      let payload = {
        email: this.editCustomer.email,
        password: this.editCustomer.password,
      };
      this.$store.commit("SET_BUSY", true);
      return this.$store.dispatch("customers/login", payload).then(() => {
        this.$store.dispatch("requests/getRequestStates").then(() => {
          this.$store.commit("SET_BUSY", false);
          if (!this.loginError) {
            return 1;
          } else {
            this.error = this.loginError;
            return -1;
          }
        });
      });
    },
    getEditData() {
      this.editCustomer = JSON.parse(JSON.stringify(this.customer));
    },
    onFileSelect(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.$store.commit("SET_BUSY", true);
      this.$store
        .dispatch("fileToSizedBlob", { file: files[0] })
        .then((blob) => {
          this.avatarUpload = blob;
          this.uploadAvatar();
          /*
        this.$store.dispatch('fileToMiniBlob', {file: files[0]}).then(blob => {
          this.miniAvatarUpload = blob
        })
        */
        });
    },
    uploadAvatar() {
      console.log("uploading avatar");
      let payload = {
        key: "images/customers/" + this.editCustomer._id,
        image: this.avatarUpload,
      };
      this.$store.dispatch("uploadS3CameraPhoto", payload).then((res) => {
        console.log(res);
        this.editCustomer.s3Image = res;
        this.updateCustomer();
        // this.uploadMiniAvatar()
      });
      /*
      this.$store.dispatch('customers/uploadCameraPhoto', payload).then((res) => {
        this.editCustomer.image = res.data
        this.updateCustomer()
        // this.uploadMiniAvatar()
      })
      */
    },
    uploadMiniAvatar() {
      console.log("uploading mini avatar");
      let payload = {
        // id: this.editCustomer._id,
        photo: this.miniAvatarUpload,
      };
      this.$store
        .dispatch("customers/uploadCameraPhoto", payload)
        .then((res) => {
          this.editCustomer.smallImage = res.data;
          this.updateCustomer();
        });
    },
    onCarClick(car) {
      console.log(car);
      this.$store.commit("cars/SET_SELECTED_CAR", car);
      this.$store.commit("cars/SET_EDIT_CAR_VISIBLE", true);
    },
    createCar() {
      let payload = {
        model: "Mein Auto",
        customer: this.customer._id,
      };
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("cars/create", payload).then((res) => {
        console.log("created car:");
        console.log(res);
        this.$store
          .dispatch("cars/getByCustomer", this.customer._id)
          .then(() => {
            this.$store.commit(
              "cars/SET_SELECTED_CAR",
              this.customerCars.find((o) => o._id === res)
            );
            this.$store.commit("cars/SET_EDIT_CAR_VISIBLE", true);
            this.$store.commit("SET_BUSY", false);
          });
      });
    },
  },
  computed: {
    submitAvailable() {
      if (
        this.editCustomer.email.includes("@") &&
        this.editCustomer.email.includes(".") &&
        this.editCustomer.email.length > 6
      ) {
        if (this.customer._id) {
          return true;
        } else {
          if (
            this.editCustomer.password === this.editCustomer.passwordCopy &&
            this.editCustomer.password.length > 5
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      customerCars: "cars/customerCars",
      requests: "requests/requests",
      offers: "offers/offers",
      editCarVisible: "cars/editCarVisible",
      customerStation: "stations/customerStation",
    }),
  },
  mounted() {
    this.getEditData();
    if (!this.customer._id) {
      this.editMode = true;
    }
  },
};
</script>
