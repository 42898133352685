<template>
  <div id="app" :style="appStyle()" :class="[whitelabel, { loading: busy }]">
    <div class="busy-modal" v-if="busy">
      <img class="busy-modal-icon" src="@/assets/gfx/icon_waiting.gif" />
    </div>
    <router-view name="header"></router-view>
    <transition name="fade" appear>
      <router-view
        @login="onLogin()"
        @logout="onLogout()"
        v-if="
          dataLoaded ||
          $route.name === 'intro' ||
          $route.name === 'registerCode' ||
          $route.name === 'registerQr' ||
          this.$route.name === 'registerVerify'
        "
      ></router-view>
    </transition>

    <PopupImprint v-if="imprintVisible" />
    <PopupPrivacy v-if="privacyVisible" />
    <PopupCustomerInfo v-if="customerInfoVisible" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import axios from "axios";

import PopupImprint from "./components/PopupImprint.vue";
import PopupPrivacy from "./components/PopupPrivacy.vue";
import PopupCustomerInfo from "./components/PopupCustomerInfo.vue";

export default {
  name: "App",
  components: {
    PopupImprint,
    PopupPrivacy,
    PopupCustomerInfo,
  },
  data() {
    return {
      dataLoaded: false,
      UPDATE_INTERVAL_MILLIS: 12000,
      updateInterval: null,
      updateAge: 0,
    };
  },
  methods: {
    onLogin() {
      console.log("onLogin");
      // temp user?
      if (this.customer._id) {
        this.loadData();
      } else {
        this.dataLoaded = true;
        this.$router.push({ name: "main" });
      }
    },
    onLogout() {
      console.log("onLogout");
      this.$store.commit("customers/LOGOUT");
      this.$store.commit("requests/SET_ALL", []);
      this.$store.commit("cars/SET_CUSTOMER_CARS", []);
      window.clearInterval(this.updateInterval);
      this.$store.commit("SET_BUSY", false);
      this.$router.push({ name: "intro" });
    },
    appStyle() {
      // document.querySelector('body').style.height = window.innerHeight + 'px'

      let style = {};
      if (this.whitelabel) {
        switch (this.whitelabel) {
          case "orange":
            style = {
              "--primary-color": "#d50",
              "--active-color": "#d90",
              "--accent-color": "#5d5",
            };
            break;
          case "gruen":
            style = {
              "--primary-color": "#4a3",
              "--active-color": "#5c3",
              "--accent-color": "#55c",
            };
            break;
        }
      }
      style.height = window.innerHeight + "px";
      return style;
    },
    onOrientationChange() {
      setTimeout(() => {
        console.log(window.innerHeight);
        this.$forceUpdate();
      }, 100);
    },
    onResize() {
      this.$forceUpdate();
    },
    preventNav(event) {
      event.preventDefault();
      event.returnValue = "";
    },
    getLastUpdate() {
      this.$store
        .dispatch("requests/getLastUpdate", this.customer._id)
        .then(() => {
          this.updateAge = moment(this.lastUpdateCall).diff(
            moment(this.lastUpdate),
            "seconds"
          );
          console.log("this.updateAge");
          console.log(this.updateAge);
          if (this.updateAge < 0) {
            this.$store.commit("SET_NEW_DATA_AVAILABLE", true);
            if (this.$route.name === "main" || this.$route.name === "offer") {
              console.log("getting new data");
              this.getNewData();
            }
          } else {
            this.$store.commit("RESET_LAST_UPDATE_CALL");
          }
        });
    },
    loadData() {
      if (!this.customer || !this.customer.selectedStation) {
        return;
      }

      this.$store.commit("SET_BUSY", true);
      this.$store
        .dispatch(
          "stations/getCustomerStation",
          this.customer.selectedStation._id
        )
        .then(() => {
          this.$store
            .dispatch("cars/getByCustomer", this.customer._id)
            .then(() => {
              this.$store.dispatch("requests/getRequestStates").then(() => {
                this.$store
                  .dispatch("requests/getByCustomer", this.customer._id)
                  .then(() => {
                    this.$store.commit("SET_BUSY", false);
                    this.dataLoaded = true;
                    if (
                      this.$route.name === "intro" ||
                      this.$route.name === "registerCode" ||
                      this.$route.name === "registerQr" ||
                      this.$route.name === "registerVerify"
                    ) {
                      this.$router.push({ name: "main" });
                    }
                    console.log("setting updateInterval");
                    let that = this;
                    that.updateInterval = window.setInterval(() => {
                      that.getLastUpdate();
                    }, that.UPDATE_INTERVAL_MILLIS);
                  });
              });
            });
        });
    },
    getNewData() {
      this.$store.commit("SET_BUSY", true);
      this.$store
        .dispatch("requests/getByCustomer", this.customer._id)
        .then(() => {
          this.$store.commit("SET_BUSY", false);
          this.$store.commit("RESET_LAST_UPDATE_CALL");
          this.$store.commit("SET_NEW_DATA_AVAILABLE", false);
          if (this.selectedRequest) {
            console.log("setting selectedRequest");
            this.$store.commit(
              "requests/SET_SELECTED_REQUEST",
              this.requests.find((o) => o._id === this.selectedRequest._id)
            );
          }
        });
    },
    initAuthHandler() {
      let that = this;
      axios.interceptors.response.use(undefined, function (err) {
        return new Promise(function () {
          if (err.response.status === 401) {
            console.log("401");
            that.onLogout();
            // you can also redirect to /login if needed !
          }
          throw err;
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      whitelabel: "whitelabel",
      imprintVisible: "imprintVisible",
      privacyVisible: "privacyVisible",
      customerInfoVisible: "customerInfoVisible",
      customer: "customers/customer",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      newDataAvailable: "newDataAvailable",
      lastUpdateCall: "lastUpdateCall",
      lastUpdate: "requests/lastUpdate",
    }),
  },
  watch: {
    customer() {
      console.log("watch customer");
    },
  },
  mounted() {
    window.addEventListener("orientationchange", this.onOrientationChange);
    window.addEventListener("resize", this.onResize);
    // persistence
    this.initAuthHandler();
    const token = localStorage.getItem("fiasco-token");
    const customer = JSON.parse(localStorage.getItem("fiasco-customer"));
    if (
      token &&
      customer &&
      this.$route.name !== "registerCode" &&
      this.$route.name !== "registerQr" &&
      this.$route.name !== "registerVerify"
    ) {
      this.$store.commit("SET_TOKEN", token);
      this.$store.commit("customers/LOGIN", customer);
      this.loadData();
    }
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  },
};
</script>

<style>
@import "./assets/css/fiasco.scss";
</style>

