<template>
  <div class="price-rating">
    <div class="price-rating-scales">
      <div class="price-rating-scale price-rating-scale-a">A</div>
      <div class="price-rating-scale price-rating-scale-b">B</div>
      <div class="price-rating-scale price-rating-scale-c">C</div>
    </div>
    <div class="price-rating-arrow">C</div>
  </div>
</template>

<script>
export default {
  name: 'PriceRating',
  data () {
    return {
      menuVisible: false
    }
  },
  methods: {
    goRequest () {
      this.$router.push({name: 'request-offers'})
    },
    createRequest () {
      this.$router.push({name: 'create-request'})
    },
    close () {
      this.$emit('close')
    }
  }
}

</script>
