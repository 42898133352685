<template>
  <div class="create-request-intro create-request-screen">
    <div class="create-request-photos-examples popup">
      <vue-glide :perView="1" :bullet="false" :gap="10" v-model="examplesIndex">
        <vue-glide-slide>
          <div class="create-request-photos-slider-page">
            <h3>Deine Werkstatt braucht mindestens 4 Fotos:</h3>
            <div class="create-request-photos-slider-page-intro">
              <div class="create-request-photos-slider-page-intro-item">
                <img src="@/assets/gfx/photo-template-diagonal-front.jpg" />
                <p>Fahrzeug diagonal von vorn</p>
              </div>
              <div class="create-request-photos-slider-page-intro-item">
                <img src="@/assets/gfx/photo-template-diagonal-rear.jpg" />
                <p>Fahrzeug diagonal von hinten</p>
              </div>
              <div class="create-request-photos-slider-page-intro-item">
                <img src="@/assets/gfx/photo-template-part.jpg" />
                <p>
                  Beschädigtes Teil (bzw. alle beschädigten Teile), 1-2m Abstand
                </p>
              </div>
              <div class="create-request-photos-slider-page-intro-item">
                <img src="@/assets/gfx/photo-template-damage.jpg" />
                <p>Schaden in Nahaufname (50cm Abstand)</p>
              </div>
            </div>
          </div>
        </vue-glide-slide>
        <!--vue-glide-slide v-for="(item, index) in examplePhotos" :key="index">
            <div class="create-request-photos-slider-page">
              <img class="create-request-photos-slider-page-photo" :src="'/examples/' + item.image">
              <p>{{item.title}}</p>
              <p>{{item.description}}</p>
            </div>
          </vue-glide-slide-->
      </vue-glide>
    </div>

    <div class="create-request-btn-prev" @click="goBack()">
      <img src="@/assets/gfx/arrow-prev.png" />
    </div>

    <div class="create-request-btn-next" @click="goNext()">
      <img src="@/assets/gfx/arrow-next.png" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CreateRequestIntro",
  data() {
    return {
      examplesIndex: 0,
      examplePhotos: [
        {
          title: "Beispiel: Gesamtes Fahrzeug",
          description: "Das ganze Fahrzeug muss sichbar sein.",
          image: "2.jpg",
          placeholder: "car-placeholder-front.png",
        },
        {
          title: "Beispiel: Beschädigtes Teil",
          description: "Das ganze Teil muss sichbar sein.",
          image: "3.jpg",
          placeholder: "car-placeholder-diagonal-front.jpg",
        },
        {
          title: "Beispiel: Nahaufnahme",
          description: "Etwa 50cm Abstand.",
          image: "4.jpg",
          placeholder: "car-placeholder-back.png",
        },
      ],
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "main" });
    },
    goNext() {
      this.$router.push({ name: "CreateRequestPhotos" });
    },
    getEditData() {
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest));
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      damageTypes: "requests/damageTypes",
      damageLocations: "requests/damageLocations",
      damageLocationName: "requests/damageLocationName",
      damageTypeName: "requests/damageTypeName",
      selectedRequest: "requests/selectedRequest",
      damageDummyCar: "damageDummyCar",
    }),
  },
  mounted() {
    console.log("CREATE REQUEST INTRO MOUNTED");
  },
};
</script>
