<template>
  <div class="intro screen">
    <div class="intro-inner">
      <img v-if="!whitelabel" src="@/assets/gfx/fiasco-logo.png" />
      <img
        v-if="whitelabel === 'rep2go'"
        src="@/assets/gfx/Rep2Go_logo_white.png"
      />
      <img
        v-if="whitelabel === 'mobilityconcept'"
        src="@/assets/gfx/mobility-concept-logo.png"
      />
      <div class="intro-nav">
        <form
          @submit.prevent="onLogin()"
          class="login-form"
          :class="{ error: loginError }"
          v-if="!onboardingVisible"
        >
          <input type="text" v-model="loginForm.email" placeholder="E-Mail" />
          <input
            type="password"
            v-model="loginForm.password"
            placeholder="Passwort"
          />
          <input type="submit" value="Login" />
        </form>
        <p class="intro-error" v-if="loginError">
          Das hat nicht funktioniert. Überprüfe E-Mail und Passwort.
        </p>
        <p class="center">Neu hier?</p>
        <button @click="goRegisterQr()">Mit QR Code anmelden</button>
        <button @click="goRegisterCode()">Mit Werkstatt-Code anmelden</button>
        <!--button>Neu anmelden</button>
        <button>Überspringen</button-->
      </div>
    </div>
    <div class="intro-legals">
      <div class="intro-legals-item" @click="openCustomerInfo()">
        Kundeninformationen
      </div>
      <div class="intro-legals-item" @click="openImprint()">Impressum</div>
      <div class="intro-legals-item" @click="openPrivacy()">Datenschutz</div>
    </div>
    <Onboarding v-if="onboardingVisible" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Onboarding from "./Onboarding.vue";

export default {
  components: { Onboarding },
  name: "Intro",
  data() {
    return {
      hits: 0,
      password: "123",
      loginForm: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    goMain() {
      this.$router.push({ name: "main" });
    },
    goRegisterCode() {
      this.$router.push({ name: "registerCode" });
    },
    goRegisterQr() {
      this.$router.push({ name: "registerQr" });
    },
    onLogin() {
      let payload = {
        email: this.loginForm.email,
        password: this.loginForm.password,
      };
      this.$store.commit("customers/SET_LOGIN_ERROR", null);
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("customers/login", payload).then(() => {
        if (!this.loginError) {
          this.$emit("login");
          /*
          this.$store.dispatch('stations/getCustomerStation', this.customer._id).then(() => {
            this.$store.dispatch('cars/getByCustomer', this.customer._id).then(() => {
              this.$store.dispatch('requests/getByCustomer', this.customer._id).then(() => {
                this.$store.dispatch('offers/getActive').then(() => {
                  this.$store.commit('SET_BUSY', false)
                  this.goMain()
                })
              })
            })
          })
          */
        } else {
          this.$store.commit("SET_BUSY", false);
          console.log(this.loginError);
        }
      });
    },
    openImprint() {
      this.$store.commit("SET_IMPRINT_VISIBLE", true);
    },
    openPrivacy() {
      this.$store.commit("SET_PRIVACY_VISIBLE", true);
    },
    openCustomerInfo() {
      this.$store.commit("SET_CUSTOMER_INFO_VISIBLE", true);
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      loginError: "customers/loginError",
      whitelabel: "whitelabel",
      onboardingVisible: "onboardingVisible",
    }),
  },
  mounted() {
    console.log("INTRO MOUNTED");
    this.$store.commit("SET_ONBOARDING_VISIBLE", true);
    if (this.$route.params.partner === "rep2go") {
      // todo: check exists
      this.$store.commit("SET_WHITELABEL", this.$route.params.partner);
    }
    if (this.$route.params.partner === "mobilityconcept") {
      this.$store.commit("SET_WHITELABEL", this.$route.params.partner);
    }
    if (this.$route.name === "Imprint") {
      this.$store.commit("SET_IMPRINT_VISIBLE", true);
    }
    if (this.$route.name === "Privacy") {
      this.$store.commit("SET_PRIVACY_VISIBLE", true);
    }
    /*
    if (window.location.hostname === 'localhost') {
      this.loginForm.email = 'miranda@car.de'
      this.loginForm.password = '123'
    }
    */
  },
};
</script>
