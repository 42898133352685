<template>
  <div class="intro screen register verify">
    <div class="intro-inner">
      <img v-if="!whitelabel" src="@/assets/gfx/fiasco-logo.png" />
      <img
        v-if="whitelabel === 'rep2go'"
        src="@/assets/gfx/Rep2Go_logo_white.png"
      />
      <img
        v-if="whitelabel === 'mobilityconcept'"
        src="@/assets/gfx/mobility-concept-logo.png"
      />

      <div class="register-verify-inner" v-if="success">
        <h3>E-Mail bestätigt!</h3>
        <p>Willkommen bei FIASCO.</p>
        <button @click="goIntro()">Anmelden</button>
      </div>
      <p v-if="error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RegisterVerify",
  data() {
    return {
      error: null,
      success: false,
    };
  },
  methods: {
    goIntro() {
      this.$router.push({ name: "intro" });
    },
    goMain() {
      this.$router.push({ name: "main" });
    },
    verify() {
      this.$store.commit("SET_BUSY", true);
      this.error = null;
      let payload = {
        email: this.$route.params.email,
        code: this.$route.params.code,
      };
      console.log(payload);
      this.$store.dispatch("customers/verify", payload).then((res) => {
        this.$store.commit("SET_BUSY", false);
        console.log(res);
        if (res.success) {
          if (this.customer) {
            this.$store.commit("customers/SET_EMAIL_VERIFIED");
          }
          this.success = true;
        } else {
          this.error = res.error.response.data;
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      customerStation: "stations/customerStation",
      whitelabel: "whitelabel",
    }),
  },
  mounted() {
    console.log("REGI VERIFY MOUNTED");
    if (this.$route.params.email && this.$route.params.code) {
      this.verify();
    }
  },
};
</script>
