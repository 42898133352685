<template>
  <div class="request-offers screen" v-if="selectedRequest">
    <div class="header large">
      <div class="header-top">
        <div class="header-chevron-back" @click="goBack()"></div>
        <img
          class="header-avatar"
          :src="
            API.imageUrl +
            'requests/' +
            selectedRequest.images[selectedRequest.images.length - 1]
          "
          v-if="selectedRequest.s3Images.length === 0"
        />
        <s3-image
          class="header-avatar"
          :image="selectedRequest.s3Images[selectedRequest.s3Images.length - 1]"
          v-if="selectedRequest.s3Images.length > 0"
          @click="showProfile()"
        />
        <div class="header-caption">
          <div class="header-caption-main">
            {{ selectedRequest.title }}
          </div>
          <div class="header-caption-sub">
            {{ formatDate(selectedRequest.createdAt) }}
          </div>
        </div>
        <img
          class="header-edit"
          src="@/assets/gfx/icon_edit.png"
          @click="goEdit()"
        />
      </div>
      <div class="header-nav">
        <div class="header-nav-item">Angebote</div>
      </div>
    </div>
    <div class="content">
      <div class="list">
        <div class="list-empty" v-if="!selectedOffer">
          Es gibt noch kein Angebot zu deiner Anfrage.
        </div>

        <div
          class="header-hero-chat"
          @click="chatVisible = true"
          v-if="recentMessage"
        >
          <div class="chat-line">
            <!--div class="chat-line-name" v-if="recentMessage.customer">
              {{recentMessage.customer.firstName}}  {{recentMessage.customer.lastName}}
            </div-->
            <div class="chat-line-name" v-if="recentMessage.user">
              {{ recentMessage.user.firstName }}
              {{ recentMessage.user.lastName }}
            </div>
            <div class="chat-line-inner">
              <img
                class="chat-line-avatar"
                v-if="recentMessage.user"
                :src="API.imageUrl + 'users/' + recentMessage.user.image"
              />
              <img
                class="chat-line-avatar"
                v-if="recentMessage.customer"
                :src="
                  API.imageUrl + 'customers/' + recentMessage.customer.image
                "
              />
              <div class="chat-line-message">
                {{ recentMessage.content }}
                <div class="chat-line-message-date">
                  {{ formatDate(recentMessage.deliveredAt) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 class="request-offers-chat-title" v-if="recentMessage">
          <button @click="chatVisible = true">FIASCO Chat</button>
        </h3>

        <div
          class="list-item list-item-offer"
          v-if="selectedOffer"
          @click="goOffer()"
        >
          <div class="list-item-offer-top">
            <img
              class="list-item-image"
              :src="
                API.imageUrl +
                'stations/logos/' +
                customer.selectedStation.logoImage
              "
            />
            <div class="list-item-caption">
              <div class="list-item-caption-main">
                {{ customer.selectedStation.name }}
              </div>
              <div class="list-item-caption-sub">
                {{ offerDate() }}
              </div>
              <div class="list-item-caption-sub light">
                {{ customer.selectedStation.city }}
              </div>
              <div class="list-item-offer-state">
                <img src="@/assets/gfx/icon_check.png" />
                {{ requestStatusName(selectedRequest.status) }}
              </div>
            </div>
          </div>
          <!--div class="list-item-offer-ribbon">
            <div class="list-item-offer-ribbon-scale active">
              <div class="list-item-offer-ribbon-scale-arrow"></div>
              A
              </div>
            <div class="list-item-offer-ribbon-scale">B</div>
            <div class="list-item-offer-ribbon-scale">C</div>
          </div-->
          <div
            class="list-item-offer-price"
            v-if="!selectedOffer.calculation.useLumpSumPrice"
          >
            {{
              (selectedOffer.calculation.totalPrice * 1.19).toFixed(0)
            }}&nbsp;€
          </div>
          <div
            class="list-item-offer-price"
            :class="
              'reliability-level-' +
              selectedOffer.calculationLevel.toLowerCase()
            "
            v-if="selectedOffer.calculation.useLumpSumPrice"
          >
            {{
              (selectedOffer.calculation.lumpSumPrice * 1.19).toFixed(0)
            }}&nbsp;€
          </div>
          <div
            class="list-item-offer-reliability"
            v-if="!selectedOffer.calculation.useLumpSumPrice"
          >
            <div
              class="list-item-offer-reliability-level"
              :class="
                'reliability-level-' +
                selectedOffer.calculationLevel.toLowerCase()
              "
            >
              {{ selectedOffer.calculationLevel }}
            </div>
            <div
              v-if="selectedOffer.calculationLevel === 'A'"
              class="list-item-offer-reliability-caption"
              :class="
                'reliability-level-' +
                selectedOffer.calculationLevel.toLowerCase()
              "
            >
              Zuverlässige Kalkulation:<br />
              Max. 10% Abweichung
            </div>
            <div
              v-if="selectedOffer.calculationLevel === 'B'"
              class="list-item-offer-reliability-caption"
            >
              Grobe Kalkulation:<br />
              Max. 30% Abweichung
            </div>
            <div
              v-if="selectedOffer.calculationLevel === 'C'"
              class="list-item-offer-reliability-caption"
            >
              Geschätzt:<br />
              Über 30% Abweichung
            </div>
          </div>

          <div
            class="list-item-offer-reliability"
            v-if="selectedOffer.calculation.useLumpSumPrice"
          >
            <div class="list-item-offer-reliability-level">F</div>
            <div class="list-item-offer-reliability-caption">Fixpreis</div>
          </div>

          <div class="list-item-offer-sub">
            <div class="list-item-offer-sub-services">
              <span>Service:</span>
              <div
                class="list-item-offer-sub-service"
                :class="{
                  inactive: !selectedOffer.services.includes('PICKUP'),
                }"
              >
                <img src="@/assets/gfx/icon_holundbring_04.svg" />
              </div>
              <div
                class="list-item-offer-sub-service"
                :class="{
                  inactive: !selectedOffer.services.includes('CLEANING'),
                }"
              >
                <img src="@/assets/gfx/icon_reinigung_05.svg" />
              </div>
              <div
                class="list-item-offer-sub-service"
                :class="{
                  inactive: !selectedOffer.services.includes('FREE_RENTAL_CAR'),
                }"
              >
                <img src="@/assets/gfx/icon_3jahre_03.svg" />
              </div>
              <div
                class="list-item-offer-sub-service"
                :class="{
                  inactive:
                    !selectedOffer.services.includes('WARRANTY_3_YEARS'),
                }"
              >
                <img src="@/assets/gfx/icon_rental_car.png" />
              </div>
            </div>
            <!--div class="list-item-offer-sub-certificates">
              <span>Zertifiziert:</span>
              <img
                class="list-item-offer-sub-certificates-icon"
                src="@/assets/gfx/certificate-fiasco.png"
              />
              <img
                class="list-item-offer-sub-certificates-icon"
                src="@/assets/gfx/certificate-dekra.png"
              />
              <img
                class="list-item-offer-sub-certificates-icon"
                src="@/assets/gfx/certificate-iso.png"
              />
              <img
                class="list-item-offer-sub-certificates-icon"
                src="@/assets/gfx/certificate-eurogarant.png"
              />
              <img
                class="list-item-offer-sub-certificates-icon"
                src="@/assets/gfx/certificate-zkf.png"
              />
            </div-->
          </div>
        </div>
      </div>
    </div>
    <Chat v-if="chatVisible" @close="chatVisible = false" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Chat from "./Chat.vue";

export default {
  name: "RequestOffers",
  data() {
    return {
      // TODO: multiple offers
      chatVisible: false,
      // offer: null
    };
  },
  components: {
    Chat,
  },
  methods: {
    goBack() {
      this.$router.push({ name: "main" });
    },
    goOffer() {
      this.$router.push({
        name: "offer",
        params: {
          requestId: this.selectedRequest._id,
          id: this.selectedOffer._id,
        },
      });
    },
    goEdit() {
      this.$router.push({
        name: "request-edit",
        params: { id: this.selectedRequest._id },
      });
    },
    offerDate() {
      let item = this.selectedRequest.statusHistory.find(
        (o) => o.status === "OFFERED"
      );
      return item ? this.formatDate(item.changedAt) : "";
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      customer: "customers/customer",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      recentMessage: "requests/recentMessage",
      requestStatusName: "requests/requestStatusName",
      selectedOffer: "offers/selectedOffer",
      customerStation: "stations/customerStation",
    }),
  },
  mounted() {
    // TODO: multiple offers
    if (!this.selectedRequest) {
      this.$store.commit(
        "requests/SET_SELECTED_REQUEST",
        this.requests.find((o) => o._id === this.$route.params.id)
      );
    }
    console.log(this.selectedRequest.statusHistory);
    console.log(
      this.selectedRequest.statusHistory.some((o) => o.status === "OFFERED")
    );
    if (this.selectedRequest.offer) {
      if (
        this.selectedRequest.statusHistory.some((o) => o.status === "OFFERED")
      ) {
        this.$store.dispatch("offers/getById", this.selectedRequest.offer);
        // this.offer = this.activeOffers.find(o => o._id === this.selectedRequest.offer)
      }
    }
  },
};
</script>
